import { useQuery } from 'react-query';
import { apiService } from '../../../utills/api';

const useGetDocumentationDetail = (id) => {
  return useQuery({
    queryKey: ['documentationTree', id],
    enabled: !!id,
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/documentation/${id}/treeview/`);
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetDocumentationDetail;
