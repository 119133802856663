import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/auth';
import 'antd/dist/antd.min.css';
import Week from './components/week/Week';
import NavigationLeftItem from './components/navigation/navigationLeftItem';
import SubLayout from './subLayouts/subLayout';
import PopoverProfile from './components/profile/ProfilePopover';

class CustomLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { menu, history, currentCompany } = this.props;

    return (
      <div id="App">
        {this.props.isAuthenticated && (
          <div className="App">
            <div>
              <nav className="nav">
                <ul className="nav__list nav__list--active">
                  <span>
                    <Week history={history} />
                    {(() => {
                      if (menu.menu_1 != null) {
                        return menu.menu_1.map((item, i) => (
                          <NavigationLeftItem
                            key={i}
                            route={item.route_1}
                            icon={item.icon_svg || ''}
                            text={item.title}
                          />
                        ));
                      } else {
                        return;
                      }
                    })()}
                  </span>
                  <li className="nav_list_avatar">
                    <a>
                      <PopoverProfile history={history} />
                    </a>
                  </li>
                </ul>
              </nav>
              <div>
                {(() => {
                  if (menu.menu_1 != null) {
                    return menu.menu_1.map((item, i) => (
                      <SubLayout
                        currentCompany={currentCompany}
                        key={i}
                        {...this.props}
                        items={menu.menu_2}
                        items2={menu.menu_3}
                        items4={menu.menu_4}
                      />
                    ));
                  } else {
                    return;
                  }
                })()}
              </div>

              <div className="main">{this.props.children}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    currentCompany: state.auth.current_company,
    menu: state.menu.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLayout));
