import reducer from './reducers/mainReducer';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { legacy_createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};
const isDevelopment = import.meta.env.MODE === 'development';
const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [applyMiddleware(thunk)];
if (isDevelopment) {
  middleware.push(applyMiddleware(logger));
}

export const store = legacy_createStore(persistedReducer, composeEnhances(...middleware));
