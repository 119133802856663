import { apiService } from '../../utills/api';

export const getCultures = () => {
  return async (dispatch, getState) => {
    console.log('load culture');
    dispatch({ type: 'START_LOADING_CULTURE' });

    try {
      let { data } = await apiService.get('/api/v1/culture/dropdown/');

      if (data) {
        dispatch({ type: 'GET_CULTURE_SUCCESS', data: data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const clearUnits = () => {
  return async (dispatch, getState) => {
    dispatch({ type: 'CLEAR_CULTURE' });
  };
};

export const setActiveCulture = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: 'SET_ACTIVE_CULTURE', data: id });
  };
};

export const clearActiveCulture = () => {
  return async (dispatch, getState) => {
    dispatch({ type: 'CLEAR_ACTIVE_CULTURE' });
  };
};
