import { Field, Form, Formik } from 'formik';
import { useRef } from 'react';
import { DatePickerField } from '../../../shared/DatePickerField';
import { InputField } from '../../../shared/InputField';
import Modal from '../../../shared/modal/Modal';
import ModalButton from '../../../shared/modal/ModalButton';
import Yup from '../../../utills/myYup';
import { validDate } from '../../../utills/time';
import Label from '../../Label';

const DocDialog = ({ visible, onClose, formProps, onSubmit }) => {
  const formRef = useRef(null);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
  });

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={formProps ? 'Editace dokumentace' : 'Vytvoření dokumentace'}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          publication_data: formProps?.publication_data || null,
          chief_engineer_name: formProps?.chief_engineer_name || null,
          builder_name: formProps?.builder_name || null,
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="publication_data" title="Datum publikace" />
                  <Field
                    name="publication_data"
                    value={validDate(values.publication_data) || null}
                    onChange={(date) => setFieldValue('publication_data', date)}
                    placeholder=""
                    component={DatePickerField}
                  />
                </div>

                <div>
                  <Label name="chief_engineer_name" title="Hlavní inženýr projektu" />
                  <Field
                    name="chief_engineer_name"
                    value={values.chief_engineer_name || null}
                    placeholder=""
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="builder_name" title="Stavebník" />
                  <Field
                    name="builder_name"
                    value={values.builder_name || null}
                    placeholder=""
                    component={InputField}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DocDialog;
