import React from 'react';
import { Checkbox, Form, Input } from 'antd';

const FormItem = Form.Item;

export const CheckboxField = ({ field, form: { touched, errors, submitCount }, ...props }) => {
  const errorMsg =
    (touched[field.name] && errors[field.name]) || (errors[field.name] && submitCount > 0)
      ? errors[field.name]
      : null;

  return (
    <FormItem
      label={props.label}
      help={errorMsg}
      hasFeedback={!!errors[field.name]}
      validateStatus={errors[field.name] && (touched[field.name] || submitCount > 0) && 'error'}
    >
      <Checkbox {...field} checked={field.value} {...props} allowClear />
    </FormItem>
  );
};
