import React from 'react';
import { useSelector } from 'react-redux';
import withDbItemsWrapper from '../../../utills/hoc/dbItemsWrapper';
import TechnicalReportItemModal from './componenets/modals/TechnicalReportItemModal';
import TechnicalReportItemView from './componenets/TechnicalReportItemView';

const TechnicalReportItem = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.technicalReportItem);
  return (
    <div className="w-full min-w-0">
      {detail && <TechnicalReportItemView data={detail} onEdit={handleEdit} />}

      <TechnicalReportItemModal
        title="Položky technické správy"
        visible={openDialog}
        formProps={isEdit ? detail : null}
        classification={[]}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
};

export default withDbItemsWrapper(
  TechnicalReportItem,
  'technicalReportItem',
  'left-panel-technical-report-item/'
);
