import React from 'react';
import { useSelector } from 'react-redux';
import withDbItemsWrapper from '../../../utills/hoc/dbItemsWrapper';
import PropertySetModal from './components/modals/PropertySetModal';
import PropertySetView from './components/PropertySetView';

const PropertySet = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.propertySet);

  return (
    <div className="w-full min-w-0">
      {detail && <PropertySetView data={detail} onEdit={handleEdit} />}
      <PropertySetModal
        title="Sety Vlastností"
        visible={openDialog}
        formProps={isEdit ? detail : null}
        classification={[]}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
};

export default withDbItemsWrapper(
  PropertySet,
  'propertySet',
  'left-panel-set-property-definition/'
);
