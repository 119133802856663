import React, { useState } from 'react';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import EditCompanyForm from '../../components/EditCompanyForm';
import { apiService } from '../../../../../utills/api';
import { NotificationManager } from 'react-notifications';

const EditCompanyModal = ({ visible, onClose, data, submitEditForm, error, title }) => {
  const [submit, setSubmit] = useState(false);
  const [submitIco, setSubmitIco] = useState(false);
  const [reset, setReset] = useState(false);
  const [icoVisible, setIcoVisible] = useState(false);
  const [aresData, setAresData] = useState(null);

  const formSubmit = (values) => {
    submitEditForm(values);
    setAresData(null);
    setSubmit(false);
  };

  const formSubmitIco = async (data) => {
    setSubmitIco(false);
    try {
      let { data: ares } = await apiService.get('/api/v1/company/data-ares/', {
        params: { ico: data.ico },
      });
      if (ares) {
        setAresData(ares);
        setIcoVisible(false);
      }
    } catch (e) {
      NotificationManager.error('Chyba', e?.response?.data?.message);
    }
  };

  const _onClose = () => {
    setAresData(null);
    onClose();
  };

  return (
    <>
      <Modal
        title={title}
        visible={visible}
        header={[]}
        onClose={_onClose}
        footer={[
          <ModalButton key={0} action={() => onClose()}>
            Zavřít
          </ModalButton>,
          <ModalButton key={1} action={() => setIcoVisible(true)}>
            Načíst z Ares
          </ModalButton>,
          <ModalButton primary key={2} action={() => setSubmit(true)}>
            Uložit
          </ModalButton>,
        ]}
      >
        <EditCompanyForm
          submitOutside={submit}
          formProps={data}
          ares={aresData}
          error={error}
          reset={reset}
          resetConfirm={() => setReset(false)}
          submit={formSubmit}
        />
      </Modal>
      <Modal
        title="Zdejte IČO"
        visible={icoVisible}
        header={[]}
        footer={[
          <ModalButton key={1} action={() => setIcoVisible(false)}>
            Zavřít
          </ModalButton>,
          <ModalButton primary key={1} action={() => setSubmitIco(true)}>
            Odeslat
          </ModalButton>,
        ]}
      >
        <EditCompanyForm
          submitOutside={submitIco}
          formProps={data}
          submit={formSubmitIco}
          isIco={true}
        />
      </Modal>
    </>
  );
};

export default EditCompanyModal;
