/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm, Tooltip } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Delete16Regular } from '@fluentui/react-icons';

function TypeMarkComponentTable(props) {
  const permission = useSelector((state) => state.auth.pagePermission);

  const columns = () =>
    props.columns.map((column, i) => {
      if (column.key === 'id') {
        return (
          <th
            className="py-2 w-[50px] uppercase text-sm text-left pl-2 border-r last:border-r-0"
            key={i}
          />
        );
      }
      return (
        <th
          className="py-2 uppercase text-color2 text-[12px] tracking-widest	 text-left pl-2 border-r last:border-r-0"
          key={i}
        >
          {column.title}
        </th>
      );
    });

  const rows = (data) => {
    if (!data) return [];
    return data.map((item, i) => (
      <tr
        className="text-color2 even:bg-neutral-100 even:bg-opacity-60 border-b last:border-b-0"
        key={i}
      >
        {props.columns.map((column, j) => {
          if (column.key === 'title') {
            if (item.rowSpan === 0) return null;
            return (
              <td
                className={classNames('p-2 text-[13px] border-r bg-white')}
                rowSpan={item.rowSpan}
                key={j}
              >
                {item[column.key]}
              </td>
            );
          }
          if (column.key === 'solution') {
            return (
              <td
                className="p-2 text-[13px] gap-2 flex items-center justify-between border-r"
                key={column.key}
              >
                <span>{item[column.key]?.title}</span>
                <span>&nbsp;</span>
              </td>
            );
          }
          if (column.key === 'color') {
            return (
              <td className="p-2 text-[13px] border-r" key={column.key}>
                <span className="flex items-center gap-1 justify-between">
                  <span>{item[column.key]?.title}</span>

                  <div className="flex gap-2">
                    {props.onEditColor && item.solution && permission === 2 && (
                      <>
                        <Tooltip placement="top" title="Editovat vzhled">
                          <EditOutlined
                            className="justify-end text-sm cursor-pointer"
                            onClick={() =>
                              props.onEditColor(item.id, item?.solution?.classified_solution)
                            }
                          />
                        </Tooltip>
                        {item.color && (
                          <Popconfirm
                            placement="topLeft"
                            title="Opravdu chcete položku odstranit?"
                            onConfirm={() =>
                              props.onDeleteColor(
                                item?.solution?.classified_solution,
                                item?.id,
                                item?.solution?.appearance?.id
                              )
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip placement="top" title="Odstranit vzhled">
                              <Delete16Regular className="justify-end cursor-pointer text-sm" />
                            </Tooltip>
                          </Popconfirm>
                        )}
                      </>
                    )}
                    {/* {item.solution?.appearance && item.solution && permission === 2 && (
                      <Tooltip placement="top" title="Odstranit vzhled">
                        <DeleteOutlined
                          className="justify-end"
                          onClick={() => props.onDeleteColor(item.id, item.solution.id)}
                        />
                      </Tooltip>
                    )} */}
                  </div>
                </span>
              </td>
            );
          }
          if (column.key === 'id') {
            return (
              <td key={column.key} rowSpan={item.rowSpan} className="flex gap-2 p-2 justify-center">
                {props.onAdd && permission === 2 && (
                  <Tooltip placement="left" title="Přidat řešení">
                    <PlusOutlined onClick={() => props.onAdd(item.id)} />
                  </Tooltip>
                )}
                {item.solution && permission === 2 && (
                  <Popconfirm
                    placement="topLeft"
                    title="Opravdu chcete položku odstranit?"
                    onConfirm={() =>
                      props.onDeleteSolution(item.solution.classified_solution, item.id)
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip placement="left" title="Odstranit řešení">
                      <Delete16Regular className="shrink-0 cursor-pointer text-sm" />
                    </Tooltip>
                  </Popconfirm>
                )}
              </td>
            );
          }
          return (
            <td className="p-2 text-[13px]" key={column.key}>
              {item[column.key]}
            </td>
          );
        })}
      </tr>
    ));
  };

  return (
    <table className="table-fixed w-full">
      <thead className="border-b border-neutral-300">
        <tr>{columns()}</tr>
      </thead>
      <tbody>{rows(props.data)}</tbody>
    </table>
  );
}

export default TypeMarkComponentTable;
