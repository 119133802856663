import React, { Suspense } from 'react';
import PhaseModal from './components/modals/PhaseModal';
import PhaseDetailView from './components/PhaseDetail';
import { useSelector } from 'react-redux';
import withLeftPanelWrapper from '../../utills/hoc/leftPanelWithDetailWrapper';

export const Phase = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.phase);
  return (
    <div className="w-full">
      {openDialog && (
        <Suspense fallback={<div>Loading...</div>}>
          <PhaseModal
            visible={openDialog}
            formProps={isEdit ? detail : null}
            submitForm={handleSubmit}
            title={isEdit ? 'Editovat fázi' : 'Vytvořit fázi'}
            onClose={handleClose}
          />
        </Suspense>
      )}

      {detail && <PhaseDetailView makeEditable={handleEdit} data={detail} />}
    </div>
  );
};

export default withLeftPanelWrapper(Phase, 'phase');
