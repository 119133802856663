import { Button } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { InputField } from '../../shared/InputField';
import Yup from '../../utills/myYup';
import Label from '../Label';
import AuthModal from '../registration/modals/AuthModal';
import RegistrationForm from '../registration/RegistrationForm';
import Logo from '../../images/moiryNewLogo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { authLogin, register } from '../../store/actions/auth';
import { useQuery } from '../../hookd/useQuery';
import style from './login.module.css';
import { Link } from 'react-router-dom';

const NewLogin = (props) => {
  const [registrationVisible, setRegistrationVisible] = useState(false);
  const dispatch = useDispatch();
  const query = useQuery();
  const intended = query.get('intended');

  const registrationSubmit = (values) => {
    dispatch(register(values));
  };

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required('Povinné pole'),
    password: Yup.string().required('Povinné pole'),
  });
  return (
    <div className={style.page}>
      <div className={style.container}>
        <div className={style.header}>
          <img alt="example" src={Logo} />
        </div>

        <Formik
          initialValues={{
            userName: null,
            password: null,
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(authLogin(values.userName, values.password, intended));
          }}
        >
          {(props) => {
            const { values } = props;
            return (
              <>
                <Form>
                  <div>
                    <Label name="userName" title="Uživateské jméno" required />
                    <Field
                      name="userName"
                      placeholder=""
                      value={values.userName || null}
                      component={InputField}
                      required
                    />
                  </div>
                  <div>
                    <Label name="password" title="Heslo" required />
                    <Field
                      type="password"
                      name="password"
                      value={values.password || null}
                      component={InputField}
                    />
                  </div>

                  <div className={style.loginFooter}>
                    <button className={('hover:bg-color6', style.button)} type="submit">
                      Přihlásit se
                    </button>
                    <button
                      type="button"
                      className={style.button}
                      onClick={() => setRegistrationVisible(true)}
                    >
                      Jsem nový uživatel
                    </button>
                  </div>
                  <Link className="text-sm hover:color6" to="/reset-password">
                    Zapomněl jsem heslo
                  </Link>
                </Form>
                <AuthModal visible={registrationVisible} title="Nový uživatel">
                  <RegistrationForm
                    onSubmit={registrationSubmit}
                    //     error={errorReg}
                    onClose={() => setRegistrationVisible(true)}
                  />
                </AuthModal>
              </>
            );
          }}
        </Formik>
        <div className={style.footer}>
          <span>O aplikaci</span>
          <span>Forum</span>
        </div>
      </div>
    </div>
  );
};

export default NewLogin;
