import React from 'react';
import { NotificationManager } from 'react-notifications';
import { apiService } from '../../utills/api';
import { useParams } from 'react-router-dom';
import ResetPasswordConfirmForm from './ResetPasswordConfirmForm';
import history from '../../history';

const ResetPasswordConfirm = () => {
  const { token } = useParams();

  const submitNewPass = (values, setErrors) => {
    delete apiService.defaults.headers.common.Authorization;

    console.log({ values });

    apiService
      .post('/my-rest-auth/password-reset-complete/', {
        password: values.password,
        token,
      })
      .then(() => {
        NotificationManager.success('Heslo úspěšně změněno.');
        history.push('/login');
      })
      .catch((error) => {
        if (error.response.data.error) {
          NotificationManager.error('Chyba', error.response.data.error?.toString());
        } else {
          setErrors(error?.response?.data);
        }
      });
  };

  return (
    <div className="flex justify-center items-center h-screen flex-col gap-2">
      <h1 className="font-bold text-xl">Zvolte nové heslo</h1>

      <ResetPasswordConfirmForm submit={submitNewPass} />
    </div>
  );
};

export default ResetPasswordConfirm;
