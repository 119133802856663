import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import actionsFactory from '../../store/actions/factory/dbItemsActionFactory';
import { Phase } from './Phase';
import { apiService } from '../../utills/api';
import { useQuery } from '../../hookd/useQuery';
import { useHistory } from 'react-router-dom';

const PhaseForRevit = ({ match }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const history = useHistory();

  const query = useQuery();
  const contractId = query.get('contractId');

  const dispatch = useDispatch();
  const { fetchDetail, create, edit, clear } = actionsFactory('phase', false);

  useEffect(() => {
    dispatch(clear());

    if (match?.params?.id) {
      const id = match.params.id;

      if (id !== 'create') {
        dispatch(fetchDetail(id));
      } else {
        setOpenDialog(true);
      }
    }
  }, [match?.params?.id]);

  const handleSubmit = async (id, values) => {
    if (id) {
      dispatch(edit(id, values));
      setIsEdit(false);
    } else {
      try {
        const response = await apiService.post(`/api/v1/phase/?contract=${contractId}`, {
          ...values,
          contract: contractId,
        });

        history.push(`/vykonove-faze/${response.data.id}`);
      } catch (err) {
        console.log(err?.response);
      }
    }
    setOpenDialog(false);
  };

  const handleEdit = () => {
    setIsEdit(true);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setIsEdit(false);
    setOpenDialog(false);
  };

  const options = { openDialog, isEdit, handleSubmit, handleEdit, handleClose };

  return <Phase {...options} />;
};

export default PhaseForRevit;
