import { combineReducers } from 'redux';
import reducer from './auth';
import reducerConsist from './typemarkReducer';
import companyReducer from './companyReducer';
import * as actionTypes from '../actions/actionTypes';
import LanguageDetector from 'i18next-redux-languagedetector';
import menuReducer from './menuReducer';
import classificationReducer from './classificationReducer';
import cultureReducer from './cultureReducer';
import contractReducer from './contractReducer';
import dbItemsReducer from './dbItemsReducer';
import createNamedWrapperReducer from './wrappers/createNamedWrapperReducer';
import typeMarkReducer from './typemarkReducer';
import leftPanelWithDetailReducer from './leftPanelWithDetailReducer';
import typeMarkTemplateReducer from './typemarkTemaplateReducer';

const appReducer = combineReducers({
  auth: reducer,
  company: companyReducer,
  consist: reducerConsist,
  i18next: LanguageDetector,
  menu: menuReducer,
  classification: classificationReducer,
  culture: cultureReducer,
  contract: contractReducer,
  typeMark: typeMarkReducer,
  typeMarkTemplate: typeMarkTemplateReducer,
  solution: createNamedWrapperReducer(dbItemsReducer, 'SOLUTION'),
  propertyDefinition: createNamedWrapperReducer(dbItemsReducer, 'PROPERTY_DEFINITION'),
  professionBond: createNamedWrapperReducer(dbItemsReducer, 'PROFESSION_BOND'),
  propertySet: createNamedWrapperReducer(dbItemsReducer, 'PROPERTY_SET'),
  technicalReportItem: createNamedWrapperReducer(dbItemsReducer, 'TECHNICAL_REPORT_ITEM'),
  technicalReportItemSet: createNamedWrapperReducer(dbItemsReducer, 'TECHNICAL_REPORT_ITEM_SET'),
  biblio: createNamedWrapperReducer(dbItemsReducer, 'BIBLIO'),
  unit: createNamedWrapperReducer(dbItemsReducer, 'UNIT'),
  buildingObject: createNamedWrapperReducer(leftPanelWithDetailReducer, 'BUILDING_OBJECT'),
  appearance: createNamedWrapperReducer(leftPanelWithDetailReducer, 'APPEARANCE'),
  contractListing: createNamedWrapperReducer(leftPanelWithDetailReducer, 'CONTRACT_LISTING'),
  model: createNamedWrapperReducer(leftPanelWithDetailReducer, 'MODEL'),
  phase: createNamedWrapperReducer(leftPanelWithDetailReducer, 'PHASE'),
  print: createNamedWrapperReducer(leftPanelWithDetailReducer, 'PRINT'),
});

const mainReducer = (state, action) => {
  if (action.type === actionTypes.AUTH_LOGOUT) {
    return appReducer(undefined, action);
  }
  if (action.type === actionTypes.CHANGE_CURRENT_COMPANY) {
    return appReducer({ auth: state.auth, menu: state.menu }, action);
  }

  return appReducer(state, action);
};

export default mainReducer;
