import { Checkbox } from 'antd';
import classNames from 'classnames';
import { Field } from 'formik';
import React, { useEffect, useState } from 'react';

const FormItemWithCheckbox = ({
  name,
  value,
  placeholder,
  component,
  label,
  data,
  onChangeParent,
  inherited,
  required,
}) => {
  const [checked, setChecked] = useState(!value);

  useEffect(() => {
    setChecked(!value);
  }, [value]);

  const handleCheck = () => {
    setChecked((checked) => !checked);
    onChangeParent(null);
  };

  return (
    <div className="flex items-center text-sm text-color8">
      <label
        htmlFor={name}
        className={classNames('flex w-1/3 truncate', {
          "before:content-['_*'] before:mr-1 before:text-red-500": required,
        })}
      >
        {label}
      </label>

      <div className="flex grow items-center gap-2">
        <Checkbox checked={checked} onChange={() => handleCheck()} />
        <div className="grow">
          <Field
            id={name}
            name={name}
            value={checked ? inherited : value}
            placeholder={placeholder}
            component={component}
            disabled={checked}
            data={data}
            onBlur={(e) => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default FormItemWithCheckbox;
