import React from 'react';
import { useSelector } from 'react-redux';

import withLeftPanelWrapper from '../../../utills/hoc/leftPanelWithDetailWrapper';

import BuildingObjectDetailView from './components/BuildingObjectDetailView';
import BuildingObjectModal from './components/modals/BuildingObjectModal';

const BuildingObject = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.buildingObject);

  const handleDeleteRestore = () => {
    handleSubmit(detail.id, { is_active: !detail.is_active });
  };

  return (
    <div className="w-full">
      {detail && <BuildingObjectDetailView onEdit={handleEdit} onDelete={handleDeleteRestore} />}
      <BuildingObjectModal
        title="Stavební objekt"
        visible={openDialog}
        formProps={isEdit ? detail : null}
        classification={[]}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
};

export default withLeftPanelWrapper(BuildingObject, 'buildingObject');
