import React from 'react';
import { useSelector } from 'react-redux';
import withDbItemsWrapper from '../../../utills/hoc/dbItemsWrapper';
import BiblioView from './components/BiblioView';
import BiblioModal from './components/modals/BiblioModal';

function Biblio({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) {
  const { detail } = useSelector((state) => state.biblio);

  return (
    <div className="w-full">
      {detail && <BiblioView data={detail} onEdit={handleEdit} />}

      <BiblioModal
        title="Biblio"
        visible={openDialog}
        formProps={isEdit ? detail : null}
        classification={[]}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
}

export default withDbItemsWrapper(Biblio, 'biblio', 'left-panel-biblio/');
