import {
  CLEAR_TYPE_MARK_TEMPLATES,
  GET_TYPE_MARK_TEMPLATES_SUCCESS,
  GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
  TYPE_MARK_TEMPLATE_ACTIVE_CLASSIFICATION,
  ROUTE_ID_ADDED,
  TYPE_MARK_TEMPLATE_CREATED_SUCCESS,
} from '../actions/typemarkTemplate';

const initialState = {
  activeClassification: null,
  typeMarkTemplates: [],
  loading: false,
  detail: null,
  menuId: null,
  next: null,
};

const typeMarkTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TYPE_MARK_TEMPLATES_SUCCESS: {
      return {
        ...state,
        typeMarkTemplates: [...state.typeMarkTemplates, ...action.data.results],
        detail: null,
        next: action.data.next,
      };
    }

    case GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS: {
      return {
        ...state,
        detail: { ...action.data },
      };
    }

    case TYPE_MARK_TEMPLATE_CREATED_SUCCESS: {
      const { id, title } = action.data;
      return {
        ...state,
        typeMarkTemplates: [...state.typeMarkTemplates, { id, title }],
        detail: { ...action.data },
      };
    }

    case ROUTE_ID_ADDED: {
      return {
        ...state,
        menuId: action.data,
      };
    }

    case CLEAR_TYPE_MARK_TEMPLATES: {
      return {
        ...initialState,
        activeClassification: state.activeClassification,
        menuId: state.menuId,
      };
    }

    case TYPE_MARK_TEMPLATE_ACTIVE_CLASSIFICATION: {
      return { ...initialState, activeClassification: action.data };
    }

    default:
      return state;
  }
};

export default typeMarkTemplateReducer;
