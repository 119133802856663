import React, { useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { PlusOutlined } from '@ant-design/icons';
import Modal from '../../../../../shared/modal/Modal';
import Yup from '../../../../../utills/myYup';
import { InputField } from '../../../../../shared/InputField';
import Bar from '../../../../../elements/Bar';
import Label from '../../../../Label';
import FormList from '../../../../../elements/FormList';
import ModalButton from '../../../../../shared/modal/ModalButton';
import PickValueWithClassificationModal from '../../../../../shared/modals/PickValueWithClassificationModal';
import ImageCropperDialog from '../../../../../shared/components/ImageCropperDialog';
import SolutionPropertyDefinitionModal from './SolutionPropertyDefinitionModal';
import { updateObjectInArray } from '../../../../../utills/imutable';
import { AreaField } from '../../../../../shared/AreaField';

function SolutionModal({ visible, title, formProps, onSubmit, onClose }) {
  const [visibleAddFileDialog, setVisibleAddFileDialog] = useState(false);
  const [visibleAddPropertyItem, setVisibleAddPropertyItem] = useState(false);
  const [visibleAddTechnicalReportItem, setVisibleAddTechnicalReportItem] = useState(false);
  const [visibleAddPropertyProfessionBond, setVisibleAddProfessionBond] = useState(false);
  const [visiblePropertyDefinitionValueSetter, setVisiblePropertyDefinitionValueSetter] =
    useState(false);

  const [propertyIdForSetValue, setPropertyIdForSetValue] = useState(null);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    form: Yup.string().required('Povinné pole'),
    function: Yup.string().required('Povinné pole'),
    execution: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const handleAddFile = (image) => {
    formRef.current.setFieldValue('images', [image]);

    setVisibleAddFileDialog(false);
  };

  const handleDeleteFile = (item) => {
    if (!formRef?.current) return null;

    formRef.current.setFieldValue('images', []);
  };

  const handleAddPropertyItem = (item) => {
    formRef.current.setFieldValue('property_definition_set', [
      ...formRef.current.values.property_definition_set,
      item,
    ]);

    setVisibleAddPropertyItem(false);
  };

  const handleAddTechnicalReportItem = (item) => {
    formRef.current.setFieldValue('technical_report_item_set', [
      ...formRef.current.values.technical_report_item_set,
      item,
    ]);
    setVisibleAddTechnicalReportItem(false);
  };

  const handleAddProfessionBondItem = (item) => {
    formRef.current.setFieldValue('profession_bond_set', [
      ...formRef.current.values.profession_bond_set,
      item,
    ]);

    setVisibleAddProfessionBond(false);
  };

  const handleTechnicalReportItem = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.technical_report_item_set.filter(
      (item) => item.id !== id
    );

    formRef.current.setFieldValue('technical_report_item_set', newValues);
  };

  const handleDeleteProfessionBondItem = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.profession_bond_set.filter((item) => item.id !== id);

    formRef.current.setFieldValue('profession_bond_set', newValues);
  };

  const handleDeletePropertySet = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.property_definition_set.filter(
      (item) => item.id !== id
    );

    formRef.current.setFieldValue('property_definition_set', newValues);
  };

  const handleUpdatePropertyValue = (values) => {
    if (!formRef?.current || !propertyIdForSetValue) return null;

    const newValues = updateObjectInArray(formRef.current.values.property_definition_set, {
      id: propertyIdForSetValue,
      ...values,
    });

    formRef.current.setFieldValue('property_definition_set', newValues);
    setVisiblePropertyDefinitionValueSetter(false);
    setPropertyIdForSetValue(null);
  };

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          function: formProps?.function || null,
          form: formProps?.form || null,
          execution: formProps?.execution || null,
          images: formProps?.images || [],
          property_definition_set: formProps?.property_definition_set || [],
          technical_report_item_set: formProps?.technical_report_item_set || [],
          profession_bond_set: formProps?.profession_bond_set || [],
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, {
            ...values,
            images: values.images.map((image) => image.id),
            property_definition_set: values.property_definition_set.map((item) => ({
              id: item.id,
              value: item.value || null,
            })),
            technical_report_item_set: values.technical_report_item_set.map((item) => ({
              id: item.id,
            })),
            profession_bond_set: values.profession_bond_set.map((item) => ({
              id: item.id,
            })),
          });
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <Form>
              <div>
                <Label name="title" title="Název" required />
                <Field
                  name="title"
                  value={values.title || null}
                  placeholder="Název"
                  component={InputField}
                />
              </div>

              <div>
                <Label name="function" title="Funkce" required />
                <Field
                  name="function"
                  value={values.function || null}
                  placeholder="Funkce"
                  component={AreaField}
                />
              </div>

              <div>
                <Label name="execution" title="Provedeni" required />
                <Field
                  name="execution"
                  value={values.execution || null}
                  placeholder="Provedeni"
                  component={AreaField}
                />
              </div>

              <div>
                <Label name="form" title="Forma" required />
                <Field
                  name="form"
                  value={values.form || null}
                  placeholder="Forma"
                  component={AreaField}
                />
              </div>

              <div className="mb-1">
                <Bar
                  text="Obrázek"
                  content={[<PlusOutlined key={1} onClick={() => setVisibleAddFileDialog(true)} />]}
                />
                <FormList data={values.images} onDelete={handleDeleteFile} />
              </div>

              <div className="mb-1">
                <Bar
                  text="Vlastnosti"
                  content={[
                    <PlusOutlined key={1} onClick={() => setVisibleAddPropertyItem(true)} />,
                  ]}
                />
                <FormList
                  data={values.property_definition_set}
                  withValue
                  onEdit={(id) => {
                    setPropertyIdForSetValue(id);
                    setVisiblePropertyDefinitionValueSetter(true);
                  }}
                  onDelete={handleDeletePropertySet}
                />
              </div>

              <div className="mb-1">
                <Bar
                  text="Prvky technické zprávy"
                  content={[
                    <PlusOutlined key={1} onClick={() => setVisibleAddTechnicalReportItem(true)} />,
                  ]}
                />
                <FormList
                  data={values.technical_report_item_set}
                  onDelete={handleTechnicalReportItem}
                />
              </div>

              <div className="mb-1">
                <Bar
                  text="Profesní vazby"
                  content={[
                    <PlusOutlined key={1} onClick={() => setVisibleAddProfessionBond(true)} />,
                  ]}
                />
                <FormList
                  data={values.profession_bond_set}
                  onDelete={handleDeleteProfessionBondItem}
                />
              </div>

              <ImageCropperDialog
                visible={visibleAddFileDialog}
                onSubmit={handleAddFile}
                url="/api/v1/solution/upload-image/"
                onClose={() => setVisibleAddFileDialog(false)}
              />
              <PickValueWithClassificationModal
                visible={visibleAddPropertyItem}
                url="left-panel-property-definition/"
                pickerUrl="property-definition/left-panel-picker/"
                onClose={() => setVisibleAddPropertyItem(false)}
                onSubmit={handleAddPropertyItem}
              />
              <PickValueWithClassificationModal
                visible={visibleAddTechnicalReportItem}
                url="left-panel-technical-report-item/"
                pickerUrl="technical-report-item/left-panel-picker/"
                onClose={() => setVisibleAddTechnicalReportItem(false)}
                onSubmit={handleAddTechnicalReportItem}
              />
              <PickValueWithClassificationModal
                visible={visibleAddPropertyProfessionBond}
                url="left-panel-profession-bond/"
                pickerUrl="profession-bond/left-panel-picker/"
                onClose={() => setVisibleAddProfessionBond(false)}
                onSubmit={handleAddProfessionBondItem}
              />
              <SolutionPropertyDefinitionModal
                propertyId={propertyIdForSetValue}
                visible={visiblePropertyDefinitionValueSetter}
                onClose={() => {
                  setVisiblePropertyDefinitionValueSetter(false);
                  setPropertyIdForSetValue(null);
                }}
                onSubmit={handleUpdatePropertyValue}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default SolutionModal;
