/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { apiService } from '../utills/api';

export const usePhaseService = () => {
  const { contractId } = useSelector((state) => state.contract);

  const getPhases = () => apiService.get(`/api/v1/phase/left-panel/?contract=${contractId}`);

  const getPhase = (id) => apiService.get(`/api/v1/phase/${id}/`);

  const getPhaseTypes = () => apiService.get(`/api/v1/phase-type/dropdown/?contract=${contractId}`);

  const getModels = () => apiService.get(`/api/v1/model/dropdown/?contract=${contractId}`);

  const addPhase = (values) =>
    apiService.post('/api/v1/phase/', {
      ...values,
      contract: contractId,
    });

  const updateModel = (id, values) =>
    apiService.patch(`/api/v1/phase/${id}/?contract=${contractId}`, values);

  return {
    getPhases,
    getPhase,
    getPhaseTypes,
    getModels,
    addPhase,
    updateModel,
  };
};
