import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './miniTopNavigation.module.css';

const MiniTopNavigation = (props) => {
  return (
    <NavLink className={style.text} to={props.route} activeClassName="active">
      <span>{props.text}</span>
    </NavLink>
  );
};

export default MiniTopNavigation;
