import React, { useState } from 'react';
import { Upload, Progress, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useDispatch, useSelector } from 'react-redux';

import ModalButton from '../../../shared/modal/ModalButton';
import Modal from '../../../shared/modal/Modal';
import ProfileForm from './ProfileForm';
import { apiService } from '../../../utills/api';

import './Profile.css';

const ProfileEditModal = ({ visible, onClose, onSubmit }) => {
  const [reset, setReset] = useState(false);
  const [error, setError] = useState(null);
  const [submit, setSubmit] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [progress, setProgress] = useState(0);

  const dispatch = useDispatch();

  const formSubmit = async (data) => {
    setSubmit(false);
    try {
      let { data: response } = await apiService.patch(`/api/v1/user/${user?.id}/`, data);
      if (response) {
        dispatch({ type: 'UPDATE_USER_SUCCESSFULLY', data: response });
        onSubmit();
      }
    } catch (error) {
      setError(error);
    }
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append('image_url', file);
    try {
      const res = await apiService.patch(`/api/v1/user/${user?.id}/`, fmData, config);
      if (res) {
        dispatch({ type: 'UPDATE_USER_SUCCESSFULLY', data: res.data });
      }
      onSuccess('Ok');
    } catch (err) {
      onError({ err });
    }
  };

  const imageCropRender = () => (
    <ImgCrop zoom shape="round">
      <Upload
        customRequest={uploadImage}
        listType="picture-card"
        fileList={[{ url: user?.image_url }]}
        beforeUpload={beforeUpload}
      >
        <div className="image-crop-wrapper">{'Změnit avatara'}</div>
      </Upload>
      {progress > 0 ? <Progress percent={progress} /> : null}
    </ImgCrop>
  );

  return (
    <Modal
      title="Editace uživatele"
      visible={visible}
      pagination={false}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton primary key={2} action={() => setSubmit(true)}>
          Uložit
        </ModalButton>,
      ]}
    >
      {imageCropRender()}
      <ProfileForm
        formProps={user}
        error={error}
        submit={formSubmit}
        submitOutside={submit}
        reset={reset}
        resetConfirm={() => setReset(false)}
      />
    </Modal>
  );
};

export default ProfileEditModal;
