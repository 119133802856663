import { EDIT_TYPES } from '../../components/database/classification/constants';

const initialState = {
  classification_items: [],
  detail: null,
  active_classification: null,
  edit: null,
  modalVisible: false,
  choosen_translation: null,
  translations: [],
  loadingChildren: false,
  error: false,
};

const updateItemInTree = (node, id, nextItems) => {
  if (node.id === id) {
    node.children = node.children.concat(nextItems);
  }

  if (node.children !== undefined && node.children.length > 0) {
    for (let i = 0; i < node.children.length; i++) {
      node.children[i] = updateItemInTree(node.children[i], id, nextItems);
    }
  }

  return node;
};

const iterateOverRootOfTree = (tree, id, dataForUpdate) => {
  let result = [];
  if (Array.isArray(tree) && tree.length > 0) {
    for (let i = 0; i <= tree.length - 1; i++) {
      result = result.concat(updateItemInTree(tree[i], id, dataForUpdate));
    }
  }

  return result;
};

const classificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CLASSIFICATION_SUCCESS':
      return {
        ...state,
        classification_items: action.data,
      };
    case 'START_LOADING_CLASSIFICATION_CHILDREN':
      return {
        ...state,
        loadingChildren: true,
      };
    case 'GET_CLASSIFICATION_CHILDREN_SUCCESS': {
      let items = iterateOverRootOfTree(state.classification_items, action.id, action.data);
      return {
        ...state,
        classification_items: items,
        loadingChildren: false,
      };
    }
    case 'GET_CLASSIFICATION_DETAIL_SUCCESS':
      return {
        ...state,
        detail: action.data,
      };
    case 'SET_ACTIVE_CLASSIFICATION':
      return {
        ...state,
        active_classification: action.data,
      };
    case 'START_EDITING_DETAIL':
      return {
        ...state,
        modalVisible: true,
        edit: EDIT_TYPES.detail,
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        modalVisible: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        modalVisible: false,
        edit: null,
      };
    case 'EDITING_DETAIL_FINISHED':
      return {
        ...state,
        modalVisible: false,
        edit: null,
      };
    case 'CLASSIFICATION_DETAIL_UPDATED':
      return {
        ...state,
        detail: {
          ...state.detail,
          is_def_component: action.data.is_def_component,
          is_def_composition: action.data.is_def_composition,
          is_active: action.data.is_active,
        },
        edit: null,
        modalVisible: false,
      };
    case 'GET_CLASSIFICATION_TRANSLATIONS_SUCCESS':
      return {
        ...state,
        translations: action.data,
        error: false,
      };
    case 'GET_CLASSIFICATION_TRANSLATIONS_FAILED':
      return {
        ...state,
        translations: [],
        error: true,
      };
    case 'CLEAR_CLASSIFICATION_TRANSLATIONS':
      return {
        ...state,
        translations: [],
        error: false,
      };
    case 'TRANSLATION_CHOOSEN_FOR_EDIT':
      return {
        ...state,
        choosen_translation: action.data,
        modalVisible: true,
        edit: EDIT_TYPES.translations,
      };
    case 'CLASSIFICATION_TRANSLATIONS_UPDATED': {
      let updateTitle = action.data.translation_set.find(
        (item) => item?.language?.code === navigator.language.substring(0, 2)
      );

      return {
        ...state,
        translations: action.data.translation_set,
        detail: {
          ...state.detail,
          title: updateTitle ? updateTitle.title : state.detail.title,
        },
        modalVisible: false,
        edit: null,
      };
    }
    case 'EDIT_ALTERNATIVE_CLASSIFICATION_SUCCESS': {
      // let alternativeArray = [...state.detail?.alternative_classification_set];
      // const index = (alternativeArray || []).findIndex(
      //   (ele) => ele.id === action.data.id
      // );
      // if (index > -1) {
      //   alternativeArray[index] = action.data;
      // }
      return {
        initialState,
      };
    }
    case 'ADD_ALTERNATIVE_CLASSIFICATION_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          alternative_classification_set: [
            ...state.detail.alternative_classification_set,
            action.data,
          ],
        },
      };
    case 'REMOVE_ALTERNATIVE_CLASSIFICATION_SUCCESS':
      return {
        ...state,
        detail: {
          ...state.detail,
          alternative_classification_set: state.detail.alternative_classification_set.filter(
            (ele) => ele.id !== action.data
          ),
        },
      };
    case 'CLEAR_CLASSIFICATION':
      return initialState;
    default:
      return state;
  }
};

export default classificationReducer;
