/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { logout, refreshConnection } from '../store/actions/auth';
import { store } from '../store/store';

const { dispatch } = store;

const apiService = axios.create({
  xsrfHeaderName: 'X-CSRFTOKEN',
  xsrfCookieName: 'csrftoken',
  baseURL: import.meta.env.VITE_BASE_API,
});
apiService.defaults.params = {};

const updateToken = () => {
  apiService.defaults.headers.common['Authorization'] = `Bearer ${
    store.getState().auth.access_token
  }`;
};

apiService.defaults.headers.common['Content-Type'] = 'application/json';
// updateToken();
apiService.defaults.headers.common['Authorization'] = `Bearer ${
  store.getState().auth.access_token
}`;

apiService.interceptors.request.use(
  function (config) {
    if (config.url === '/my-rest-auth/login/') {
      delete config.headers.common.Authorization;
    }
    const lang = navigator.language || navigator.userLanguage;
    if (lang) {
      config.params['lang'] = lang;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiService.interceptors.request.use((config) => {
  if (!config.url.match(/(.*)[\/](login|menu-for-employee|phase)[[\/](.*)/g)) {
    let employee = store.getState().auth.current_employee;
    if (employee) {
      config.params['employee'] = employee;
    }
  }
  return config;
});

apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (typeof error.response == 'undefined') {
      NotificationManager.error('Chyba', 'Zkontrolujte zda jste pripojeni k internetu');
      throw error;
    }

    if (error.response.status === 500) {
      // NotificationManager.error('Chyba', 'Omlouváme se, něco se pokazilo');
      throw error;
    }

    if (originalConfig.url !== '/my-rest-auth/login' && error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        let refreshToken = store.getState().auth.refresh_token;
        if (originalConfig.url === '/my-rest-auth/token/refresh/') {
          dispatch(logout());
        }

        if (refreshToken) {
          try {
            let {
              data: { access },
            } = await apiService.post(`/my-rest-auth/token/refresh/`, {
              refresh: refreshToken,
            });
            if (access) {
              dispatch(refreshConnection(access));

              updateToken();
              originalConfig.headers.Authorization = `Bearer ${access}`;
              return apiService(originalConfig);
            } else {
              // dispatch(logout());
            }
          } catch (e) {
            // dispatch(logout());
          }
        }
        // dispatch(logout());
        return Promise.reject(error);
      }
    }
    throw error;
  }
);

export { apiService, updateToken };
