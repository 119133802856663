import { useEffect, useRef, useState } from 'react';
import Bar from '../../elements/Bar';
import Sortable from 'sortablejs';
import { ArrowBidirectionalUpDown16Regular, Edit12Filled } from '@fluentui/react-icons';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { apiService } from '../../utills/api';
import { Spin } from 'antd';
import InstructionDialog from './InstructionDialog';

const InstructionTemplateDetail = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [showDialog, seShowDialog] = useState();

  const { data, error } = useQuery({
    queryKey: ['instructions', id],
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/instruction-template/${id}`);

      return data;
    },
    refetchOnWindowFocus: false,
  });

  const updateDetail = useMutation({
    mutationFn: ({ updatedData }) => {
      return apiService.patch(`/api/v1/instruction-template/${id}/`, {
        ...data,
        // ...(updatedData ? updatedData : []),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['instructions', id]);
    },
  });

  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const sortable = new Sortable(ref.current, {
      animation: 200,

      onEnd(evt) {
        const newItems = [...data.instructions];
        const movedItem = newItems.splice(evt.oldIndex, 1)[0];

        newItems.splice(evt.newIndex, 0, movedItem);
        updateDetail.mutate({ updatedData: newItems });
      },
      handle: '.drag-handle',
    });

    return () => {
      sortable.destroy();
    };
  }, [data]);

  return (
    <>
      {' '}
      <div className="w-full bg-white h-full">
        <Bar
          text="Detail"
          content={[<Edit12Filled key={1} onClick={() => seShowDialog(true)} />]}
        />

        <Bar text="Položky šablony" content={[]} />

        <ul ref={ref} className="flex flex-col gap-2 m-2">
          {error && <div>Neco se pokazilo</div>}
          {!error && !data ? (
            <div className="flex h-full w-full justify-center items-center">
              <Spin />
            </div>
          ) : (
            data.instructions.map((item) => (
              <li
                key={item.id}
                className="drag-handle flex bg-color4 cursor-move justify-between items-center pl-4"
              >
                <span className="text-sm">{item.title}</span>

                <span className="p-2">
                  <ArrowBidirectionalUpDown16Regular />
                </span>
              </li>
            ))
          )}
        </ul>
      </div>
      <InstructionDialog
        visible={showDialog}
        onClose={() => seShowDialog(false)}
        formProps={data}
        onSubmit={(newData) => updateDetail.mutate({ updatedData: newData })}
      />
    </>
  );
};

export default InstructionTemplateDetail;
