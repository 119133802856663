import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Search from 'antd/lib/input/Search';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewClassificationTree from '../../components/classification/newClassification';
import Filter from '../../components/filter/Filter';
import BaseContainer from '../../containers/BaseContainer';
import Bar from '../../elements/Bar';
import { getClassificationChildren } from '../../store/actions/classification';

export default function LeftPanelWithClassification({
  data,
  makeActive,
  active,
  onSearch,
  addItem,
  onFilter,
  title,
  filteredValues,
  url,
  disableAdd = false,
}) {
  const [visibleFilter, setVisibleFilter] = useState(false);
  const permission = useSelector((state) => state.auth.pagePermission);
  const dispatch = useDispatch();

  const itemTrea = (item) => {
    if (!item) return null;
    if (item.children.length === 0) {
      return (
        <NewClassificationTree
          key={item.id}
          content={item.title}
          status={item.status}
          reference={item.reference}
          children_url={item.children_url}
          active={item.id === active}
          onExpand={() => {
            dispatch(getClassificationChildren(item.id, url));
          }}
          onClick={() => makeActive(item.id)}
          visible
          // open={false}
        />
      );
    }
    return (
      <NewClassificationTree
        key={item.id}
        content={item.title}
        status={item.status}
        children_url={item.children_url}
        reference={item.reference}
        active={item.id === active}
        onClick={() => makeActive(item.id)}
        onExpand={() => {}}
        canHide
        visible
        // open={false}
      >
        {item.children.map((child) => itemTrea(child))}
      </NewClassificationTree>
    );
  };

  const makeTree = (data) => (data || []).map((ele) => itemTrea(ele));

  const content = () => {
    const items = [
      <Filter
        key={1}
        filteredValues={filteredValues}
        visible={visibleFilter}
        onFilter={onFilter}
        onClose={() => setVisibleFilter(false)}
      >
        <FilterOutlined onClick={() => setVisibleFilter(true)} />
      </Filter>,
    ];
    if (permission === 2 && !disableAdd) {
      {
        active && items.push(<PlusOutlined key={2} onClick={addItem} />);
      }
    }
    return items;
  };

  return (
    <div>
      <BaseContainer fullHeight width="420px" margin>
        <Bar text={title} content={content()} />
        <Search placeholder="Vyhledat" onChange={onSearch} />
        {makeTree(data)}
      </BaseContainer>
    </div>
  );
}

LeftPanelWithClassification.propTypes = {
  data: PropTypes.array,
  makeActive: PropTypes.func,
  active: PropTypes.string,
  onSearch: PropTypes.func,
  addItem: PropTypes.func,
  onFilter: PropTypes.func,
  title: PropTypes.string,
  filteredValues: PropTypes.object,
  disableAdd: PropTypes.bool,
};
