import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import { InputField } from '../../../../shared/InputField';
import { SelectField } from '../../../../shared/SelectField';
import { objectEmptyCheck } from '../../../../utills/usefullUtills';

const RightsForm = ({ formProps, submitOutside, submit, error, reset = false, resetConfirm }) => {
  const formRef = useRef();

  useEffect(() => {
    if (reset === true) {
      formRef.current.setValues(formProps);
      resetConfirm();
    }
  }, [reset]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && submitOutside) {
      formRef.current.submitForm();
    }

    return () => (isSubscribed = false);
  }, [submitOutside]);

  useEffect(() => {
    formRef.current.setErrors(error?.response?.data || '');
  }, [error]);

  const init = ({ title, is_active }) => ({
    title,
    is_active,
  });

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          return (
            <Form labelCol={{ span: 4 }}>
              <Form.Item label="Název role">
                <Field
                  name="title"
                  value={values.title}
                  placeholder="Název role"
                  component={InputField}
                />
              </Form.Item>
              {!objectEmptyCheck(formProps) && (
                <Form.Item label="Stav">
                  <Field
                    name="is_active"
                    value={values.is_active ? 1 : 2}
                    placeholder="Stav"
                    component={SelectField}
                    onChange={(data) => setFieldValue('is_active', data === 1 ? true : false)}
                    data={[
                      { title: 'aktivní', id: 1 },
                      { title: 'neaktivní', id: 2 },
                    ]}
                  />
                </Form.Item>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RightsForm;
