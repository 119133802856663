import Dragger from 'antd/lib/upload/Dragger';
import React, { useState } from 'react';
import Modal from '../modal/Modal';
import ModalButton from '../modal/ModalButton';
import { apiService } from '../../utills/api';

const FileUploader = ({ url, visible, onSubmit, onClose }) => {
  const [loading, setLoading] = useState(false);

  const upload = async (options) => {
    const { file } = options;

    const fmData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    fmData.append('file', file);

    setLoading(true);
    try {
      const res = await apiService.post(url, fmData, config);

      if (res.data) {
        onSubmit({ id: res.data.id, title: file.name });
      }
    } catch (err) {
      console.log('Eroor: ', err);
    } finally {
      setLoading(false);
    }
  };

  const config = {
    name: 'file',
    multiple: true,
    accept: '.pdf, .doc, image/*',
    customRequest: upload,
    showUploadList: false,
  };

  return (
    <Modal
      title="Nahrát soubor"
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => handleSubmit()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <div className="flex flex-col h-auto">
        <Dragger {...config}>
          <p className="ant-upload-text p-5">Klikni nebo přetáhni soubor pro jeho nahrání</p>
        </Dragger>
      </div>
    </Modal>
  );
};

export default FileUploader;
