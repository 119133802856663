export function updateObjectInArray(array, action) {
  return array.map((item) => {
    if (item.id !== action.id) {
      return item;
    }

    return {
      ...item,
      ...action,
    };
  });
}
