import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import { SelectField } from '../../../../../shared/SelectField';

import useFormRutine from '../../../../../shared/hooks/formHook';
import { InputField } from '../../../../../shared/InputField';
import Yup from '../../../../../utills/myYup';

const AlternativeClassificationForm = ({ formProps = {}, submitOutside, submit, error, data }) => {
  const formRef = useRef();

  useFormRutine(formRef, submitOutside, null, null, null, error);

  const init = (props) => {
    if (props) {
      return {
        classification_system: props?.classification_system?.id,
        code: props?.code,
      };
    }
    return {
      classification_system: null,
      code: null,
    };
  };

  const ValidationSchema = Yup.object().shape({
    classification_system: Yup.string().required('Povinné pole'),
    code: Yup.string().required('Povinné pole'),
  });

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form labelCol={{ span: 4 }}>
              {!formProps && (
                <Form.Item label="Třídníky">
                  <Field
                    name="classification_system"
                    value={values.classification_system || null}
                    component={SelectField}
                    data={data || []}
                    onChange={(val) => {
                      setFieldValue('classification_system', val);
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item label="Hodnota">
                <Field name="code" value={values.code || null} component={InputField} />
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AlternativeClassificationForm;
