import React from 'react';
import { useSelector } from 'react-redux';
import withDbItemsWrapper from '../../../utills/hoc/dbItemsWrapper';
import UnitsModal from './components/modals/UnitsModal';
import UnitsDetailView from './components/UnitsDetailView';

const Units = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.unit);

  return (
    <div className="w-full">
      {detail && <UnitsDetailView data={detail} editUnit={handleEdit} />}

      <UnitsModal
        title={isEdit ? 'Aktivovat/Zdeaktivovat' : 'Přidat jednotku'}
        visible={openDialog}
        submitForm={handleSubmit}
        formProps={isEdit ? detail?.version_set[0] : null}
        isActiveForm={isEdit ? true : false}
        // error={error}
        onClose={handleClose}
      />
    </div>
  );
};

export default withDbItemsWrapper(Units, 'unit', 'left-panel-unit/');
