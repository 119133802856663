import React from 'react';
import { useDispatch } from 'react-redux';
import { confirmRegistration } from '../../store/actions/auth';

const Confirm = (props) => {
  const dispatch = useDispatch();

  const _onClick = () => {
    dispatch(confirmRegistration(props.match.params.token));
    props.history.push('/');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <span style={{ color: 'blue', cursor: 'pointer' }} onClick={_onClick}>
        Potvrdit registraci
      </span>
    </div>
  );
};

export default Confirm;
