import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import Bar from '../../../elements/Bar';
import List from '../../../elements/List';
import classNames from 'classnames';
import styles from './style.module.css';

export default function ProjectInformationView({ data, makeEditable }) {
  const permission = useSelector((state) => state.auth.pagePermission);

  return (
    <div className={classNames('w-full', styles.projectInformationContainer)}>
      <Bar
        text={'Detail'}
        content={permission === 2 ? [<EditOutlined key={1} onClick={makeEditable} />] : []}
      />
      <Bar text={'Popis koncepce'} />
      <div className="p-4" dangerouslySetInnerHTML={{ __html: data?.concept_description }} />

      <Bar className="mt-1" text={'Společná poznámka'} content={[]} />
      <div className="p-4" dangerouslySetInnerHTML={{ __html: data?.common_note }} />

      <Bar className="mt-1" text={'Prvky technické zprávy'} content={[]} />
      <List
        data={(data?.classified_technical_report_item_set || []).map((item) => ({
          name: item.title,
          value: '',
        }))}
      />
    </div>
  );
}
