import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import style from './listOfUsers.module.css';
import { arrayOf, shape, func, bool, string, number } from 'prop-types';

export default class ListOfUsers extends PureComponent {
  static propTypes = {
    users: arrayOf(
      shape({
        degree_before: string,
        email: string,
        id: string,
        image_url: string,
        status: number,
        title_f: string,
      })
    ),
    fetchMoreData: func,
    hasMore: bool,
    onInvite: func,
  };

  static defaultProps = {
    users: [],
    fetchMoreData: () => {},
    hasMore: true,
    onInvite: () => {},
  };

  render() {
    const { users, fetchMoreData, hasMore, onInvite } = this.props;
    return (
      <InfiniteScroll
        dataLength={users.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        height={400}
      >
        <div className={style.container}>
          {users.map((item, i) => (
            <UserItem key={i} props={item} onInvite={onInvite} />
          ))}
        </div>
      </InfiniteScroll>
    );
  }
}

const UserItem = ({
  props: { email, degree_before, title_f, image_url, id, status, degree_after },
  onInvite,
}) => {
  console.log({ degree_after });
  return (
    <div className={style.listItem}>
      <span className={style.left}>
        <Avatar
          size={40}
          style={{ marginRight: '10px' }}
          {...(image_url
            ? {
                src: image_url,
              }
            : {
                icon: <UserOutlined />,
              })}
        />
        <span className={style.item}>{`${degree_before} ${title_f} ${degree_after}`}</span>
        <span className={style.item}>{email} </span>
      </span>
      {status === 1 && (
        <button className={style.button} onClick={() => onInvite(id)}>
          {'Pozvat'}
        </button>
      )}
      {status === 2 && (
        <button className={`${style.button} ${style.disabled}`}>{'Již zde pracuje'}</button>
      )}
      {status === 3 && (
        <button onClick={() => onInvite(id)} className={`${style.button}`}>
          {'Odeslat znovu'}
        </button>
      )}
    </div>
  );
};
