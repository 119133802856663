const initialState = {
  detail: {},
  loading: false,
  employees: [],
  storage: {
    type: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_LOADING_COMPANY':
      return {
        ...state,
        loading: true,
      };
    case 'COMPANY_LOADED_SUCCESSFULL':
      return {
        ...state,
        loading: false,
        detail: action.data,
      };
    case 'COMPANY_EDITED_SUCCESSFULL':
      return {
        ...state,
        detail: { ...state.detail, ...action.data },
      };
    case 'UPDATE_ADMINISTRATORS_SUCCESSFULL':
      return {
        ...state,
        detail: { ...action.data },
      };
    case 'EMPLOYEES_LOADED_SUCCESSFULL':
      return {
        ...state,
        employees: [...action.data],
      };
    case 'FETCH_STORAGE_SUCCESSFULL':
      return {
        ...state,
        storage: action.data,
      };
    case 'UPDATE_STORAGE_SUCCESSFULL':
      return {
        ...state,
        detail: {
          ...state.detail,
          storage_info: {
            status_data: action.data?.status_data?.status,
            type: action.data?.type,
          },
        },
        storage: action.data,
      };

    case 'LICENCE_CHANGED': {
      return {
        ...state,
        detail: { ...state.detail, licence: action.data },
      };
    }
    case 'RESET_COMPANY': {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
