/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import classNames from 'classnames';

function PropertyListWithBiblio(props) {
  const rows = (data) => {
    if (!data) return [];
    return data.map((item) => {
      const countOfBilios = item.biblios.length;
      const rowSpan = countOfBilios > 0 ? countOfBilios : 1;

      if (countOfBilios === 0) {
        return (
          <tr
            className="text-color2 even:bg-neutral-100 even:bg-opacity-60 border-b last:border-b-0"
            key={item.id}
          >
            <td className={classNames('p-2 text-[13px] border-r bg-white')} rowSpan={rowSpan}>
              {item.title}
            </td>

            <td className="p-2 text-[13px] border-r bg-white"></td>
          </tr>
        );
      }

      return item.biblios.map((biblio, i) => {
        return (
          <tr
            className="text-color2 even:bg-neutral-100 even:bg-opacity-60 border-b last:border-b-0"
            key={item.id}
          >
            {i === 0 && (
              <td className={classNames('p-2 text-[13px] border-r bg-white')} rowSpan={rowSpan}>
                {item.title}
              </td>
            )}
            <td key={biblio.id} className="p-2 text-[13px] border-r bg-white">
              {biblio.title}
            </td>
          </tr>
        );
      });
    });
  };

  return (
    <table className="table-fixed w-full">
      <thead className="border-b border-neutral-300">
        <tr className="uppercase text-xs text-left text-color2 tracking-wide">
          <th className="py-2 w-[50px]  pl-2 border-r last:border-r-0">Vlastnost</th>
          <th className="py-2 w-[50px]  text-left pl-2 border-r last:border-r-0">Biblio</th>
        </tr>
      </thead>
      <tbody className="border-b">{rows(props.data)}</tbody>
    </table>
  );
}

export default PropertyListWithBiblio;
