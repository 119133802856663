import React, { useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import Label from '../../../../Label';
import { AreaField } from '../../../../../shared/AreaField';
import ModalButton from '../../../../../shared/modal/ModalButton';
import { SelectField } from '../../../../../shared/SelectField';
import { useEffect } from 'react';
import { apiService } from '../../../../../utills/api';

const ProfessionBondModal = ({ visible, title, formProps, onSubmit, onClose }) => {
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    description: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);
  const [professions, setProfessions] = useState([]);

  useEffect(() => {
    const fetchProfessions = async () => {
      try {
        const data = (await apiService.get('/api/v1/profession-bond/dropdown/')).data;
        setProfessions(data);
      } catch {
        //
      }
    };

    fetchProfessions();
  }, []);

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          description: formProps?.description || null,
          professionId: formProps?.profession?.id || null,
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, {
            ...values,
            profession: values.professionId,
          });
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="description" title="Popisek" required />
                  <Field
                    name="description"
                    value={values.description || null}
                    placeholder="Popisek"
                    component={AreaField}
                  />
                </div>

                <div>
                  <Label name="profession" title="Profese" />
                  <Field
                    name="professionId"
                    value={values.professionId || null}
                    data={professions || []}
                    placeholder="Profese"
                    onChange={(val) => {
                      setFieldValue('professionId', val);
                    }}
                    component={SelectField}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ProfessionBondModal;
