import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';

const detailData = ({ title, is_active } = {}) => {
  return [
    { name: 'Název', value: title },
    { name: 'Aktivní', value: is_active ? 'Ano' : 'Ne' },
  ];
};

export default function BuildingModelDetailView({ data, makeEditable }) {
  const permission = useSelector((state) => state.auth.pagePermission);

  return (
    <div className="w-full">
      <Bar
        text={'Detail modelu stavby'}
        content={permission === 2 && data ? [<EditOutlined key={1} onClick={makeEditable} />] : []}
      />
      <List data={detailData(data || {})} />
    </div>
  );
}
