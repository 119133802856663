import * as THREE from 'three';

export const findProperty = (id, properties) => {
  if (!properties) return null;
  return properties.find((property) => property.property_definition.id === id);
};

export const setupGeometry = (nTriang, nVertices, VertCoord, TriangIndices, material) => {
  const group = new THREE.Group();
  const output = {
    meshForBound: [],
    mesh: [],
    group: new THREE.Group(),
  };
  const meshForBound = [];

  for (const i in nTriang) {
    const SplitVertCoordtmp = VertCoord[i].split(';');
    const SplitnTriang = nTriang[i].split(';');
    const SplitTriangIndices = TriangIndices[i].split(';');
    const SplitTnVertices = nVertices[i].split(';');

    const SplitVertCoord = SplitVertCoordtmp.map((element) => {
      return Number(element.replace(',', '.')) * 304.8;
    });

    let x = 0;
    let y = 0;

    const mesh = new THREE.Mesh();

    for (const l in SplitTnVertices) {
      const TempGeometry = new THREE.BufferGeometry();

      const verticesArray = [];

      for (let k = x; k < x + SplitTnVertices[l] * 3; k += 3) {
        verticesArray.push(SplitVertCoord[k], SplitVertCoord[k + 1], SplitVertCoord[k + 2]);
      }

      const vertices = new Float32Array(verticesArray);
      TempGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

      const indicesArray = [];
      for (let j = y; j < y + SplitnTriang[l] * 3; j += 3) {
        indicesArray.push(
          SplitTriangIndices[j],
          SplitTriangIndices[j + 1],
          SplitTriangIndices[j + 2]
        );
      }

      const indices = new Uint32Array(indicesArray);
      TempGeometry.setIndex(indices);

      const TempMesh = new THREE.Mesh(TempGeometry, material);
      TempMesh.castShadow = true;
      TempMesh.receiveShadow = true;

      meshForBound.push(TempMesh);
      mesh.add(TempMesh);

      group.add(TempMesh);

      const edges = new THREE.EdgesGeometry(TempGeometry, 10);
      const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x000000 }));

      group.add(line);
      x += SplitTnVertices[l] * 3;
      y += SplitnTriang[l] * 3;
    }
  }

  output.meshForBound = meshForBound;
  output.mesh = mesh;
  output.group = group;

  return output;
};

// input: geometry
export const setupCurves = (input) => {
  const lines = [];
  if (input.Curves != null)
    input.Curves.forEach((e) => {
      var points = [];
      // if (selectedLayers.find((ele) => ele.layer === e.layer)) {
      e.points.forEach((point) => {
        points.push(new THREE.Vector3(point.X, point.Y, point.Z));
      });

      var lineDistances = [];
      var d = 0;
      for (let i = 0; i < points.length; i++) {
        if (i > 0) {
          d += points[i].distanceTo(points[i - 1]);
        }
        lineDistances[i] = d;
      }

      var lineGeom = new THREE.BufferGeometry().setFromPoints(points);
      lineGeom.setAttribute(
        'lineDistance',
        new THREE.BufferAttribute(new Float32Array(lineDistances), 1)
      );

      let lineMaterial = new THREE.LineBasicMaterial({
        color: 0x0000ff,
        linewidth: 1,
        linecap: 'round',
        linejoin: 'round',
      });

      if (e.lineStyle != null) {
        var json = JSON.parse(e.lineStyle);
        var segments = [];
        if (json != null) segments = json.Pattern;

        var totalSize = 0;
        var conds = '';
        const scale = 5000;
        try {
          JSON.parse(segments).forEach((f) => {
            if (f['Type'] == 'Dash') {
              conds += `
          if(distance > ${(totalSize * scale).toFixed(1)} && distance < ${(
                (totalSize + f['Lenght']) *
                scale
              ).toFixed(1)}){
              return false;
          }`;
              totalSize += f['Lenght'];
            }
            if (f['Type'] == 'Space') {
              conds += `
          if(distance > ${(totalSize * scale).toFixed(1)} && distance < ${(
                (totalSize + f['Lenght']) *
                scale
              ).toFixed(1)}){
              return true;
          }`;
              totalSize += f['Lenght'];
            }
            if (f['Type'] == 'Dot') {
              conds += `
          if(distance > ${(totalSize * scale).toFixed(1)} && distance < ${(
                (totalSize + 0.1) *
                scale
              ).toFixed(1)}){
              return false;
          }`;
              totalSize += 0.1;
            }
          });
        } catch (e) {
          console.log(e);
        }

        var lineFragShader = `
uniform vec3 diffuse;
uniform float opacity;
uniform float dotSize;
float gapSize;
float dashSize;
float totalSize;
varying float vLineDistance;

bool checkParameter(float distance){
    ${conds}
    return true;
}

void main() {
      totalSize = ${(totalSize * scale).toFixed(1)};
      float modulo = mod( vLineDistance, totalSize );

      if (checkParameter(modulo)) {
          discard;
        }

      gl_FragColor = vec4( diffuse, opacity );
    }
    `;

        var lineVertShader = `
attribute float lineDistance;
varying float vLineDistance;

void main() {
  vLineDistance = lineDistance;
  vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
  gl_Position = projectionMatrix * mvPosition;
}`;

        lineMaterial = new THREE.ShaderMaterial({
          uniforms: {
            diffuse: { value: new THREE.Color('red') },
            // dashSize: { value: 1 * scale },
            gapSize: { value: 2 * scale },
            dotSize: { value: 0.1 * scale },
            opacity: { value: 1 },
          },
          vertexShader: lineVertShader,
          fragmentShader: lineFragShader,
          transparent: true,
        });
      }

      var line = new THREE.Line(lineGeom, linematerial);
      lines.push(line);
    });
  return lines;
};

export const setupSceneAndCamera = (geoData) => {
  const { geo, position, zoom } = geoData;

  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    1,
    1000000
  );

  if (geo) {
    let group;
    let input = JSON.parse(geo);

    let nTriangles = input.Solids.TrianglesNum;
    let nVertices = input.Solids.VerticesNum;
    let VertCoordinates = input.Solids.Vertices;
    let TriangIndices = input.Solids.TriangleIndicies;

    let material = new THREE.MeshBasicMaterial({ color: 0xffffff });

    const GeoOut = setupGeometry(
      nTriangles,
      nVertices,
      VertCoordinates,
      TriangIndices,
      material,
      scene
    );

    const lines = setupCurves(input);

    group = GeoOut.group;

    scene.add(camera);
    lines.map((line) => group.add(line));

    // rotace prvku, aby byl naproti kameře
    group.rotateX(-Math.PI / 2);
    var bbox = new THREE.Box3();
    bbox.setFromObject(group);
    var newCenter = bbox.getCenter();

    scene.add(group);
    camera.position.set(2000, 1500, 2000); // X Z Y
    if (position != '[]')
      try {
        let parse = [];
        parse = JSON.parse(position);

        var mat = new THREE.Matrix4();
        mat.fromArray(parse);

        camera.position.setFromMatrixPosition(mat);
      } catch (e) {
        console.log(e);
      }

    camera.lookAt(newCenter);
    camera.aspect = 1;

    if (zoom) {
      camera.zoom = parseInt(zoom);
    }
    camera.updateProjectionMatrix();

    return {
      scene,
      camera,
    };
  }
};
