import { ConfigProvider } from 'antd';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/errorBoudary';
import createHistory from 'history/createBrowserHistory';

import { QueryClient, QueryClientProvider } from 'react-query';

import cs_CZ from 'antd/lib/locale/cs_CZ';
import moment from 'moment';
const history = createHistory();

const persistor = persistStore(store);
import './i18n';
import Routes from './routes';
import { store } from './store/store';

const queryClient = new QueryClient();

moment.locale('cs');

const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={cs_CZ}>
          <ErrorBoundary>
            <Routes history={history} />
          </ErrorBoundary>
        </ConfigProvider>
      </PersistGate>
    </QueryClientProvider>
  </Provider>
);

export default App;
