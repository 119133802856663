import { EditOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import React, { Suspense, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';
import DataTable from '../../../../shared/components/DataTable';
import PickValueWithClassificationModal from '../../../../shared/modals/PickValueWithClassificationModal';
import {
  addProperty,
  addTechnicalReportItem,
  removeProperty,
  removeTechnicalReportItem,
  addSolution,
  removeSolution,
  addPropertySet,
  addTechnicalReportItemSet,
  GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
} from '../../../../store/actions/typemarkTemplate';
import { isEmptyArray } from '../../../../utills/array';
import { findProperty } from '../../../../utills/typemark';

import TypeMarkComponentTable from '../../../project/typemark/components/TypeMarkComponentTable';
import SolutionPropertyDefinitionModal from '../../solution/components/modals/SolutionPropertyDefinitionModal';
import ColorsModal from '../../../project/typemark/components/modals/ColorsModal';
import TypeMarkModal from '../../../project/typemark/components/modals/TypeMarkModal';
import SolutionPickerModal from '../../../../shared/modals/SolutionPickerModal';
import { useMutation, useQueryClient } from 'react-query';
import { apiService } from '../../../../utills/api';
import BaseContainer from '../../../../containers/BaseContainer';

const detailData = ({ title, description } = {}) => [
  { name: 'Název', value: title },
  { name: 'Popis', value: description },
];

const prepareDataForComponentSet = (component_set) => {
  if (!component_set) return [];
  const result = [];
  component_set.forEach((component) => {
    const values = {
      title: component.title,
      id: component.id,
      solution: null,
    };
    if (component.solution_set.length < 1) {
      result.push({ ...values, rowSpan: 1 });
    }
    component.solution_set.forEach((solution, i) => {
      if (i === 0) {
        result.push({
          ...values,
          solution,
          rowSpan: component.solution_set.length,
        });
      } else {
        result.push({
          ...values,
          solution,
          rowSpan: 0,
        });
      }
    });
  });

  return result;
};

export default function TypeMarkTemplateDetailView({ activeItemId, data, permission }) {
  const [visibleAddPropertyDialog, setVisibleAddPropertyDialog] = useState(false);
  const [visibleAddPropertyValueDialog, setVisibleAddPropertyValueDialog] = useState(false);
  const [visibleAddPTechnicalReportItemDialog, setVisibleAddPTechnicalReportItemDialog] =
    useState(false);
  const [visibleBasicEditDialog, setVisibleBasicEditDialog] = useState(false);
  const [visibleAddComponent, setVisibleAddComponent] = useState(false);
  const [activeComponentId, setActiveComponentId] = useState(null);
  const [pickedSolutionId, setPickedSolutionId] = useState(null);
  const [visibleColorsDialog, setVisibleColorsDialog] = useState(false);
  const [propertyIdForSetValue, setPropertyIdForSetValue] = useState(false);
  const [visibleAddPropertySetDialog, setVisibleAddPropertySetDialog] = useState(false);
  const [visibleAddTechnicalReportItemSetDialog, setVisibleAddTechnicalReportItemSetDialog] =
    useState(false);
  // const { instances } = useSelector((state) => state.typeMark);
  const queryClient = useQueryClient();
  const queryKey = 'instance-type-template';

  const edit = useMutation({
    mutationFn: (values) => {
      return apiService.patch(`/api/v1/${queryKey}/${activeItemId}/`, {
        ...values,
      });
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(queryKey);
      dispatch({
        type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
        data: data,
      });
      setVisibleBasicEditDialog(false);
    },
  });

  const dispatch = useDispatch();

  const handleAddPropertySet = (value) => {
    dispatch(
      addPropertySet(activeItemId, {
        classified_property_definition_set: value.id,
      })
    );
    setVisibleAddPropertySetDialog(false);
  };

  const handleAddTechnicalReportItemSet = (value) => {
    dispatch(
      addTechnicalReportItemSet(activeItemId, {
        classified_technical_report_item_set: value.id,
      })
    );
    setVisibleAddTechnicalReportItemSetDialog(false);
  };

  const handleAddProperty = (value) => {
    dispatch(
      addProperty(activeItemId, {
        classified_property_definition: value.id,
        value: null,
      })
    );
    setVisibleAddPropertyDialog(false);
  };

  const handleRemoveProperty = (id) => {
    const selectedProperty = findProperty(id, data?.property_set);

    dispatch(
      removeProperty(activeItemId, {
        classified_property_definition: selectedProperty.property_definition.classified_id,
      })
    );
  };

  const handleAddTechnicalReportItem = (values) => {
    dispatch(
      addTechnicalReportItem(activeItemId, {
        classified_technical_report_item: values.id,
      })
    );
    setVisibleAddPTechnicalReportItemDialog(false);
  };

  const handleRemoveTechnicalReportItem = (id) => {
    dispatch(
      removeTechnicalReportItem(activeItemId, {
        classified_technical_report_item: id,
      })
    );
  };

  const handleAddPropertyValue = (selectedValue) => {
    const selectedProperty = findProperty(propertyIdForSetValue, data?.property_set);

    if (!propertyIdForSetValue) return;

    dispatch(
      addProperty(activeItemId, {
        classified_property_definition: selectedProperty.property_definition.classified_id,
        value: selectedValue.value,
      })
    );
    setVisibleAddPropertyValueDialog(false);
    setPropertyIdForSetValue(null);
  };

  const handleCloseTypeMarkDialog = () => {
    setVisibleBasicEditDialog(false);
  };

  const handleSubmitTypeMarkDialog = ({ values }) => {
    edit.mutate(values);
  };

  const handleRemoveSolution = (id) => {
    dispatch(
      removeSolution(activeItemId, {
        id: id,
      })
    );
  };

  // eslint-disable-next-line no-shadow
  const handleRemoveColor = (activeComponentId, pickedSolutionId) => {
    dispatch(addSolution(activeItemId, activeComponentId, pickedSolutionId, null));
  };

  const handleAddComponent = (pickedSolution) => {
    dispatch(addSolution(activeItemId, activeComponentId, pickedSolution));
    setVisibleAddComponent(false);
  };

  const handleSubmitColorsDialog = (values) => {
    dispatch(addSolution(activeItemId, activeComponentId, pickedSolutionId, values.appearance));
    setVisibleColorsDialog(false);
  };

  return (
    <BaseContainer fullHeight width="100%">
      <Bar text={data?.title} />
      <div>
        <div className="flex flex-row justify-start w-full flex-wrap">
          <div className="flex-1">
            <Bar
              text="Detail"
              content={
                permission === 2 && data
                  ? [<EditOutlined key={1} onClick={() => setVisibleBasicEditDialog(true)} />]
                  : []
              }
            />
            <Suspense fallback={<Skeleton active />}>
              <List data={detailData(data || {})} />
            </Suspense>
          </div>
        </div>

        <div className="flex flex-col gap-[1px]">
          <div>
            <Bar text="Komponenty" content={[]} />
            {data && isEmptyArray(data.component_set) && (
              <TypeMarkComponentTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Řešení', key: 'solution' },
                  { title: 'Vzhled', key: 'color' },
                  { title: 'id', key: 'id' },
                ]}
                data={prepareDataForComponentSet(data?.component_set)}
                onDeleteSolution={handleRemoveSolution}
                onAdd={(id) => {
                  setVisibleAddComponent(true);
                  setActiveComponentId(id);
                }}
                onDelete={() => {}}
              />
            )}
          </div>

          <div>
            <Bar
              text="Vlastnosti"
              content={
                permission === 2 && data
                  ? [
                      <PlusOutlined key={0} onClick={() => setVisibleAddPropertyDialog(true)} />,
                      <UnorderedListOutlined
                        key={1}
                        onClick={() => setVisibleAddPropertySetDialog(true)}
                      />,
                    ]
                  : []
              }
            />
            {data && isEmptyArray(data.property_set) && (
              <DataTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Hodnota', key: 'value' },
                  { title: 'Popis', key: 'description' },
                  { title: 'id', key: 'id' },
                ]}
                data={data?.property_set.map((item) => ({
                  ...item.property_definition,
                  value: item.value,
                }))}
                onDelete={handleRemoveProperty}
                onEdit={(id) => {
                  setPropertyIdForSetValue(id);
                  setVisibleAddPropertyValueDialog(true);
                }}
              />
            )}
          </div>

          <div>
            <Bar
              text="Položky technické zprávy"
              content={
                permission === 2 && data
                  ? [
                      <PlusOutlined
                        key={0}
                        onClick={() => setVisibleAddPTechnicalReportItemDialog(true)}
                      />,
                      <UnorderedListOutlined
                        key={1}
                        onClick={() => setVisibleAddTechnicalReportItemSetDialog(true)}
                      />,
                    ]
                  : []
              }
            />
            {data && isEmptyArray(data.technical_report_item_set) && (
              <DataTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Popis', key: 'description' },
                  { title: 'id', key: 'id' },
                ]}
                data={data?.technical_report_item_set}
                onDelete={handleRemoveTechnicalReportItem}
              />
            )}
          </div>
        </div>
      </div>

      <PickValueWithClassificationModal
        visible={visibleAddPropertySetDialog}
        url="left-panel-set-property-definition/"
        pickerUrl="set-property-definition/left-panel-picker/"
        onClose={() => setVisibleAddPropertySetDialog(false)}
        onSubmit={handleAddPropertySet}
      />
      <PickValueWithClassificationModal
        visible={visibleAddTechnicalReportItemSetDialog}
        url="left-panel-set-technical-report-item/"
        pickerUrl="set-technical-report-item/left-panel-picker/"
        onClose={() => setVisibleAddTechnicalReportItemSetDialog(false)}
        onSubmit={handleAddTechnicalReportItemSet}
      />
      <PickValueWithClassificationModal
        visible={visibleAddPropertyDialog}
        url="left-panel-property-definition/"
        pickerUrl="property-definition/left-panel-picker/"
        onClose={() => setVisibleAddPropertyDialog(false)}
        onSubmit={handleAddProperty}
      />
      <PickValueWithClassificationModal
        visible={visibleAddPTechnicalReportItemDialog}
        url="left-panel-technical-report-item/"
        pickerUrl="technical-report-item/left-panel-picker/"
        onClose={() => setVisibleAddPTechnicalReportItemDialog(false)}
        onSubmit={handleAddTechnicalReportItem}
      />
      <SolutionPickerModal
        visible={visibleAddComponent}
        onClose={() => setVisibleAddComponent(false)}
        classificationId={activeComponentId}
        onSubmit={handleAddComponent}
      />
      <SolutionPropertyDefinitionModal
        propertyId={propertyIdForSetValue}
        visible={visibleAddPropertyValueDialog}
        formProps={(data?.property_set || []).find(
          (property) => property.property_definition.id === propertyIdForSetValue
        )}
        onClose={() => {
          setVisibleAddPropertyValueDialog(false);
          setPropertyIdForSetValue(null);
        }}
        onSubmit={handleAddPropertyValue}
      />
      <TypeMarkModal
        title="Základní info"
        isTemplate
        visible={visibleBasicEditDialog}
        formProps={data}
        onSubmit={handleSubmitTypeMarkDialog}
        onClose={handleCloseTypeMarkDialog}
      />
      {visibleColorsDialog && (
        <ColorsModal
          title="Vzhledy"
          visible={visibleColorsDialog}
          formProps={data}
          onSubmit={handleSubmitColorsDialog}
          onClose={() => setVisibleColorsDialog(false)}
        />
      )}
    </BaseContainer>
  );
}
