// import Rollbar from "rollbar";
import React from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { Link } from 'react-router-dom';
import Logo from '../images/logoMoiry.svg';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    // rollbar: new Rollbar({
    //     accessToken: 'e52fb91581bd4e678b0438220d6a21e4',
    //     captureUncaught: true,
    //     captureUnhandledRejections: true,
    // })};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    NotificationManager.error('Nastala chyba', error.message);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // this.state.rollbar.error(error);
    // NotificationManager.error("Nastala chyba", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex gap-3 justify-center items-center w-full h-screen  bg-slate-300">
          <img src={Logo} alt="Moiry" />
          <div className="flex flex-col">
            <span className="text-xl text-neutral-700">MOIRY APP</span>
            <span className="text-base text-neutral-700">Omlováme se, ale něco se pokazilo</span>
            <a href="/" className="text-blue-500">
              Zpět na Domovskou stránku
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
