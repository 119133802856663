import React, { useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import Bar from '../../../../../elements/Bar';
import { PlusOutlined } from '@ant-design/icons';
import Label from '../../../../Label';
import { AreaField } from '../../../../../shared/AreaField';
import FormList from '../../../../../elements/FormList';
import ModalButton from '../../../../../shared/modal/ModalButton';
import FileUploader from '../../../../../shared/components/FileUploader';

const BiblioModal = ({ visible, title, formProps, onSubmit, onClose }) => {
  const [visibleAddFileDialog, setVisibleAddFileDialog] = useState(false);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    designation: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const handleAddFile = (file) => {
    formRef.current.setFieldValue('files', [...formRef.current.values.files, file]);

    setVisibleAddFileDialog(false);
  };

  const handleDeleteFile = (item) => {
    if (!formRef?.current) return null;

    const indexToRemove = formRef.current.values.files.find((ele) => ele.id === item.id);
    const newValues = formRef.current.values.files.filter((_, i) => i.id !== indexToRemove);

    formRef.current.setFieldValue('files', newValues);
  };

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          designation: formProps?.designation || null,
          publication_year: formProps?.publication_year || null,
          files: formProps?.files || [],
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, {
            ...values,
            files: values.files.map((file) => file.id),
          });
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="designation" title="Označení" required />
                  <Field
                    name="designation"
                    value={values.designation || null}
                    placeholder="Označení"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="publication_year" title="Rok vydání" />
                  <Field
                    name="publication_year"
                    value={values.publication_year || null}
                    placeholder="Rok vydání"
                    type="number"
                    component={InputField}
                  />
                </div>

                <div className="mb-1">
                  <Bar
                    text="Soubory"
                    content={[
                      <PlusOutlined key={1} onClick={() => setVisibleAddFileDialog(true)} />,
                    ]}
                  />
                  <FormList data={values.files} onDelete={handleDeleteFile} />
                </div>
                <FileUploader
                  visible={visibleAddFileDialog}
                  onSubmit={handleAddFile}
                  url={'/api/v1/biblio/upload-file/'}
                  onClose={() => setVisibleAddFileDialog(false)}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default BiblioModal;
