import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import { InputField } from '../../../../shared/InputField';
import { SelectField } from '../../../../shared/SelectField';

const TeamsForm = ({ formProps, submitOutside, submit, error, reset = false, resetConfirm }) => {
  const formRef = useRef();

  useEffect(() => {
    if (reset === true) {
      formRef.current.setValues(formProps);
      resetConfirm();
    }
  }, [reset]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && submitOutside) {
      formRef.current.submitForm();
    }

    return () => (isSubscribed = false);
  }, [submitOutside]);

  useEffect(() => {
    formRef.current.setErrors(error?.response?.data || '');
  }, [error]);

  const init = ({ title, shortcut, leader, is_active }) => ({
    title,
    shortcut,
    leader,
    is_active: is_active === true ? 1 : 2,
  });

  const prepareData = (data) =>
    data.map((ele) => ({ title: ele.employee_full_name, id: ele.employee }));

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit({
            ...values,
            is_active: values.is_active === 1 ? true : false,
          });
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          return (
            <Form labelCol={{ span: 4 }}>
              <Form.Item label="Označení">
                <Field
                  name="shortcut"
                  value={values.shortcut}
                  placeholder="Označení"
                  component={InputField}
                />
              </Form.Item>
              <Form.Item label="Název">
                <Field
                  name="title"
                  value={values.title}
                  placeholder="Název"
                  component={InputField}
                />
              </Form.Item>
              {formProps && Object.keys(formProps).length > 0 && (
                <>
                  <Form.Item label="Vedoucí">
                    <Field
                      name="employee_role"
                      value={values.leader}
                      onChange={(val) => {
                        setFieldValue('leader', val);
                      }}
                      data={prepareData(formProps?.members_list || [])}
                      placeholder="Vedoucí"
                      component={SelectField}
                    />
                  </Form.Item>
                  <Form.Item label="Stav">
                    <Field
                      name="is_active"
                      value={values.is_active}
                      onChange={(val) => {
                        console.log({ val });
                        setFieldValue('is_active', val);
                      }}
                      data={[
                        { id: 1, title: 'Aktivní' },
                        { id: 2, title: 'Neaktivní' },
                      ]}
                      placeholder=""
                      component={SelectField}
                    />
                  </Form.Item>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TeamsForm;
