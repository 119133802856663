import Bar from '../../../../elements/Bar';
import { EditOutlined } from '@ant-design/icons';
import List from '../../../../elements/List';
import { useSelector } from 'react-redux';

const SolutionDetailView = ({ data, onEdit, editDisable }) => {
  const permission = editDisable ? 0 : useSelector((state) => state.auth.pagePermission);
  const prepareData = () => {
    if (!data) return [];
    return [
      { name: 'Označení', value: data.title },
      { name: 'Funkce', value: data.function },
      { name: 'Forma', value: data.form },
      { name: 'Provedeni', value: data.execution },
    ];
  };

  return (
    <div className="border-l-2 border-neutral-200 h-full">
      <Bar
        text="Detail"
        content={permission === 2 ? [<EditOutlined key={1} onClick={onEdit} />] : []}
      />
      <div className="flex">
        {data?.images && data?.images.length > 0 && (
          <>
            <img src={data?.images[0].url} className="h-[350px]" />
          </>
        )}
        <List data={prepareData()} />
      </div>
      <div className="mb-[1px]">
        <Bar text="Vlastnosti" />
        <List
          data={(data?.property_definition_set || []).map((property) => ({
            name: property.title,
            value: property.value,
          }))}
        />
      </div>
      <div className="mb-[1px]">
        <Bar text="Profesní vazby" />
        <List
          data={(data?.profession_bond_set || []).map((professionBond) => ({
            name: professionBond.title,
          }))}
        />
      </div>
      <div className="mb-[1px]">
        <Bar text="Technické zprávy" />
        <List
          data={(data?.technical_report_item_set || []).map((technicalReportItem) => ({
            name: technicalReportItem.title,
            value: technicalReportItem.description,
          }))}
        />
      </div>
    </div>
  );
};

export default SolutionDetailView;
