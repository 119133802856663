import { DownloadOutlined } from '@ant-design/icons';
import React from 'react';
import stylesLicence from './licenceList.module.css';

const ContentListItem = ({ items }) => {
  let arrayOfItems = [];
  let id = null;
  for (var key in items) {
    if (key === 'id') {
      id = items[key];
    } else if (key === 'document') {
      arrayOfItems.push(
        <a href={items[key]} target="_blank" rel="noreferrer">
          <DownloadOutlined />
        </a>
      );
    } else {
      arrayOfItems.push(items[key]);
    }
  }
  return (
    <li className={stylesLicence.listRow}>
      {arrayOfItems.map((ele, i) => (
        <span className={stylesLicence.item} key={i}>
          {ele}
        </span>
      ))}
    </li>
  );
};

const Header = () => {
  return (
    <li className={`${stylesLicence.listRow} ${stylesLicence.italic}`}>
      {[
        'Č. faktury',
        'Datum vystavení',
        'Částka bez DPH',
        'Období',
        'Částka s DPH',
        'Stáhnout',
      ].map((ele, i) => (
        <span className={stylesLicence.item} key={i}>
          {ele}
        </span>
      ))}
    </li>
  );
};

const BillList = ({ data }) => {
  if (!Array.isArray(data)) return null;
  return (
    <ul style={{ paddingLeft: 0 }}>
      <Header />
      {data.map((ele, i) => ele && <ContentListItem key={i} items={ele} />)}
    </ul>
  );
};

export default BillList;
