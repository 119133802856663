import React, { useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import Bar from '../../../../../elements/Bar';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import PickValueWithClassificationModal from '../../../../../shared/modals/PickValueWithClassificationModal';
import Label from '../../../../Label';
import { AreaField } from '../../../../../shared/AreaField';
import FormList from '../../../../../elements/FormList';
import ModalButton from '../../../../../shared/modal/ModalButton';
import HasValueModal from './HasValuesModal';
import { ReactSortable } from 'react-sortablejs';

const PropertiesModal = ({ visible, title, formProps, onSubmit, onClose }) => {
  const [visibleHasValues, setVisibleHasValues] = useState(false);
  const [visibleAddBiblioDialog, setVisibleAddBiblioDialog] = useState(false);
  const [visibleAddUnitDialog, setVisibleAddUnitDialog] = useState(false);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    shortcut: Yup.string().required('Povinné pole'),
    description: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const handleAddHasValues = (values) => {
    if (!formRef.current) return;

    formRef.current.values.value_choices.push({
      ...values,
      order: formRef.current.values.value_choices.length + 1,
    });

    setVisibleHasValues(false);
  };

  const handleRemoveValueChoice = (valueChoice) => {
    if (!formRef.current) return;

    const newValues = formRef.current.values.value_choices.filter(
      (ele) => ele.order !== valueChoice.order
    );

    formRef.current.setFieldValue('value_choices', newValues);
  };

  const handleAddUnit = (item) => {
    formRef.current.setFieldValue('unit', item);

    setVisibleAddUnitDialog(false);
  };

  const handleDeleteUnit = () => {
    formRef.current.setFieldValue('unit', null);
  };

  const handleDeleteBiblio = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.biblios.filter((biblio) => biblio.id !== id);

    formRef.current.setFieldValue('biblios', newValues);
  };

  const handleAddBiblio = (item) => {
    formRef.current.setFieldValue('biblios', [...formRef.current.values.biblios, item]);

    setVisibleAddBiblioDialog(false);
  };

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          shortcut: formProps?.shortcut || null,
          description: formProps?.description || null,
          biblios: formProps?.biblios || [],
          unit: formProps?.unit || null,
          value_choices: formProps?.value_choices || [],
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, {
            ...values,
            biblios: values.biblios.map((biblio) => biblio.id),
            value_choices: values.value_choices,
            unit: values.unit?.id,
          });
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="shortcut" title="Značka" required />
                  <Field
                    name="shortcut"
                    value={values.shortcut || null}
                    placeholder="Značka"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="description" title="Popisek" required />
                  <Field
                    name="description"
                    value={values.description || null}
                    placeholder="Popisek"
                    component={AreaField}
                  />
                </div>

                <div className="mb-1">
                  <Bar
                    text="Biblio"
                    content={[
                      <PlusOutlined key={1} onClick={() => setVisibleAddBiblioDialog(true)} />,
                    ]}
                  />
                  <FormList data={values.biblios} onDelete={handleDeleteBiblio} />
                </div>

                <div className="mb-1">
                  <Bar
                    text="Jednotka"
                    content={[
                      <PlusOutlined key={1} onClick={() => setVisibleAddUnitDialog(true)} />,
                    ]}
                  />
                  <FormList data={values.unit ? [values.unit] : []} onDelete={handleDeleteUnit} />
                </div>

                <div className="mb-1">
                  <Bar
                    text="Nabývá hodnot"
                    content={[<PlusOutlined key={1} onClick={() => setVisibleHasValues(true)} />]}
                  />
                  <ReactSortable
                    list={values.value_choices}
                    setList={(items) => {
                      setFieldValue('value_choices', items);
                    }}
                    className="w-full overflow-hidden flex flex-col gap-1 text-color2 max-w-[530px]"
                    sort={true}
                  >
                    {(values.value_choices || []).map((val, i) => (
                      <div
                        className="flex justify-between py-1 px-2 gap-4 rounded-sm items-center bg-neutral-50 cursor-move"
                        key={val.order}
                      >
                        <div className="flex items-center gap-4 ">
                          <span className="min-w-[180px]">{val.value}</span>
                          <span>{val.description}</span>
                        </div>

                        <DeleteOutlined key={1} onClick={() => handleRemoveValueChoice(val)} />
                      </div>
                    ))}
                  </ReactSortable>
                </div>
              </Form>
              <PickValueWithClassificationModal
                visible={visibleAddBiblioDialog}
                url="left-panel-biblio/"
                pickerUrl="biblio/left-panel/"
                onClose={() => setVisibleAddBiblioDialog(false)}
                onSubmit={handleAddBiblio}
              />
              <PickValueWithClassificationModal
                visible={visibleAddUnitDialog}
                url="left-panel-unit"
                pickerUrl="unit/left-panel/"
                onClose={() => setVisibleAddUnitDialog(false)}
                onSubmit={handleAddUnit}
              />
              <HasValueModal
                title="Přidat položku"
                visible={visibleHasValues}
                onClose={() => setVisibleHasValues(false)}
                onSubmit={handleAddHasValues}
              />
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PropertiesModal;
