import React, { useState } from 'react';
import PanelWithClassification from '../components/PanelWithClassification';
import Modal from '../modal/Modal';
import ModalButton from '../modal/ModalButton';

const PickClassificationModal = ({ visible, onSubmit, onClose, url, pickerUrl } = {}) => {
  const [activeItem, setActiveItem] = useState(null);
  const handleMakeActive = async (item) => {
    setActiveItem(item);
  };

  const reset = () => {
    setActiveItem(null);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(activeItem);
    reset();
  };

  return (
    <Modal
      title={'Vyberte položku'}
      visible={visible}
      footer={[
        <ModalButton key={1} action={handleClose}>
          Zavřít
        </ModalButton>,
        <ModalButton primary key={2} action={handleSubmit}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <div className="flex h-[350px]">
        <div className="border-x border-neutral-200 mr-[1px]">
          <PanelWithClassification
            makeActive={handleMakeActive}
            valueAsObject
            active={activeItem?.id}
            onSearch={() => {}}
            addItem={() => {}}
            onFilter={() => {}}
            className="!h-[300px]"
            width={600}
            url={url}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PickClassificationModal;
