import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { NotificationManager } from 'react-notifications';

import { getContracts, pickContract } from '../../services/contractPickerService';
import { setContract } from '../../store/actions/contract';

// const groupStyles = {
//   background: 'black',
//   padding: '5px 0px',
//   display: 'flex',
// };

// function GroupHeading(props) {
//   return (
//     <div style={groupStyles}>
//       <components.GroupHeading {...props} />
//     </div>
//   );
// }

// const makePreviewData = (data = {}) => [
//   {
//     label: 'Poslední',
//     options: data.last_used.map((ele) => ({
//       label: ele.title,
//       value: ele.contract,
//     })),
//   },
//   {
//     label: 'Oblíbené',
//     options: data.favorite.map((ele) => ({
//       label: ele.title,
//       value: ele.contract,
//     })),
//   },
// ];

const makeData = (data = []) =>
  data.map((ele) => ({
    label: ele.title,
    value: ele.phase,
    data: {
      contract: ele.contract,
      model: ele.model,
      phase: ele.phase,
    },
  }));

export default function ContractPicker() {
  const [items, setItems] = useState([]);
  const { phaseId } = useSelector((state) => state.contract);
  const dispatch = useDispatch();

  const makeReq = async (term) => {
    try {
      const resContracts = await getContracts(term);
      const items = makeData(resContracts.data.results);
      setItems(resContracts.data.results);
      return items;
    } catch (e) {
      return [];
    }
    // return () => {
    //   setItems([]);
    // };
  };

  // useEffect(() => {
  //   makeReq();
  // }, []);
  const findCurrentContract = () => items.find((option) => option.phase === phaseId);

  const currentContractOption = findCurrentContract(items);

  const handleChange = async (arg) => {
    try {
      if (arg?.value) {
        // const res = (await pickContract(arg?.value?.contract)).data;
        dispatch(setContract(arg?.data));
      }
    } catch (e) {
      NotificationManager.error('Chyba', 'Omlouváme se, něco se pokazilo');
    }
  };

  const loadOptions = (term) => makeReq(term);

  return (
    <div className="App">
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        value={{
          label: currentContractOption?.title,
          value: currentContractOption?.phase,
        }}
        placeholder="Vyberte akci"
        styles={{
          container: (base) => ({
            ...base,
            backgroundColor: 'white',
            marginLeft: '23px',
            marginRight: '1px',
          }),
          control: (base) => ({
            ...base,
            minHeight: 18,
            top: 0,
            margin: 0,
            padding: 0,
            border: 0,
            lineHeight: '14px',
            borderRadius: 0,
          }),
          placeholder: (base) => ({
            ...base,
            lineHeight: '14px',
            fontSize: '12px',
          }),
          dropdownIndicator: (base) => ({
            ...base,
            padding: 1,
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: '1px',
          }),
          Svg: (base) => ({
            ...base,
            height: '18px',
            width: '18px',
          }),
          multiValue: (base) => ({
            ...base,
            backgroundColor: '#f2f2f2',
          }),
          valueContainer: (base) => ({
            ...base,
            padding: 0,
          }),
          indicatorContainer: (base) => ({
            ...base,
            height: '18px',
            top: 0,
            margin: 0,
            padding: 0,
          }),
          input: (base) => ({
            ...base,
            top: 0,
            margin: 0,
            padding: 0,
          }),
          option: (base, state) => ({
            ...base,
            borderBottom: '1px dotted pink',
            background: state.isSelected ? '#CCCCCC' : 'white',
            color: 'black',
            fontSize: '14px',
            padding: 4,
            border: 'none',
          }),
          singleValue: (base) => ({
            ...base,
            // padding: "0px 4px",
            lineHeight: '14px',
            fontSize: '12px',
            borderRadius: 0,
            background: '#f2f2f2',
            color: 'black',
            display: 'flex',
          }),
          groupHeading: (base) => ({
            ...base,
            flex: '1 1',
            color: 'black',
            border: 'none',
            background: 'white',
            margin: 0,
          }),
          menuList: () => ({
            background: 'white',
            maxHeight: '300px',
            overflow: 'auto',
          }),
        }}
        // components={{ GroupHeading }}
        isSearchable
        // onMenuOpen={makeReq}
        onChange={(value) => handleChange(value)}
        name="contract-picker"
        // options={items}
      />
    </div>
  );
}
