import React, { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import Modal from '../../../../shared/modal/Modal';
import ModalButton from '../../../../shared/modal/ModalButton';
import Label from '../../../Label';
import Yup from '../../../../utills/myYup';
import PickValueWithClassificationModal from '../../../../shared/modals/PickValueWithClassificationModal';
import Bar from '../../../../elements/Bar';
import { PlusOutlined } from '@ant-design/icons';
import FormList from '../../../../elements/FormList';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import classNames from 'classnames';
import styles from '../style.module.css';

const ProjectInformationModal = ({ visible, title, formProps, onSubmit, onClose }) => {
  const formRef = useRef();
  const [visibleAddTechnicalReportItem, setVisibleAddTechnicalReportItem] = useState(false);

  const validationSchema = Yup.object().shape({
    concept_description: Yup.string().required('Povinné pole'),
    common_note: Yup.string().required('Povinné pole'),
  });

  const handleAddTechnicalReportItem = (item) => {
    formRef.current.setFieldValue('classified_technical_report_item_set', [
      ...formRef.current.values.classified_technical_report_item_set,
      item,
    ]);
    setVisibleAddTechnicalReportItem(false);
  };

  const handleDeleteTechnicalReportItem = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.classified_technical_report_item_set.filter(
      (item) => item.id !== id
    );

    formRef.current.setFieldValue('classified_technical_report_item_set', newValues);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton type="submit" key={2} primary action={() => formRef.current.submitForm()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={{
          common_note: formProps?.common_note || null,
          concept_description: formProps?.concept_description || null,
          classified_technical_report_item_set:
            formProps?.classified_technical_report_item_set || [],
        }}
        onSubmit={(values) => {
          const reports = values.classified_technical_report_item_set.map((item) => item.id);
          onSubmit({
            ...values,
            classified_technical_report_item_set: reports,
          });
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          return (
            <>
              <Form>
                <div className={classNames(styles.projectInformationContainer)}>
                  <Label name="concept_description" title="Popis koncepce" required />
                  <CKEditor
                    data={values.concept_description}
                    editor={ClassicEditor}
                    config={{
                      list: {
                        listType: 'numbered',
                      },
                    }}
                    onChange={(e, editor) => {
                      const data = editor.getData();
                      setFieldValue('concept_description', data);
                    }}
                  />
                </div>

                <div>
                  <Label name="common_note" title="Společná poznámka" required />
                  <CKEditor
                    data={values.common_note}
                    editor={ClassicEditor}
                    config={{
                      list: {
                        listType: 'numbered',
                      },
                    }}
                    onChange={(e, editor) => {
                      const data = editor.getData();
                      setFieldValue('common_note', data);
                    }}
                  />
                </div>
                <div className="mb-4 mt-2">
                  <Bar
                    text="Prvky technické zprávy"
                    content={[
                      <PlusOutlined
                        key={1}
                        onClick={() => setVisibleAddTechnicalReportItem(true)}
                      />,
                    ]}
                  />
                  <FormList
                    data={values.classified_technical_report_item_set}
                    onDelete={handleDeleteTechnicalReportItem}
                  />
                </div>
                <PickValueWithClassificationModal
                  visible={visibleAddTechnicalReportItem}
                  url="left-panel-technical-report-item/"
                  pickerUrl="technical-report-item/left-panel-picker/"
                  onClose={() => setVisibleAddTechnicalReportItem(false)}
                  onSubmit={handleAddTechnicalReportItem}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ProjectInformationModal;
