import React, { useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import Bar from '../../../../../elements/Bar';
import { PlusOutlined } from '@ant-design/icons';
import PickValueWithClassificationModal from '../../../../../shared/modals/PickValueWithClassificationModal';
import Label from '../../../../Label';
import { AreaField } from '../../../../../shared/AreaField';
import FormList from '../../../../../elements/FormList';
import ModalButton from '../../../../../shared/modal/ModalButton';

const PropertySetModal = ({ visible, title, formProps, onSubmit, onClose }) => {
  const [visibleAddPropertyDialog, setVisibleAddPropertyDialog] = useState(false);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    description: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const handleAddProperty = (item) => {
    formRef.current.setFieldValue('property_definition_set', [
      ...formRef.current.values.property_definition_set,
      item,
    ]);

    setVisibleAddPropertyDialog(false);
  };

  const handleDeleteProperty = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.property_definition_set.filter((ele) => ele.id !== id);

    formRef.current.setFieldValue('property_definition_set', newValues);
  };

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          description: formProps?.description || null,
          property_definition_set: formProps?.property_definition_set || [],
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, {
            ...values,
            property_definition_set: [
              ...new Set(values.property_definition_set.map((property) => property.id)),
            ],
          });
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="description" title="Popisek" required />
                  <Field
                    name="description"
                    value={values.description || null}
                    placeholder="Popisek"
                    component={AreaField}
                  />
                </div>

                <div className="mb-1">
                  <Bar
                    text="Vlastnosti"
                    content={[
                      <PlusOutlined key={1} onClick={() => setVisibleAddPropertyDialog(true)} />,
                    ]}
                  />
                  <FormList data={values.property_definition_set} onDelete={handleDeleteProperty} />
                </div>
              </Form>
              <PickValueWithClassificationModal
                visible={visibleAddPropertyDialog}
                url="left-panel-property-definition"
                pickerUrl="property-definition/left-panel-picker/"
                onClose={() => setVisibleAddPropertyDialog(false)}
                onSubmit={handleAddProperty}
              />
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PropertySetModal;
