import axios from 'axios';
import { apiService } from '../utills/api';

const googleMapsApiKey = 'AIzaSyDKWqkfaGVcQV9vHNjW9wFAW2SLlSAsqOo';
export const getBuildings = async (next, search, filter) => {
  let cancel;
  return await apiService.get(`/api/v1/building/left-panel/`, {
    params: {
      page: next,
      search,
      page_size: 10,
      ...filter,
    },
    cancelToken: new axios.CancelToken((c) => cancel === c),
  });
};

export const getBuildingDetail = async (id) => await apiService.get(`/api/v1/building/${id}/`);

export const getBuildingMap = async () => await apiService.get(`/api/v1/building/map/`);

export const postBuilding = async (data) => await apiService.post(`/api/v1/building/`, data);

export const getDetailFromPoint = async (lat, lang) =>
  await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lang}&key=${googleMapsApiKey}`
  );

export const getEtape = async (id) => await apiService.get(`/api/v1/etape/${id}/`);

export const updateEtape = async (id, values) =>
  await apiService.patch(`/api/v1/etape/${id}/`, values);

export const copyEtape = async (id) => await apiService.get(`/api/v1/etape/${id}/copy/`);

export const getEtapsForSelect = async () => await apiService.get(`/api/v1/etape/dropdown/`);

export const getCultureForSelect = async () => await apiService.get(`/api/v1/culture/dropdown/`);

export const addProject = async (values) => await apiService.post(`/api/v1/contract/`, values);

export const getProjects = async () => await apiService.get(`/api/v1/contract/left-panel/`);

export const getProjectDetail = async (id) => await apiService.get(`/api/v1/contract/${id}/`);

export const updateProject = async (id, values) =>
  await apiService.patch(`/api/v1/contract/${id}/`, values);
