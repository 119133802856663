import React from 'react';
import { useSelector } from 'react-redux';
import ProfessionBondModal from './components/modals/ProfessionBondModal';
import ProfessionBondView from './components/ProfessionBondView';
import withDbItemsWrapper from '../../../utills/hoc/dbItemsWrapper';

const ProfessionBond = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.professionBond);

  return (
    <div className="w-full min-w-0">
      {detail && <ProfessionBondView data={detail} onEdit={handleEdit} />}
      <ProfessionBondModal
        title="Profesní vazba"
        visible={openDialog}
        formProps={isEdit ? detail : null}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
};

export default withDbItemsWrapper(ProfessionBond, 'professionBond', 'left-panel-profession-bond/');
