import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import MainContainer from '../../containers/MainContainer';
import Bar from '../../elements/Bar';
import LeftPanel from '../../shared/components/LeftPanel';
import { apiService } from '../../utills/api';
import { Spin } from 'antd';

const Print = () => {
  const { contractId, modelId, phaseId } = useSelector((state) => state.contract);
  const { menuId } = useSelector((state) => state.typeMark);
  const employee = useSelector((state) => state.auth.current_employee);
  const permission = useSelector((state) => state.auth.pagePermission);
  const [buildingObjects, setBuildingObjects] = useState([]);
  const [selectedBO, setSelectedBO] = useState(null);
  const [visible, setVisible] = useState(false);
  const [next, setNext] = useState(null);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    let { data: result } = await apiService.get(`/api/v1/building-object/left-panel/`, {
      params: {
        employee: employee,
        page: 1,
        model: modelId,
      },
    });

    setBuildingObjects(result?.results || []);
    setSelectedBO(getFirst(result?.results)?.id);
    setNext(result?.next);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submit = async (id, data) => {};

  const getFirst = (data) => (data?.length > 0 ? data[0] : {});

  const fetchMore = async () => {
    if (next === null) return;
    try {
      const response = (
        await apiService.get(`/api/v1/building-object/left-panel/`, {
          params: {
            employee: employee,
            page: next,
            model: modelId,
            search: search,
            ...filter,
          },
        })
      ).data;

      if (response) {
        setBuildingObjects((buildingObjects) => [...buildingObjects, ...response.results]);
        setNext(response.next);
      }
    } catch (e) {
      //   setError(e);
    }
  };

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
    try {
      let { data } = await getRights(1, e?.target?.value, filter);
      if (data) {
        setBuildingObjects(data?.results || []);
        setNext(data?.next);
      }
    } catch (e) {
      //
    }
  };

  const onFilter = async (filterValues) => {
    try {
      let { data } = await getRights(1, search, filterValues);
      if (data) {
        setBuildingObjects(data?.results || []);
        setNext(data?.next);
      }
    } catch (e) {
      //
    }
  };

  const handlePrint = async () => {
    setLoading(true);
    try {
      await apiService.get('/api/v1/print/schedule-pdf/', {
        params: {
          employee: employee,
          model: modelId,
          phase: phaseId,
          contract: contractId,
          menuId: menuId,
          building_object: selectedBO,
        },
      });
      NotificationManager.success('Hotovo', 'Zkontrolujte si e-mail');
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MainContainer>
        <LeftPanel
          data={buildingObjects}
          addItem={() => setVisible(true)}
          submit={submit}
          onSearch={onSearch}
          onFilter={onFilter}
          permission={permission}
          fetchMore={fetchMore}
          hasMore={!!next}
          filteredValues={{
            is_active: [
              { value: true, title: 'Aktivní' },
              { value: false, title: 'Neaktivní' },
            ],
          }}
          title="Stavební objekty"
          makeActive={(id) => setSelectedBO(id)}
          active={selectedBO}
        />
        {selectedBO && (
          <>
            <div className="pointer-events-none">
              <LeftPanel
                data={[{ title: 'Tisk', id: 1 }]}
                disableAdd
                addItem={() => {}}
                submit={() => {}}
                onSearch={() => {}}
                onFilter={() => {}}
                fetchMore={() => {}}
                hasMore={false}
                filteredValues={{
                  is_active: [
                    { value: true, title: 'Aktivní' },
                    { value: false, title: 'Neaktivní' },
                  ],
                }}
                title="Šablony"
                makeActive={() => {}}
                active={1}
              />
            </div>
            <div className="w-full">
              <Bar text="" />
              <div className="flex flex-col h-[320px] align-center justify-center">
                {!loading ? (
                  <button className="bg-color4 h-full" onClick={handlePrint}>
                    Generovat PDF
                  </button>
                ) : (
                  <Spin />
                )}
              </div>
            </div>
          </>
        )}
      </MainContainer>
    </>
  );
};

export default Print;
