import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import React, { useState, useEffect } from 'react';
import treeStyle from './rightTree.module.css';
import { usePermission } from '../../../../hookd/usePerrmission';

const styles = {
  tree: {
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'middle',
  },
  toggle: {
    width: '1em',
    height: '1em',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  contents: {
    willChange: 'transform, opacity, height',
    padding: '2.5px 0px 0px 28px',
  },
};

function Contents({ children, ...style }, open) {
  const newStyle = open
    ? { opacity: 1, height: 'auto', ...style }
    : { opacity: 0, height: 0, ...style };

  return <div style={{ ...newStyle, ...styles.contents }}>{children}</div>;
}

function Tree(props) {
  const { isEditable } = usePermission();
  const [state, setState] = useState({
    open: props.open || true,
    visible: props.visible || true,
  });

  const toggle = () => {
    props.children && setState({ ...state, open: !state.open, visible: false });
  };

  useEffect(() => {
    const newState = { ...state, ...props };
    setState(newState);
  }, [props]);

  const { children, content, style, status } = props;

  return (
    <div className={treeStyle.main} style={{ ...styles.tree, ...style }}>
      <div className={treeStyle.row}>
        <span>
          {/* <Icon style={{ ...styles.toggle, opacity: 1 }} onClick={toggle} /> */}
          <span className={treeStyle.controls}>
            {children ? (
              state.open ? (
                <UpOutlined onClick={toggle} />
              ) : (
                <DownOutlined onClick={toggle} />
              )
            ) : (
              ''
            )}
          </span>
          <span className={treeStyle.text}>{content}</span>
        </span>
        {/* {isEditable && ( */}
        <Radio.Group
          className={treeStyle.containerFlex}
          onChange={props.changeRules}
          value={status}
          disabled={!isEditable}
        >
          {[1, 2, 0].map(
            // cteni, editace, odeprit
            (ele) => (
              <Radio
                key={ele}
                style={{ margin: 0 }}
                className={treeStyle.radioContainer}
                value={ele}
              />
            )
          )}
        </Radio.Group>
        {/* )} */}
      </div>
      {Contents(props, state.open)}
    </div>
  );
}

export default Tree;
