import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import PanelWithClassification from '../../shared/components/PanelWithClassification';
import MainContainer from '../../containers/MainContainer';
import actionsFactory from '../../store/actions/factory/dbItemsActionFactory';
import { Spin } from 'antd';
import { SearchContext } from '../../components/project/building/Building';
import NLeftPanel from '../../shared/components/NLeftPanel';
import { useMutation, useQueryClient } from 'react-query';
import { apiService } from '../api';
import { emptyValuesAsUndefined } from '../form';
import { NotificationManager } from 'react-notifications';

const withDbItemsWrapper = (Component, name, url) =>
  // eslint-disable-next-line react/display-name
  function () {
    const permission = useSelector((state) => state.auth.pagePermission);
    const { activeClassification, detail, isLoading } = useSelector((state) => state[name]);

    const [openDialog, setOpenDialog] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [search, setSearch] = useState(null);
    const [filter, setFilter] = useState({});
    const splittedName = name.split(/(?=[A-Z])/);
    let queryKey = splittedName.join('-').toLowerCase();
    if (name === 'propertySet') {
      queryKey = 'set-property-definition';
    }
    if (name === 'technicalReportItemSet') {
      queryKey = 'set-technical-report-item';
    }
    if (name === 'contractListing') {
      queryKey = 'contract';
    }
    if (name === 'typeMarkTemplate') {
      queryKey = 'instance-type-template';
    }

    const TYPE = splittedName.join('_').toUpperCase();

    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { fetchDetail, setActiveClassification, clear, clearItems } = actionsFactory(name);
    const { contractId, modelId } = useSelector((state) => state.contract);

    const create = useMutation({
      mutationFn: (values) => {
        return apiService.post(`/api/v1/${queryKey}/`, {
          ...values,
          contract: contractId,
          model: modelId,
          classification: activeClassification,
        });
      },
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(queryKey);
        NotificationManager.success('Položka úspěšně vytvořena');
        dispatch(fetchDetail(data.id));
        setOpenDialog(false);
      },
      onError: () => {
        NotificationManager.error('Něco se pokazilo');
      },
    });

    const edit = useMutation({
      mutationFn: ({ id, values }) => {
        return apiService.post(`/api/v1/${queryKey}/${id}/create-version/`, {
          ...emptyValuesAsUndefined(values),
        });
      },
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(queryKey);
        NotificationManager.success('Položka úspěšně editována');
        dispatch(fetchDetail(id));
        setOpenDialog(false);
      },
      onError: () => {
        NotificationManager.error('Něco se pokazilo');
      },
    });

    const makeActive = (id) => {
      dispatch({ type: `GET_${TYPE}_DETAIL`, name: TYPE });
      dispatch(fetchDetail(id));
    };

    useEffect(
      () => () => {
        dispatch(clear());
      },
      []
    );

    const makeActiveClassification = (id) => {
      dispatch(setActiveClassification(id));
      queryClient.invalidateQueries(queryKey);
    };

    const handleSubmit = (id, values) => {
      if (id) {
        edit.mutate({ id, values });
        setIsEdit(false);
      } else {
        create.mutate(values);
      }
      setOpenDialog(false);
    };

    const handleEdit = () => {
      setIsEdit(true);
      setOpenDialog(true);
    };

    const handleClose = () => {
      setIsEdit(false);
      setOpenDialog(false);
    };

    const onSearch = async (e) => {
      setSearch(e?.target?.value);
    };

    const onFilter = async (value) => {
      setFilter(value);
    };

    return (
      <SearchContext.Provider
        value={{
          search,
          filter,
          setSearch,
          setFilter,
        }}
      >
        <MainContainer>
          <div className="flex">
            <PanelWithClassification
              addItem={() => {}}
              submit={() => {}}
              onSearch={() => {
                dispatch(clear());
              }}
              permission={permission}
              onFilter={() => {}}
              fetchMore={() => {}}
              url={url}
              filteredValues={{
                is_active: [
                  { value: true, title: 'Aktivní' },
                  { value: false, title: 'Neaktivní' },
                ],
              }}
              title="Třídník"
              disableAdd
              makeActive={makeActiveClassification}
              active={activeClassification}
            />
            {activeClassification && (
              <div className="border-l-2 h-full border-neutral-200">
                <NLeftPanel
                  queryKey={queryKey}
                  title={t(name)}
                  addItem={() => setOpenDialog(true)}
                  submit={() => {}}
                  onSearch={onSearch}
                  onFilter={onFilter}
                  permission={permission}
                  filteredValues={{}}
                  makeActive={makeActive}
                  active={detail?.id}
                  classification={activeClassification}
                />
              </div>
            )}
            {isLoading ? (
              <div className="flex w-full h-[400px] justify-center items-center">
                <Spin />
              </div>
            ) : (
              <Component
                openDialog={openDialog}
                isEdit={isEdit}
                handleSubmit={handleSubmit}
                handleEdit={handleEdit}
                handleClose={handleClose}
              />
            )}
          </div>
        </MainContainer>
      </SearchContext.Provider>
    );
  };
export default withDbItemsWrapper;
