import React from 'react';
import TopNavigation from '../components/navigation/topNavigation';
import { each } from 'underscore';

const SubSubLayout = (props) => {
  return (
    <ul
      style={{
        height: '60px',
        margin: '0',
        display: 'flex',
        marginLeft: '50px',
      }}
    >
      {(() => {
        function compare(a, b) {
          if (a.route_3 === b.route_3) return true;
          else return false;
        }
        var intersect = function (a, arr2) {
          var intersect = [];
          each(arr2, function (b) {
            if (compare(a, b)) intersect.push(b);
          });
          return intersect;
        };

        if (props.items.length !== 0) {
          return props.items.map((item, i) =>
            props.match.path === item.route_1 + item.route_2 ? (
              intersect(item, props.items2).length > 0 ? (
                <TopNavigation
                  key={i}
                  route={
                    `${props.match.path}` + item.route_3 + intersect(item, props.items2)[0].route_4
                  }
                  routeForActive={`${props.match.path}` + item.route_3}
                  currentItem={item}
                  text={item.title}
                  {...props}
                  icon={item.icon_svg || ''}
                />
              ) : (
                <TopNavigation
                  key={i}
                  route={`${props.match.path}` + item.route_3}
                  currentItem={item}
                  routeForActive={`${props.match.path}` + item.route_3}
                  text={item.title}
                  {...props}
                  icon={item.icon_svg || ''}
                />
              )
            ) : (
              ''
            )
          );
        } else {
          return;
        }
      })()}
    </ul>
  );
};

export default SubSubLayout;
