import React from 'react';
import { useSelector } from 'react-redux';
import styles from './list.module.css';

const ListItem = ({ name, value, actions }) => {
  return (
    <li className={styles.contentListItem}>
      <span className={styles.contentListItemName}>
        {name ? name : ''}
        {value && ':'}
      </span>
      {value && <span className={styles.contentListItemValue}>{value ? value : ''}</span>}
      <span className={styles.actionContainer}>
        {actions.map((ele, i) => (
          <div key={i} className={styles.actionIcon}>
            {ele}
          </div>
        ))}
      </span>
    </li>
  );
};

const List = ({ data, actions }) => {
  const permission = useSelector((state) => state.auth.pagePermission);
  const icons = (id) =>
    (actions || []).map((item, i) => (
      <div key={i} className="actions-icon">
        {item(id)}
      </div>
    ));

  if (!data) return null;

  return (
    <ul className="w-full" style={{ paddingLeft: 0 }}>
      {data.map(
        (ele, i) =>
          ele && (
            <ListItem
              key={i}
              name={ele.name}
              value={ele.value}
              actions={permission === 2 ? icons(ele.id) : []}
            />
          )
      )}
    </ul>
  );
};

export default List;
