import { EditOutlined } from '@ant-design/icons';
import { Delete20Filled, DeleteDismiss20Regular } from '@fluentui/react-icons';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';

const BuildingObjectDetailView = ({ onEdit, onDelete }) => {
  const permission = useSelector((state) => state.auth.pagePermission);
  const { detail } = useSelector((state) => state.buildingObject);

  const data = useMemo(() => {
    return [
      { name: 'Typ stavebního objektu', value: detail.building_object_type },
      { name: 'Název', value: detail.title },
      { name: 'Plný popis', value: detail.description },
      { name: 'Číslo', value: detail.number },
    ];
  }, [detail]);

  return (
    <div className="border-l-2 border-neutral-200 h-full">
      <Bar
        text="Detail"
        content={
          permission === 2
            ? [
                <EditOutlined key={1} onClick={onEdit} />,
                ...(detail.is_active
                  ? [<Delete20Filled key={2} onClick={onDelete} />]
                  : [<DeleteDismiss20Regular key={2} onClick={onDelete} />]),
              ]
            : []
        }
      />
      <List data={data || []} />
    </div>
  );
};

export default BuildingObjectDetailView;
