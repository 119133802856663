import React, { useCallback, useContext, useRef } from 'react';
import classNames from 'classnames/bind';
import { Checkbox, Spin } from 'antd';
import { SearchContext } from '../components/project/building/Building';

const NavListCustomInfinite = ({
  makeActive,
  data,
  active,
  fetchMoreData,
  hasMore,
  loading,
  isFetching,
}) => {
  const observer = useRef();
  const context = useContext(SearchContext) ?? {};

  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreData();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleToggleSelected = (id) => {
    if (context.selected[id]) {
      let old = { ...context.selected };
      delete old[id];
      context.setSelected?.(old);
    } else {
      context.setSelected?.((selected) => ({ ...selected, [id]: id }));
    }
  };

  const handleClick = (e, index) => {
    if (e.shiftKey) {
      const last = Object.keys(context.selected)[Object.keys(context.selected).length - 1];
      const lastItemIndex = data.findIndex((item) => item.id === last);

      let newSelected = {};

      if (index >= lastItemIndex) {
        for (let i = lastItemIndex + 1; i <= index; i++) {
          newSelected = { ...newSelected, [data[i].id]: data[i].id };
        }

        context.setSelected?.((selected) => ({ ...selected, ...newSelected }));
      } else {
        for (let i = index; i < lastItemIndex; i++) {
          newSelected = { ...newSelected, [data[i].id]: data[i].id };
        }

        context.setSelected?.((selected) => ({ ...selected, ...newSelected }));
      }
    }
  };

  const _makeActive = (id) => {
    makeActive(id);
    context.setSelected?.({ [id]: id });
  };

  const items = (data || []).map((item, i) => {
    if (data.length === i + 1) {
      return (
        <div
          key={item.id}
          ref={lastItemRef}
          className={classNames(
            'flex items-center pl-2 align-middle hover:bg-color2 px-2 border-b border-neutral-200 text-gray-500 space-x-1 group hover:text-white',
            {
              'bg-color4 text-black': item.id === active || !!context.selected?.[item.id],
            }
          )}
        >
          {context.setSelected && (
            <Checkbox
              id={item.id}
              className={classNames('opacity-0 group-hover:opacity-100 pr-1', {
                'opacity-100': item.id === active || context.selected?.[item?.id],
              })}
              checked={item.id === active || !!context.selected?.[item?.id]}
              onChange={() => handleToggleSelected(item.id)}
              onClick={(e) => handleClick(e, i)}
            />
          )}
          <label
            // htmlFor={item.id}
            key={item.id}
            onClick={() => _makeActive(item.id)}
            className={classNames(
              'inline-block list-none w-full min-h-[30px]  cursor-pointer pt-[5px] text-sm '
            )}
          >
            {item.title}
          </label>
        </div>
      );
    }
    return (
      <div
        key={item.id}
        className={classNames(
          'flex items-center pl-2 align-middle hover:bg-color2 px-2 border-b border-neutral-200 text-gray-500 space-x-1 group hover:text-white',
          {
            'bg-color4 text-black': item.id === active || !!context.selected?.[item.id],
          }
        )}
      >
        {context.setSelected && (
          <Checkbox
            id={item.id}
            className={classNames('opacity-0 group-hover:opacity-100 pr-1', {
              'opacity-100': item.id === active || context.selected?.[item?.id],
            })}
            checked={item.id === active || !!context.selected?.[item?.id]}
            onChange={() => handleToggleSelected(item.id, i)}
            onClick={(e) => handleClick(e, i)}
          />
        )}
        <label
          // htmlFor={item.id}
          key={item.id}
          onClick={() => _makeActive(item.id)}
          className={classNames(
            'inline-block list-none w-full min-h-[30px] hover:text-white cursor-pointer pt-[5px] text-sm'
          )}
        >
          {item.title}
        </label>
      </div>
    );
  });

  if (isFetching) {
    items.push(
      <div className="flex justify-center py-3">
        <Spin />
      </div>
    );
  }

  return items;
};

export default NavListCustomInfinite;
