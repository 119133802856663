import React from 'react';
import { Button, Form } from 'antd';
import { Field, withFormik } from 'formik';
import * as yup from 'yup';
import { InputField } from '../../shared/InputField';
const FormItem = Form.Item;

class C extends React.Component {
  render() {
    const { values, handleSubmit } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit} className="w-[350px]">
          <Field name="email" value={values.title} placeholder="Email" component={InputField} />

          <FormItem>
            <Button block htmlType="submit">
              potvrdit
            </Button>
          </FormItem>
        </form>
        <span className="error">{this.props.errorFromServer}</span>
      </>
    );
  }
}

const validationSchema = yup.object({
  email: yup.string().email().required('Povinné pole'),
});

const ResetPasswordEmailForm = withFormik({
  validationSchema,
  mapPropsToValues: () => ({ email: '' }),
  handleSubmit: async (values, { props, setErrors }) => {
    const errors = await props.submit(values);
    if (errors) {
      setErrors(errors);
    }
  },
})(C);

export default ResetPasswordEmailForm;
