import FormItem from 'antd/es/form/FormItem';
import { Field, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { InputField } from '../../../shared/InputField';
import { SelectField } from '../../../shared/SelectField';
import Modal from '../../../shared/modal/Modal';
import ModalButton from '../../../shared/modal/ModalButton';
import { apiService } from '../../../utills/api';
import FormItemWithCheckbox from '../features/FormItemWithCheckbox';
import useGetDocument from '../hooks/getDocument';
import Yup from '../../../utills/myYup';
import { Loader } from 'three/src/Three.js';
import useGetInheritedValues from '../hooks/getInheritedValues';

const PartModal = ({ formProps, onSubmit, visible, documentId, documentationId, onClose }) => {
  const formRefs = useRef();
  const { data } = useGetDocument(formProps?.id ?? documentId);
  const { data: inherited } = useGetInheritedValues(
    documentationId,
    formProps?.parent ?? documentId
  );

  const initialState = {
    reference_number: data?.reference_number ?? null,
    title: data?.title ?? null,
    chief_engineer_name: data?.chief_engineer_name ?? null,
    builder_name: data?.builder_name ?? null,
    processor: data?.processor ?? null,
    profession: data?.profession ?? null,
    document_type: data?.document_type ?? null,
  };

  const [documentTypes, setDocumentTypes] = useState([]);

  useEffect(() => {
    const getDocumentTypes = async () => {
      try {
        const { data } = await apiService.get('/api/v1/document/types/');
        const options = data.map(({ title, shortcut }) => ({
          id: shortcut,
          title,
        }));
        setDocumentTypes(options);
      } catch (e) {
        //
      }
    };

    getDocumentTypes();
  }, []);

  const ValidationSchema = Yup.object().shape({
    document_type: Yup.string().required('Povinné pole'),
    title: Yup.string().required('Povinné pole'),
  });

  if (!data?.length) {
    <div className="w-full h-full flex items-center justify-center">
      <Loader />
    </div>;
  }

  return (
    <Modal
      title={formProps ? 'Editace dokumentu' : 'Vytvoření dokumentu'}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton type="submit" key={2} primary action={() => formRefs.current?.submitForm()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRefs}
        enableReinitialize={true}
        initialValues={documentId ? {} : initialState}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setErrors }) => {
          const errors = onSubmit(formProps ? formProps.id : null, values);
          if (errors) {
            setErrors(errors);
          }
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          return (
            <Form>
              <FormItem label="Název" labelCol={{ span: 24 }} required>
                <Field
                  name="title"
                  value={values.title || undefined}
                  placeholder=""
                  component={InputField}
                />
              </FormItem>

              <FormItem label="Typ dokumentu" labelCol={{ span: 24 }}>
                <Field
                  name="document_type"
                  value={values.document_type || undefined}
                  placeholder=""
                  required
                  component={SelectField}
                  data={documentTypes}
                  onChange={(val) => {
                    setFieldValue('document_type', val);
                  }}
                />
              </FormItem>

              {values.document_type === 'SH' && (
                <FormItemWithCheckbox
                  label="Číslo výkresu"
                  name="reference_number"
                  placeholder=""
                  component={InputField}
                  value={values.reference_number || undefined}
                  inherited={inherited?.reference_number}
                  onChangeParent={(val) => {
                    console.log({ val });
                    setFieldValue('reference_number', val);
                  }}
                />
              )}

              <FormItemWithCheckbox
                label="Stavebník"
                name="builder_name"
                placeholder=""
                component={InputField}
                value={values.builder_name || undefined}
                inherited={inherited?.inherited?.builder_name}
                onChangeParent={(val) => {
                  setFieldValue('builder_name', val);
                }}
              />
              <FormItemWithCheckbox
                label="Hlavní inženýr projektu"
                name="chief_engineer_name"
                placeholder=""
                component={InputField}
                value={values.chief_engineer_name || undefined}
                inherited={inherited?.inherited?.chief_engineer_name}
                onChangeParent={(val) => {
                  setFieldValue('chief_engineer_name', val);
                }}
              />
              <FormItemWithCheckbox
                label="Zpracovatel objektu"
                name="processor"
                placeholder=""
                component={InputField}
                value={values.processor || undefined}
                inherited={inherited?.inherited?.processor}
                onChangeParent={(val) => {
                  setFieldValue('processor', val);
                }}
              />
              <FormItemWithCheckbox
                label="Profese"
                name="profession"
                placeholder=""
                component={InputField}
                value={values.profession || undefined}
                inherited={inherited?.inherited?.profession}
                onChangeParent={(val) => {
                  setFieldValue('profession', val);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default PartModal;
