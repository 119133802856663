import { Form, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import ModalButton from '../../../../shared/modal/ModalButton';
import { Field, Formik } from 'formik';
import Yup from '../../../../utills/myYup';
import { InputField } from '../../../../shared/InputField';
import { SelectField } from '../../../../shared/SelectField';
import { SketchPicker } from 'react-color';

const ColorsModal = ({ visible, onClose, submitForm, formProps, title }) => {
  const formRef = useRef();

  const buttons = () => {
    let _buttons = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
      <ModalButton primary key={2} action={() => formRef?.current?.submitForm()}>
        Potvrdit
      </ModalButton>,
    ];

    return _buttons;
  };

  console.log({ formProps });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    hex: Yup.string().required('Povinné pole'),
  });

  return (
    <Modal title={title} visible={visible} header={[]} onClose={onClose} footer={buttons()}>
      <div>
        <Formik
          innerRef={formRef}
          validationSchema={validationSchema}
          enableReinitialize={true}
          initialValues={{
            title: formProps?.model_color?.title || null,
            hex: formProps?.model_color?.hex || null,
            is_active: formProps ? (formProps.is_active ? 1 : 2) : 1,
            color_code: formProps?.model_color?.color_code || null,
          }}
          onSubmit={(values, { resetForm }) => {
            submitForm(formProps?.id, {
              model_color: { ...values },
              is_active: values.is_active === 1 ? true : false,
            });
            resetForm();
          }}
        >
          {(props) => {
            const { values, setFieldValue } = props;

            return (
              <Form labelCol={{ span: 5 }}>
                <Form.Item label="Název">
                  <Field
                    name="title"
                    value={values.title}
                    placeholder="Název"
                    component={InputField}
                  />
                </Form.Item>
                <Form.Item label="Hexadecimal">
                  <Field
                    name="hex"
                    value={values.hex}
                    placeholder="Hexa"
                    component={InputField}
                    disabled
                  />
                </Form.Item>

                <Form.Item label="Kód barvy">
                  <Field
                    name="color_code"
                    value={values.color_code}
                    placeholder="Kód barvy"
                    component={InputField}
                  />
                </Form.Item>
                <Form.Item label="Status">
                  <Field
                    name="is_active"
                    value={values.is_active || null}
                    placeholder=""
                    component={SelectField}
                    data={[
                      { value: 1, id: 1, title: 'Aktivní' },
                      { value: 2, id: 2, title: 'Neaktivní' },
                    ]}
                    onChange={(val) => setFieldValue('is_active', val)}
                  />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <SketchPicker
                    color={values?.hex || ''}
                    onChange={({ hex }) => setFieldValue('hex', hex)}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ColorsModal;
