import React, { useEffect, useState } from 'react';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import ListOfUsers from '../ListOfUsers';
import Search from 'antd/lib/input/Search';
import { apiService } from '../../../../../utills/api';
import UsersForm from '../UsersForm';
import { NotificationManager } from 'react-notifications';

const getPeople = async (current, page, search) =>
  await apiService.get(`/api/v1/company/${current}/user-for-invite/`, {
    params: {
      page: page,
      search: search,
    },
  });

const createEmployee = async (current, data) =>
  await apiService.post(`/api/v1/company/${current}/invite-employee/`, data);

const InvitePeopleModal = ({ visible, onClose, current, roles }) => {
  const [invite, setInvite] = useState(false);
  const [currentInvId, setCurrentInvId] = useState(null);
  const [search, setSearch] = useState(null);
  const [people, setPeople] = useState([]);
  const [nextPeople, setNextPeople] = useState(null);
  const [error, setError] = useState(null);
  const [reset, setReset] = useState(false);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (current) {
        try {
          let { data } = await getPeople(current, 1, null);
          if (data) {
            setPeople(data?.results || []), setNextPeople(data?.next);
          }
        } catch (e) {
          //
        }
      }
    };

    fetchData();
  }, [current]);

  const fetchMorePeople = async () => {
    if (nextPeople !== null) {
      try {
        let { data } = await getPeople(current, nextPeople, search);

        if (data) {
          setPeople(people.concat(data?.results || [])), setNextPeople(data?.next);
        }
      } catch (e) {
        //   setError(e);
      }
    }
  };

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
    try {
      let { data } = await getPeople(current, 1, e?.target?.value);
      if (data) {
        setPeople(data?.results || []), setNextPeople(data?.next);
      }
    } catch (e) {
      //
    }
  };

  const buttons = () => {
    return [
      <ModalButton primary key={2} action={onClose}>
        Zavřít
      </ModalButton>,
    ];
  };

  const buttonsNewEmployee = () => {
    return [
      <ModalButton key={1} action={() => setInvite(false)}>
        Zavřít
      </ModalButton>,
      <ModalButton primary key={2} action={() => setSubmit(true)}>
        Vytvořit
      </ModalButton>,
    ];
  };

  const _onInvite = (id) => {
    setInvite(true);
    setCurrentInvId(id);
  };

  const _onSubmit = async (data) => {
    let result = { ...data, user: currentInvId };
    try {
      setSubmit(false);
      let res = await createEmployee(current, result);

      if (res) {
        NotificationManager.success(res?.data?.detail);
      }
      setInvite(false);
    } catch (e) {
      setError(e);
    }
  };

  const _onClose = () => {
    setInvite(false);
  };

  const _user = people.find((ele) => ele.id === currentInvId);

  return (
    <>
      <Modal
        title={'Vyhledat existujícího uživatele'}
        visible={visible}
        header={[]}
        onClose={onClose}
        footer={buttons()}
      >
        <Search placeholder="Vyhledat uživatele" onChange={onSearch} />
        <ListOfUsers
          users={people}
          fetchMoreData={fetchMorePeople}
          onInvite={_onInvite}
          hasMore={nextPeople !== null ? true : false}
        />
      </Modal>
      <Modal
        title={`Založení pracovníka: ${_user?.degree_before} ${_user?.title_f}`}
        visible={invite}
        header={[]}
        onClose={_onClose}
        footer={buttonsNewEmployee()}
      >
        <UsersForm
          submitOutside={submit}
          formProps={{}}
          error={error}
          reset={reset}
          roles={roles}
          resetConfirm={() => setReset(false)}
          submit={_onSubmit}
          isNewEmployee={true}
        />
      </Modal>
    </>
  );
};

export default InvitePeopleModal;
