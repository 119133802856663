import { apiService } from '../../utills/api';
export const GET_TYPE_MARK_TEMPLATES_SUCCESS = 'GET_TYPE_MARK_TEMPLATES_SUCCESS';
export const GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS = 'GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS';
export const GET_TYPE_MARK_GEO_SUCCESS = 'GET_TYPE_MARK_GEO_SUCCESS';
export const TYPE_MARK_TEMPLATE_ACTIVE_CLASSIFICATION = 'TYPE_MARK_TEMPLATE_ACTIVE_CLASSIFICATION';
export const CLEAR_TYPE_MARK_TEMPLATES = 'CLEAR_TYPE_MARK_TEMPLATES';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const REMOVE_PROPERTY = 'REMOVE_PROPERTY';
export const ADD_TECHNICAL_REPORT_ITEM = 'ADD_TECHNICAL_REPORT_ITEM';
export const REMOVE_TECHNICAL_REPORT_ITEM = 'REMOVE_TECHNICAL_REPORT_ITEM';
export const TYPE_MARK_BASIC_INFO_UPDATED = 'TYPE_MARK_BASIC_INFO_UPDATED';
export const ROUTE_ID_ADDED = 'ROUTE_ID_ADDED';
export const GET_TYPE_MARK_INSTANCES = 'GET_TYPE_MARK_INSTANCES';
export const GET_TYPE_MARK_UPDATE_GEO_SUCCESS = 'GET_TYPE_MARK_UPDATE_GEO_SUCCESS';
export const TYPE_MARK_TEMPLATE_CREATED_SUCCESS = 'TYPE_MARK_TEMPLATE_CREATED_SUCCESS';

export const fetchTypeMarkTemplates = (search, page, filter) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.get(`/api/v1/instance-type-template/left-panel/`, {
        params: {
          page: page,
          page_size: 10,
          search: search,
          is_active: [true],
          ...filter,
          contract: getState().contract.contractId,
          phase: getState().contract.phaseId,
          menu: getState().typeMark.menuId,
          model: getState().contract.modelId,
          classification: getState().typeMarkTemplate.activeClassification,
        },
      });

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATES_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const createTemplate = (values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.post(`/api/v1/instance-type-template/`, {
        ...values,
        company: getState().auth.current_company,
        classification: getState().typeMarkTemplate.activeClassification,
      });

      if (data) {
        dispatch({
          type: TYPE_MARK_TEMPLATE_CREATED_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchDetail = (id) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.get(`/api/v1/instance-type-template/${id}/`, {
        params: {
          contract: getState().contract.contractId,
          phase: getState().contract.phaseId,
          model: getState().contract.modelId,
        },
      });

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const addSolution = (id, classificationId, solutionId, appearance) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/add-solution/`,
        {
          classification: classificationId,
          classified_solution: solutionId,
          appearance: appearance,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeSolution = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/remove-solution/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const addTechnicalReportItemSet = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/apply-technical-report-item-set/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const addPropertySet = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/apply-property-definition-set/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const addProperty = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/add-property/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeProperty = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/remove-property/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const addTechnicalReportItem = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/add-technical-report-item/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeTechnicalReportItem = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/remove-technical-report-item/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateTypeMarkBasicData = (id, values) => {
  return async (dispatch, getState) => {
    try {
      let { data } = await apiService.patch(
        `/api/v1/instance-type-template/${id}/`,
        {
          ...values,
        },
        {
          params: {
            contract: getState().contract.contractId,
            phase: getState().contract.phaseId,
            model: getState().contract.modelId,
          },
        }
      );

      if (data) {
        dispatch({
          type: GET_TYPE_MARK_TEMPLATE_DETAIL_SUCCESS,
          data: data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setMenuId = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ROUTE_ID_ADDED,
      data: id,
    });
  };
};

export const setActiveClassification = (id) => {
  return async (dispatch) => {
    dispatch({
      type: TYPE_MARK_TEMPLATE_ACTIVE_CLASSIFICATION,
      data: id,
    });
  };
};

export const clearItems = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_TYPE_MARK_TEMPLATES });
  };
};
