import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MainContainer from '../../../containers/MainContainer';
import LeftPanel from '../../../shared/components/LeftPanel';
import { apiService } from '../../../utills/api';
import RightsModal from './components/modals/RightsModal';
import RightsDetailView from './components/RightsDetailView';
import { SearchContext } from '../../project/building/Building';

const getRights = async (nextProps, search, filter) =>
  await apiService.get(`/api/v1/current-company/roles/`, {
    params: {
      page: nextProps,
      search,
      ...filter,
    },
  });

function RightsController({ history }) {
  const current = useSelector((state) => state.auth.current_company);
  const employee = useSelector((state) => state.auth.current_employee);
  const permission = useSelector((state) => state.auth.pagePermission);
  const [rights, setRights] = useState([]);
  const [currentRight, setCurrentRight] = useState(null);
  const [active, setActive] = useState(null);
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(null);
  const [next, setNext] = useState(null);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({});

  const fetchData = async () => {
    if (current) {
      const { data: result } = await apiService.get(`/api/v1/current-company/roles/`, {
        params: {
          employee,
          page: 1,
        },
      });

      setRights(result?.results || []);
      setNext(result?.next);

      if (result?.results) {
        const { id } = getFirst(result?.results);
        if (id) {
          const { data: activeRight } = await apiService.get(
            `/api/v1/company-role/${id}/get_menu_treeview/`
          );
          setActive(id);
          setCurrentRight(activeRight);
        } else {
          setCurrentRight(null);
        }
      }
    }
  };

  useEffect(() => {
    if (!current) {
      history.push('/');
    }
    fetchData();
  }, [current]);

  const makeActive = async (id) => {
    if (id) {
      setActive(id);
      const { data: result } = await apiService.get(
        `/api/v1/company-role/${id}/get_menu_treeview/`
      );
      setCurrentRight(result);
    }
  };

  const makeEditable = () => {
    setEdit(true);
    setVisible(true);
  };

  const onClose = () => {
    setEdit(false);
    setVisible(false);
  };

  const submit = async (id, data) => {
    if (id) {
      const { data: result } = await apiService.patch(`/api/v1/company-role/${id}/`, {
        ...data,
        company: current,
      });
      if (result) {
        const index = rights.findIndex((ele) => ele.id === id);
        const newR = [...rights];
        newR[index] = result;
        setRights(newR);
      }
    } else {
      const { data: result } = await apiService.post(`/api/v1/company-role/`, {
        ...data,
        company: current,
      });
      if (result) {
        setRights([...rights, result]);
      }
    }
    setEdit(false);
    setVisible(false);
  };

  const getFirst = (data) => (data?.length > 0 ? data[0] : {});

  const _changeRules = async (id, data) => {
    try {
      const result = await apiService.post(`/api/v1/company-role/${active}/set_menu_treeview/`, {
        status: data,
        id,
      });
      if (result?.data) setCurrentRight(result?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMoreRights = async () => {
    if (next !== null) {
      try {
        const { data } = await getRights(next, search);

        if (data) {
          setRights(rights.concat(data?.results || []));
          setNext(data?.next);
        }
      } catch (e) {
        //   setError(e);
      }
    }
  };

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
    try {
      const { data } = await getRights(1, e?.target?.value, filter);
      if (data) {
        setRights(data?.results || []);
        setNext(data?.next);
      }
    } catch (e) {
      //
    }
  };

  const onFilter = async (filterValues) => {
    console.log({ filterValues });
    try {
      const { data } = await getRights(1, search, filterValues);
      if (data) {
        setRights(data?.results || []);
        setNext(data?.next);
      }
    } catch (e) {
      //
    }
  };

  return (
    <>
      <SearchContext.Provider
        value={{
          search,
          filter,
          setSearch,
          setFilter,
        }}
      >
        <MainContainer>
          <LeftPanel
            data={rights}
            addItem={() => setVisible(true)}
            submit={submit}
            onSearch={onSearch}
            onFilter={onFilter}
            permission={permission}
            fetchMore={fetchMoreRights}
            hasMore={next !== null}
            filteredValues={{
              is_active: [
                { value: true, title: 'Aktivní' },
                { value: false, title: 'Neaktivní' },
              ],
            }}
            title="Role ve firmě"
            makeActive={makeActive}
            active={active}
          />
          {currentRight && (
            <RightsDetailView
              data={currentRight}
              makeEditable={makeEditable}
              changeRules={_changeRules}
              permission={permission}
            />
          )}
        </MainContainer>
        <RightsModal
          title={edit ? 'Editace role' : 'Nová role'}
          visible={visible}
          submitEditForm={submit}
          data={edit && rights.find((ele) => ele.id === active)}
          onClose={onClose}
        />
      </SearchContext.Provider>
    </>
  );
}

export default RightsController;
