import { Button } from 'antd';
import { Link } from 'react-router-dom';

const SuccessFulRegistration = () => {
  return (
    <div className="flex flex-col gap-2 h-screen w-full justify-center items-center">
      <h1>Registraci dokončíte potvrzením v e-mailu.</h1>

      <Link to="/login">
        <Button>Zpět na příhlášení</Button>
      </Link>
    </div>
  );
};

export default SuccessFulRegistration;
