import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import { InputField } from '../../../../shared/InputField';
import * as Yup from 'yup';

const BillForm = ({ formProps = {}, submitOutside, submit, error }) => {
  const formRef = useRef();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && submitOutside) {
      formRef.current.submitForm();
    }

    return () => (isSubscribed = false);
  }, [submitOutside]);

  useEffect(() => {
    formRef.current.setErrors(error?.response?.data || '');
  }, [error]);

  const init = ({ invoicing_email }) => ({
    invoicing_email,
  });

  const ValidationSchema = Yup.object().shape({
    invoicing_email: Yup.string().email('Zadejte validní e-mail!'),
  });

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {(props) => {
          const { values } = props;

          return (
            <Form labelCol={{ span: 4 }}>
              <Form.Item label="Faktury zasílat na:">
                <Field
                  name="invoicing_email"
                  value={values.invoicing_email}
                  placeholder="email"
                  component={InputField}
                />
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BillForm;
