import format from 'date-fns/format';
import React from 'react';
import styles from './etapeList.module.css';
import { usePermission } from '../../../../hookd/usePerrmission';

const ContentListItem = ({ title, building_title, created, company, status, actions } = {}) => {
  const { isEditable } = usePermission();
  return (
    <li className={styles.contentListItem}>
      <span className={styles.contentListItemValue}>{title ? title : ''}</span>
      <span className={styles.contentListItemValue}>{building_title ? building_title : ''}</span>
      <span className={styles.contentListItemValue}>
        {created ? format(new Date(created), 'dd. MM. yyyy') : ''}
      </span>
      <span className={styles.contentListItemValue}>{company?.title ? company?.title : ''}</span>
      <span className={styles.contentListItemValue}>{status ? status : ''}</span>
      <span className={styles.actionContainer}>
        {isEditable &&
          actions.map((ele, i) => (
            <div key={i} className={styles.actionIcon}>
              {ele}
            </div>
          ))}
      </span>
    </li>
  );
};

const EtapeList = ({ data, actions }) => {
  // const permission = useSelector((state) => state.auth.pagePermission)
  const icons = (id) =>
    (actions || []).map((item, i) => (
      <div key={i} className="actions-icon">
        {item(id)}
      </div>
    ));

  if (!data) return null;

  return (
    <ul style={{ paddingLeft: 0 }}>
      {data.map((ele, i) => ele && <ContentListItem key={i} {...ele} actions={icons(ele.id)} />)}
    </ul>
  );
};

export default EtapeList;
