import { CheckCircleOutlined } from '@ant-design/icons';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowSize from '../../../../shared/hooks/windowSizeHook';
import styles from './logactivity.module.css';

const ContentListItem = ({ action_time, description }) => {
  return (
    <li className={styles.listItem}>
      <span className={`${styles.item} ${styles.icon}`}>
        <CheckCircleOutlined />
      </span>
      <span className={`${styles.item} ${styles.date}`}>{action_time ? action_time : ''}</span>
      <span className={`${styles.item}`}>{description ? description : ''}</span>
    </li>
  );
};

const LogActivity = ({ data, height, hasMore, fetchMoreData }) => {
  const [windowWidth, windowHeight] = useWindowSize();
  if (!data) return null;

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
      height={(windowHeight || 500) - 525}
    >
      {data.map(
        (ele, i) =>
          ele && (
            <ContentListItem key={i} action_time={ele.action_time} description={ele.description} />
          )
      )}
    </InfiniteScroll>
  );
};

export default LogActivity;
