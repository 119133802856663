import { useEffect, useState } from 'react';
import { useQuery } from '../hookd/useQuery';
import { apiService } from '../utills/api';

const PdfPrint = () => {
  const query = useQuery();
  const [data, setData] = useState(null);
  const id = query.get('id');

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await apiService.get(`/api/v1/print/${id}/`);
        setData(`${data.file}`);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, [query]);

  return <>{data && <iframe src={data} width="100%" height="100%" />}</>;
};

export default PdfPrint;
