import React, { useEffect, useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import Label from '../../../../Label';
import ModalButton from '../../../../../shared/modal/ModalButton';
import { SelectField } from '../../../../../shared/SelectField';
import { apiService } from '../../../../../utills/api';
import { useSelector } from 'react-redux';

const ColorsModal = ({ visible, title, formProps, onSubmit, onClose }) => {
  const [appearance, setAppearance] = useState([]);
  const { modelId } = useSelector((state) => state.contract);

  const ValidationSchema = Yup.object().shape({
    appearance: Yup.string(),
  });

  useEffect(() => {
    const fetchAppearance = async () => {
      try {
        const res = await apiService.get('/api/v1/appearance/dropdown/', {
          params: {
            model: modelId,
          },
        });

        setAppearance(res.data);
      } catch (e) {
        //
      }
    };

    fetchAppearance();
  }, []);

  const formRef = useRef(null);

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          appearance: formProps?.appearance || null,
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="appearance" title="Vzhled" />
                  <Field
                    name="appearance"
                    value={values.appearance || null}
                    onChange={(value) => setFieldValue('appearance', value)}
                    placeholder="..."
                    component={SelectField}
                    data={appearance}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ColorsModal;
