import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';

// Sentry.init({
//   dsn: 'https://b9dc8d267d7649738e28d0e46c0d82fd@o1127059.ingest.sentry.io/4504786393825280',
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 0.3,
// });

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
