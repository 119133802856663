import React from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import Label from '../../../../Label';
import ModalButton from '../../../../../shared/modal/ModalButton';
import { useEffect } from 'react';
import { apiService } from '../../../../../utills/api';
import { useState } from 'react';
import { Radio } from 'antd';

const SolutionPropertyDefinitionModal = ({ visible, formProps, onSubmit, propertyId, onClose }) => {
  const formRef = useRef(null);
  const [propertyData, setPropertyData] = useState(null);

  useEffect(() => {
    if (!propertyId) return;

    const fetchPropertyDefinition = async () => {
      try {
        const result = await apiService.get(
          `/api/v1/property-definition/${propertyId}/last-version/`
        );
        setPropertyData(result.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchPropertyDefinition();
  }, [propertyId]);

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
    setPropertyData(null);
  };

  const hasPredefinedValues = propertyData?.value_choices && propertyData?.value_choices.length > 0;

  return (
    <Modal
      title={formProps?.property_definition?.title ?? 'Editace hodnoty'}
      visible={visible}
      className="w-[600px]"
      header={[]}
      footer={[
        <ModalButton
          key={1}
          action={() => {
            setPropertyData(null);
            onClose();
          }}
        >
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={{
          value: formProps?.value,
          description: formProps?.description,
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          return (
            <div className="pb-4">
              <Form>
                <div>
                  <Label name="value" title="Hodnota" required />
                  {hasPredefinedValues ? (
                    <table className="table-auto w-full">
                      <thead className="text-left bg-color4 text-color2 text-sm font-semibold">
                        <tr className="w-full table table-fixed">
                          <th className="p-2 w-8"></th>
                          <th className="p-2">Hodnota</th>
                          <th>Popisek</th>
                        </tr>
                      </thead>
                      <tbody className="block text-sm max-h-[300px] overflow-y-auto">
                        {(propertyData?.value_choices || []).map((item, i) => (
                          <tr key={i} className="border-b w-full table table-fixed">
                            <td className="p-2 w-8">
                              <Radio
                                value={item.value}
                                checked={item.value === values.value}
                                onChange={() => setFieldValue('value', item.value)}
                              />
                            </td>
                            <td className="p-2">{item.value}</td>
                            <td className="p-2">{item.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <Field
                      name="value"
                      value={values.value || null}
                      placeholder="Hodnota"
                      component={InputField}
                    />
                  )}
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SolutionPropertyDefinitionModal;
