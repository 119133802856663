import React from 'react';
import classNames from 'classnames/bind';
import style from './modal.module.css';
import { Spin } from 'antd';

const cx = classNames.bind(style);
function ModalButton({ action, children, primary, type = 'button', disabled, loading }) {
  const style = cx({
    button: true,
    primary,
    disabled: disabled,
  });
  return (
    <button type={type} onClick={action} className={style} disabled={disabled}>
      {loading ? <Spin /> : children}
    </button>
  );
}

export default ModalButton;
