import React from 'react';
import { Form, Select, Tooltip } from 'antd';
const { Option } = Select;
const FormItem = Form.Item;

export const SelectField = ({ field, form: { touched, errors, submitCount }, ...props }) => {
  const errorMsg =
    (touched[field.name] && errors[field.name]) || (errors[field.name] && submitCount > 0)
      ? errors[field.name]
      : null;
  const renderItems = props.data
    ? props.data.map((item, i) => (
        <Option key={i} value={item.id}>
          <Tooltip title={item.description}>{item.title}</Tooltip>
        </Option>
      ))
    : [];

  return (
    <FormItem
      label={props.label}
      help={errorMsg}
      hasFeedback={!!errors[field.name]}
      validateStatus={errors[field.name] && (touched[field.name] || submitCount > 0) && 'error'}
    >
      <Select {...field} {...props}>
        {renderItems}
      </Select>
    </FormItem>
  );
};
