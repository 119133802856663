import React from 'react';
import { createPortal } from 'react-dom';
import style from './authModal.module.css';

const AuthModal = (props) => {
  const { title, children, visible } = props;

  if (!visible) return null;
  return createPortal(
    <div>
      <div className={style.page}>
        <div id="modal" className={style.modal}>
          <div id="header" className={style.header}>
            <span className={style.title}>{title || ''}</span>
            {/* {this.list(header, { menu: style.menu, menuItem: style.menuItem })} */}
          </div>
          <div id="content" className={style.content}>
            {children}
          </div>
          <div id="footer" className={style.footer}>
            {/* {this.list(footer, { menu: '', menuItem: style.footerItem })} */}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
};

export default AuthModal;
