import React, { useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import useFormRutine from '../../../../../shared/hooks/formHook';
import { InputField } from '../../../../../shared/InputField';
import Yup from '../../../../../utills/myYup';
import { SelectField } from '../../../../../shared/SelectField';

const dropdownFeed = [
  { title: 'Ano', id: true },
  { title: 'Ne', id: false },
];

const BuildingModelForm = ({
  formProps,
  submitOutside,
  submit,
  error,
  reset = false,
  resetConfirm,
}) => {
  const formRef = useRef();

  useFormRutine(formRef, submitOutside, reset, formProps, resetConfirm, error);

  const init = ({ title, is_active } = {}) => ({
    is_active,
    title,
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
  });

  return (
    <div>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(formProps?.id, values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          return (
            <Form labelCol={{ span: 5 }}>
              <Form.Item label="Název">
                <Field
                  name="title"
                  value={values.title}
                  placeholder="Název"
                  component={InputField}
                />
              </Form.Item>
              {formProps && (
                <Form.Item label="Aktivní">
                  <Field
                    name="is_active"
                    value={values.is_active}
                    placeholder=""
                    component={SelectField}
                    data={dropdownFeed}
                    onChange={(val) => {
                      setFieldValue('is_active', val);
                    }}
                  />
                </Form.Item>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BuildingModelForm;
