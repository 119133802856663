import React, { useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import useFormRutine from '../../../../shared/hooks/formHook';
import { InputField } from '../../../../shared/InputField';
import Yup from '../../../../utills/myYup';
import { SelectField } from '../../../../shared/SelectField';

const dropdownFeed = [
  { title: 'Ano', id: true },
  { title: 'Ne', id: false },
];

const PhaseForm = ({
  formProps,
  submitOutside,
  submit,
  error,
  types,
  models,
  reset = false,
  resetConfirm,
}) => {
  const formRef = useRef();

  useFormRutine(formRef, submitOutside, reset, formProps, resetConfirm, error);

  const init = () => ({
    is_active: formProps?.is_active || null,
    name_official: formProps?.name_official || null,
    phase_type: formProps?.phase_type?.id || null,
    model: formProps?.model?.id,
  });

  const validationSchema = Yup.object().shape({
    name_official: Yup.string().required('Povinné pole'),
    phase_type: Yup.string().required('Povinné pole'),
  });

  return (
    <div>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={init()}
        validateOnBlur={false}
        onSubmit={(values) => {
          submit(
            formProps?.id,
            values.is_active !== null ? values : { ...values, is_active: true }
          );
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;

          return (
            <Form labelCol={{ span: 5 }}>
              <Form.Item label="Oficialni nazev">
                <Field
                  name="name_official"
                  value={values.name_official}
                  placeholder="Oficiální název"
                  component={InputField}
                />
              </Form.Item>
              {formProps && (
                <Form.Item label="Aktivní">
                  <Field
                    name="is_active"
                    value={values.is_active}
                    placeholder=""
                    component={SelectField}
                    data={dropdownFeed}
                    onChange={(val) => {
                      setFieldValue('is_active', val);
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item label="Typ fáze">
                <Field
                  name="phase_type"
                  value={values.phase_type}
                  placeholder=""
                  component={SelectField}
                  data={types || []}
                  onChange={(val) => {
                    setFieldValue('phase_type', val);
                  }}
                />
              </Form.Item>
              <Form.Item label="Model">
                <Field
                  name="model"
                  value={values.model}
                  placeholder=""
                  component={SelectField}
                  data={models || []}
                  onChange={(val) => {
                    setFieldValue('model', val);
                  }}
                />
              </Form.Item>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PhaseForm;
