import React from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import Label from '../../../../Label';
import ModalButton from '../../../../../shared/modal/ModalButton';
import { AreaField } from '../../../../../shared/AreaField';
import { SelectField } from '../../../../../shared/SelectField';
import { useEffect } from 'react';
import { apiService } from '../../../../../utills/api';
import { useState } from 'react';

const BuildingObjectModal = ({ visible, title, formProps, onSubmit, onClose }) => {
  const [buildingObjectTypes, setBuildingObjectTypes] = useState([]);
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    description: Yup.string().required('Povinné pole'),
    building_object_type: Yup.string().required('Povinné pole'),
    number: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  useEffect(() => {
    const fetchBOTypes = async () => {
      try {
        const response = await apiService.get('api/v1/building-object/types/');

        setBuildingObjectTypes(response.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchBOTypes();
  }, []);

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          description: formProps?.description || null,
          number: formProps?.number || null,
          building_object_type: formProps?.building_object_type || null,
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, {
            ...values,
          });
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="description" title="Plný popis" required />
                  <Field
                    name="description"
                    value={values.description || null}
                    placeholder="Plný popis"
                    component={AreaField}
                  />
                </div>

                <div>
                  <Label name="number" title="Číslo" required />
                  <Field
                    name="number"
                    value={values.number || null}
                    placeholder="Číslo"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="building_object_type" title="Typ stavebního objektu" required />
                  <Field
                    name="building_object_type"
                    value={values.building_object_type || null}
                    placeholder="..."
                    data={(buildingObjectTypes || []).map((item) => ({
                      id: item.shortcut,
                      title: item.title,
                    }))}
                    onChange={(val) => {
                      setFieldValue('building_object_type', val);
                    }}
                    component={SelectField}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default BuildingObjectModal;
