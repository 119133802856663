import { CaretDownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setPagePermission } from '../../store/actions/auth';
import { setMenuId } from '../../store/actions/typemark';
import style from './topNavigation.module.css';

const renderMenu = (items, currentItem) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (!currentItem) return;
    dispatch(setMenuId(currentItem.id));
    dispatch(setPagePermission(currentItem.status));
  };

  return (
    <Menu>
      {items.map((ele, i) => (
        <Menu.Item key={i} style={{ width: '100%' }}>
          <NavLink
            to={`${ele.route_1}${ele.route_2}${ele.route_3}${ele.route_4}`}
            activeClassName={style.active}
            onClick={handleClick}
          >
            {ele.title}
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );
};

function TopNavigation(props) {
  const dispatch = useDispatch();
  const { contractId, modelId, phaseId } = useSelector((state) => state.contract);

  const handleClick = () => {
    // this.props.setIdOfRoute(this.props.currentItem.id);
    if (!props.currentItem) return;
    dispatch(setMenuId(props.currentItem.id));
    dispatch(setPagePermission(props.currentItem.status));
    // this.props.setPagePermission(this.props.currentItem.status);
  };

  const isDisabled = useMemo(
    () =>
      props.location.pathname.startsWith('/app/akce') &&
      !(contractId !== null && modelId !== null && phaseId !== null),
    [props.location, contractId, modelId, phaseId]
  );

  const manage = () => {
    const i = props.currentItem;
    return (
      props.items2 &&
      props.items2.filter(
        (item) => i.route_1 + i.route_2 + i.route_3 === item.route_1 + item.route_2 + item.route_3
      )
    );
  };
  const result = manage();

  //! this is bad approach, needs to be refactored
  const enabledPhase = props.route !== '/app/akce/sprava/faze/vykonove-faze';

  return (
    <li
      className={classNames('nav__item item-bordered', {
        'pointer-events-none bg-gray-200': isDisabled && enabledPhase,
      })}
    >
      <NavLink
        className="nav__link_top link c-gray scrolly group  overflow-hidden"
        disabled={isDisabled && enabledPhase}
        to={props.route}
        isActive={() => props.location.pathname.startsWith(props.routeForActive)}
        activeClassName="active"
        onClick={() => {
          if (!isDisabled) {
            handleClick();
          }
        }}
      >
        <SVG
          src={props.icon}
          className={classNames('fill-neutral-600 h-5 w-5 group-hover:fill-white', {
            'fill-white': props.location.pathname.startsWith(props.routeForActive),
          })}
        />

        <span className="text break-words w-full whitespace-pre-line">
          <Trans>{props.text}</Trans>
        </span>
      </NavLink>
      {result.length > 0 && (
        <span className="lvl4">
          <Dropdown
            className="level-4-dropp"
            overlay={renderMenu(result, props.currentItem)}
            trigger={['click']}
          >
            <span>
              <CaretDownOutlined />
            </span>
          </Dropdown>
        </span>
      )}
    </li>
  );
}
export default TopNavigation;
