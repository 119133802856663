import React from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.css';

let cx = classNames.bind(styles);

const BaseContainer = ({
  children,
  width = 300,
  fullHeight = false,
  margin = false,
  className,
}) => {
  var style = cx({
    container: true,
    fullHeight: fullHeight,
    noMargin: margin,
  });
  return (
    <div className={classNames(style, className)} style={{ width: width }}>
      {children}
    </div>
  );
};

export default BaseContainer;
