import { useDispatch, useSelector } from 'react-redux';

import { changeCurrentCompany } from '../../../store/actions/auth';
import { Spin } from 'antd';

function Profile({ employee, toggleOpen, history }) {
  const dispatch = useDispatch();

  const changeCompany = () => {
    dispatch(changeCurrentCompany(employee.id, employee.company.id));
    history.push('/');
  };

  return (
    <li onClick={changeCompany} className="profile-dialog-text item">
      <span className="text-email">{employee.role.title}</span>
      <span className="text-name">{employee.company.title}</span>
    </li>
  );
}

function Profiles(props) {
  const { loadingMenu } = useSelector((state) => state.menu);

  return (
    <ul className="!max-h-[550px] overflow-y-scroll">
      {loadingMenu && (
        <div className="flex justify-center items-center">
          <Spin />
        </div>
      )}
      {(props.employees || []).map((employee) => (
        <Profile key={employee.id} employee={employee} toggleOpen={props.toggleOpen} {...props} />
      ))}
    </ul>
  );
}

export default Profiles;
