import React from 'react';
import { useSelector } from 'react-redux';
import style from './classificationDetailList.module.css';

function Row({ title, value1, value2 }) {
  return (
    <li className={style.itemList}>
      <span className={style.itemListColumnLeft}>
        <span>{title}</span>
        {value1 && <span>{value1}</span>}
      </span>
      <span className={style.itemListColumnRight}>{value2}</span>
    </li>
  );
}

export default function ClassificationDetailList() {
  const { detail } = useSelector((state) => state.classification);

  if (!detail) {
    return null;
  }

  const getTripleColumnRows = Object.keys(detail).map((key) =>
    key.match(/u[0-9]/g) ? (
      <Row key={key} title={key} value1={detail[key].number} value2={detail[key].title} />
    ) : null
  );

  return (
    <ul className={style.list}>
      <Row key={11} title="Název" value2={detail.title} />
      {getTripleColumnRows}
      <Row key={12} title="Definuje komponenty?" value2={detail.is_def_component ? 'Ano' : 'Ne'} />
      <Row key={13} title="Definuje skladbu?" value2={detail.is_def_composition ? 'Ano' : 'Ne'} />
    </ul>
  );
}
