import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import Bar from '../../../elements/Bar';
import List from '../../../elements/List';

const detailData = ({ name_official, is_active, phase_type } = {}) => {
  return [
    { name: 'Typ výkonové fáze', value: phase_type?.title },
    { name: 'Aktivní', value: is_active ? 'Ano' : 'Ne' },
    { name: 'Oficiální název', value: name_official },
  ];
};

export default function PhaseDetailView({ data, makeEditable }) {
  const permission = useSelector((state) => state.auth.pagePermission);

  return (
    <div className="w-full">
      <Bar
        text={'Detail výkonové fáze'}
        content={permission === 2 && data ? [<EditOutlined key={1} onClick={makeEditable} />] : []}
      />
      <List data={detailData(data || {})} />
      <Bar text={'Související model'} content={[]} />
      {data && (
        <List
          data={[
            {
              name: 'Model',
              value: data?.model?.title,
            },
          ]}
        />
      )}
    </div>
  );
}
