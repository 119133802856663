import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { apiService } from '../utills/api';
import { useSelector } from 'react-redux';

const fetchData = async (
  queryKey,
  page,
  search,
  filter,
  contractId,
  modelId,
  menuId,
  classification
) => {
  try {
    const { data } = await apiService.get(`/api/v1/${queryKey}/left-panel/`, {
      params: {
        page: page,
        page_size: 30,
        search: search,
        is_active: [true],
        ...filter,
        contract: contractId,
        model: modelId,
        menu: menuId,
        classification: classification,
      },
    });
    return data;
  } catch (e) {
    //
  }
};

const useGetNavigation = (queryKey, search, filter = {}, classification) => {
  const { contractId, modelId } = useSelector((state) => state.contract);
  const { menuId } = useSelector((state) => state.typeMark);

  const { fetchNextPage, hasNextPage, isError, isFetching, isLoading, data } = useInfiniteQuery({
    queryKey: [queryKey, search, filter, menuId, contractId, modelId, classification],
    keepPreviousData: true,
    queryFn: ({ pageParam = 1 }) => {
      if (!pageParam) {
        return;
      }

      return fetchData(
        queryKey,
        pageParam,
        search,
        filter,
        contractId,
        modelId,
        menuId,
        classification
      );
    },
    getNextPageParam: (lastPage) => lastPage?.next,
    refetchOnWindowFocus: false,
  });

  const pages = useMemo(() => data?.pages.filter(Boolean) || [], [data?.pages]);
  const navigationItems = useMemo(() => pages.flatMap((page) => page.results) || [], [pages]);

  return useMemo(
    () => ({
      fetchNextPage,
      hasNextPage,
      isError,
      isFetching,
      isLoading,
      navigationItems,
      pages,
    }),
    [
      fetchNextPage,
      hasNextPage,
      isError,
      isFetching,
      isLoading,
      navigationItems,
      pages,
      search,
      filter,
      menuId,
    ]
  );
};

export { useGetNavigation };
