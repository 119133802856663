import React from 'react';
import { Button, Form } from 'antd';

import { Field, withFormik } from 'formik';
import * as yup from 'yup';
import { InputField } from '../../shared/InputField';
const FormItem = Form.Item;

class C extends React.Component {
  render() {
    const { values, handleSubmit } = this.props;
    return (
      <>
        <form onSubmit={handleSubmit} className="w-[350px]">
          <Field
            type="password"
            name="password"
            placeholder="Nové heslo"
            value={values.password}
            component={InputField}
          />

          <Field
            type="password"
            name="confirm"
            placeholder="Znovu heslo pro kontrolu"
            value={values.confirm}
            component={InputField}
          />

          <FormItem>
            <Button block htmlType="submit">
              potvrdit
            </Button>
          </FormItem>
        </form>
        <span className="error">{this.props.errorFromServer}</span>
      </>
    );
  }
}

const validationSchema = yup.object({
  password: yup.string().required('Povinné pole'),
  confirm: yup.string().oneOf([yup.ref('password'), null], 'Hesla se musí shodovat'),
});

const ResetPasswordConfirmForm = withFormik({
  validationSchema,

  mapPropsToValues: () => ({ password: '', confirm: '' }),
  handleSubmit: async (values, { props, setErrors }) => {
    await props.submit(values, setErrors);
  },
})(C);

export default ResetPasswordConfirmForm;
