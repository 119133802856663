const initialState = {
  menu: { menu1: [], menu2: [], menu3: [], menu4: [] },
  loadingMenu: false,
};

const updateMenu = (state, action) => {
  return { ...state, menu: action.menu, loadingMenu: false };
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_FETCHING_MENU':
      return {
        ...state,
        loadingMenu: true,
      };
    case 'UPDATE_MENU':
      return updateMenu(state, action);
    default:
      return state;
  }
};

export default menuReducer;
