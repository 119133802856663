import React, { useEffect, useState } from 'react';
import ModalButton from '../../../shared/modal/ModalButton';
import Modal from '../../../shared/modal/Modal';
import ManageContracts from './ManageContracts';
import {
  AlignLeftOutlined,
  BlockOutlined,
  CloudUploadOutlined,
  StarOutlined,
} from '@ant-design/icons';
import {
  getAllDataForManager,
  getDataManagerCompany,
  getDataManagerFavorite,
  getDataManagerShared,
  toFavorite,
} from '../../../services/contractPickerService';

const SERVICE = {
  ALL: 1,
  FAVORITE: 2,
  SHARED: 3,
  COMPANY: 4,
};

const ContractPickerModal = ({ visible, onClose }) => {
  const [submit, setSubmit] = useState(false);
  const [service, setService] = useState(SERVICE.ALL);

  const buttons = () => {
    let _buttons = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
      // <ModalButton primary key={2} action={() => setSubmit(true)}>
      //   Potvrdit
      // </ModalButton>,
    ];

    return _buttons;
  };

  const headerItems = () => {
    let _buttons = [
      <ModalButton key={1} primary={service === 1} action={() => setService(SERVICE.ALL)}>
        <AlignLeftOutlined />
      </ModalButton>,
      <ModalButton key={2} primary={service === 4} action={() => setService(SERVICE.COMPANY)}>
        <BlockOutlined />
      </ModalButton>,
      <ModalButton key={3} primary={service === 3} action={() => setService(SERVICE.SHARED)}>
        <CloudUploadOutlined />
      </ModalButton>,
      <ModalButton key={4} primary={service === 2} action={() => setService(SERVICE.FAVORITE)}>
        <StarOutlined />
      </ModalButton>,
    ];

    return _buttons;
  };

  const serviceApi = () => {
    switch (service) {
      case SERVICE.ALL:
        return <ManageContracts service={getAllDataForManager} />;
      case SERVICE.FAVORITE:
        return <ManageContracts service={getDataManagerFavorite} />;
      case SERVICE.SHARED:
        return <ManageContracts service={getDataManagerShared} />;
      case SERVICE.COMPANY:
        return <ManageContracts service={getDataManagerCompany} />;
      default:
        return <ManageContracts service={getAllDataForManager} />;
    }
  };

  const makeTitle = () => {
    switch (service) {
      case SERVICE.ALL:
        return 'Všechny akce';
      case SERVICE.FAVORITE:
        return 'Oblíbené akce';
      case SERVICE.SHARED:
        return 'Sdílené akce';
      case SERVICE.COMPANY:
        return 'Firemní akce';
      default:
        return 'Všechny akce';
    }
  };

  return (
    <Modal
      title={makeTitle()}
      visible={visible}
      header={headerItems()}
      onClose={onClose}
      footer={buttons()}
    >
      {serviceApi()}
    </Modal>
  );
};

export default ContractPickerModal;
