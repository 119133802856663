import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MainContainer from '../../../containers/MainContainer';
import LeftPanel from '../../../shared/components/LeftPanel';
import { apiService } from '../../../utills/api';
import UsersDetailView from './components/UsersDetailView';
import UsersModal from './components/modals/UsersModal';
import InvitePeopleModal from './components/modals/InvitePeopleModal';
import { NotificationManager } from 'react-notifications';

export default function UsersController({ history }) {
  const current = useSelector((state) => state.auth.current_company);
  const permission = useSelector((state) => state.auth.pagePermission);
  const [baseData, setBaseData] = useState({
    employee: [],
    roles: [],
    nextEmployee: null,
    used_licence_status: null,
    search: null,
    filter: {},
  });
  const [logs, setLogs] = useState([]);
  const [nextLogs, setNextLogs] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [active, setActive] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleInvitePeople, setVisibleInvitePeople] = useState(false);

  const [edit, setEdit] = useState(null);
  const [error, setError] = useState(null);

  const getRoles = async () => await apiService.get(`/api/v1/company/roles/`);

  const getEmployees = async (next, search, filter) => {
    return await apiService.get(`/api/v1/current-company/employees/`, {
      params: {
        page: next,
        search: search,
        ...filter,
      },
    });
  };

  const getLogs = async (id, next) =>
    await apiService.get(`/api/v1/employee/${id}/list_log/`, {
      params: {
        page: next,
        page_size: 30,
      },
    });

  const fetchData = async () => {
    if (current) {
      let [{ data: roles }, { data: employees }] = await axios.all([
        getRoles(),
        getEmployees(1, null, baseData.filter),
      ]);

      if (employees) {
        let { id } = getFirst(employees?.results);
        if (id) {
          let { data: activeUser } = await apiService.get(`/api/v1/employee/${id}/`);
          let { data: logs_response } = await getLogs(id, 1);
          setActive(id);
          setCurrentUser(activeUser);
          setLogs(logs_response?.results || []);
          setNextLogs(logs_response?.next);
          setBaseData({
            employee: employees?.results || [],
            nextEmployee: employees?.next,
            roles: roles || [],
            used_licence_status: employees?.used_licence_status,
          });
        } else {
          setCurrentUser(null);
        }
      }
    }
  };

  useEffect(() => {
    if (!current) {
      history.push('/');
    }
    fetchData();
  }, [current]);

  const makeActive = async (id) => {
    if (id) {
      setActive(id);
      let { data: result } = await apiService.get(`/api/v1/employee/${id}/`);
      let { data: logs_response } = await getLogs(id, 1);
      setLogs(logs_response?.results || []);
      setNextLogs(logs_response?.next);
      setCurrentUser(result);
    }
  };

  const makeEditable = () => {
    setEdit(true);
    setVisible(true);
  };

  const onClose = () => {
    setEdit(false);
    setVisible(false);
    setVisibleInvitePeople(false);
  };

  const submit = async (id, data) => {
    if (id) {
      try {
        let { data: result } = await apiService.patch(`/api/v1/employee/${id}/`, data);
        if (result) {
          setCurrentUser(result);
          setVisible(false);
        }
      } catch (e) {
        NotificationManager.error('Nastala chyba', e?.response?.message);
        setError(e);
      }
    }
  };

  const fetchMoreLogs = async () => {
    if (nextLogs !== null) {
      try {
        let { data: logs_response } = await getLogs(currentUser?.id, nextLogs);

        if (logs_response) {
          setLogs(logs.concat(logs_response?.results || []));
          setNextLogs(logs_response?.next);
        }
      } catch (e) {
        //   setError(e);
      }
    }
  };

  const fetchMoreEmployees = async () => {
    if (baseData.nextEmployee !== null) {
      try {
        let { data } = await getEmployees(baseData.nextEmployee, baseData.search);

        if (data) {
          setBaseData((baseData) => ({
            ...baseData,
            employee: baseData.employee.concat(data?.results || []),
            nextEmployee: data?.next,
          }));
        }
      } catch (e) {
        //   setError(e);
      }
    }
  };

  const onSearch = async (e) => {
    setBaseData({ ...baseData, search: e?.target?.value });
    try {
      let { data } = await getEmployees(1, e?.target?.value, baseData.filter);
      if (data) {
        setBaseData({
          ...baseData,
          employee: data?.results || [],
          nextEmployee: data?.next,
        });
      }
    } catch (e) {
      //
    }
  };

  const onFilter = async (filterValues) => {
    setBaseData({ ...baseData, filter: filterValues });
    try {
      let { data } = await getEmployees(1, baseData.search, filterValues);
      if (data) {
        setBaseData({
          ...baseData,
          employee: data?.results || [],
          nextEmployee: data?.next,
        });
      }
    } catch (e) {
      //
    }
  };

  const getFirst = (data) => (data?.length > 0 ? data[0] : {});

  const [actual, limit] = baseData.used_licence_status
    ? // eslint-disable-next-line no-unsafe-optional-chaining
      baseData.used_licence_status?.split(' / ')
    : [0, 0];

  return (
    <MainContainer>
      <LeftPanel
        data={baseData.employee}
        addItem={() => setVisibleInvitePeople(true)}
        title={`Lidé ve firmě: ${baseData.used_licence_status || ''}`}
        onSearch={onSearch}
        fetchMore={fetchMoreEmployees}
        hasMore={baseData.nextEmployee !== null ? true : false}
        onFilter={onFilter}
        permission={permission}
        filteredValues={{
          is_active: [
            { value: true, title: 'Aktivní' },
            { value: false, title: 'Neaktivní' },
          ],
          role: (baseData.roles || []).map((ele) => {
            ele.value = ele.id;
            return ele;
          }),
        }}
        disableAdd={Number(actual || 0) >= Number(limit || 0)}
        submit={() => {}}
        makeActive={makeActive}
        active={active}
      />
      <UsersDetailView
        data={currentUser}
        makeEditable={makeEditable}
        permission={permission}
        logs={logs}
        fetchMoreLogs={fetchMoreLogs}
        hasMoreLogs={nextLogs !== null ? true : false}
      />
      <UsersModal
        visible={visible}
        onClose={onClose}
        data={edit && currentUser}
        submitEditForm={submit}
        error={error}
        roles={baseData.roles}
      />
      <InvitePeopleModal
        visible={visibleInvitePeople}
        onClose={onClose}
        submitEditForm={() => {}}
        current={current}
        roles={baseData.roles}
        // error={error}
        // roles={baseData.roles}
      />
    </MainContainer>
  );
}
