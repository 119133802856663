import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    notType: function notType(_ref) {
      switch (_ref.type) {
        case 'number':
          return 'Povinné pole';
        case 'string':
          return 'Povinné pole';
        default:
          return 'Zadali jste špatný typ vstupních dat';
      }
    },
  },
});

export default Yup;
