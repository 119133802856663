import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import treeStyle from './newClassification.module.css';

const styles = {
  toggle: {
    width: '1em',
    height: '1em',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  contents: {
    willChange: 'transform, opacity, height',
    padding: '0px 0px 0px 15px',
  },
};
const cx = classNames.bind(treeStyle);
function Contents({ children, ...style }, open) {
  const newStyle = open
    ? { opacity: 1, height: 'auto', ...style }
    : { opacity: 0, height: 0, ...style };

  return <div style={{ ...newStyle, ...styles.contents }}>{children}</div>;
}

const usePrevious = (values) => {
  const ref = useRef();

  useEffect(() => {
    if (values) {
      ref.current = values.map((child) => child.key);
    }
  });
  return ref.current;
};

function NewClassificationTree(props) {
  const [state, setState] = useState({
    open: props.open || false,
    visible: props.visible || false,
  });

  const { has_children, children, content, style, onClick, onExpand, reference } = props;
  const prevChildren = usePrevious(children);

  const toggle = () => {
    !state.open && !props.children && onExpand();
    props.children && setState({ ...state, open: !state.open, visible: false });
  };

  useEffect(() => {
    if (
      props.children &&
      JSON.stringify(prevChildren) !== JSON.stringify(props.children.map((child) => child.key))
    ) {
      setState({ ...state, open: true, visible: false });
    } else {
      const newState = { ...state, ...props };
      setState(newState);
    }
  }, [props]);

  const renderReferenceBudge = () => {
    if (!reference) return null;

    return (
      <div className="h-5 w-5 text-[10px] bg-color7 text-white rounded-full flex items-center justify-center ml-1">
        {reference}
      </div>
    );
  };

  return (
    <div className={cx('truncate', treeStyle.main)} style={{ ...style }}>
      <div className={cx({ row: true, active: props.active })}>
        <span className={treeStyle.spanGrid}>
          {/* <Icon style={{ ...styles.toggle, opacity: 1 }} onClick={toggle} /> */}
          <span className={treeStyle.controls}>
            {children && state.open && <MinusSquareOutlined onClick={toggle} />}
            {has_children && !state.open && <PlusSquareOutlined onClick={toggle} />}
          </span>
          <span className={treeStyle.text} onClick={onClick}>
            {content}
          </span>
          {renderReferenceBudge()}
        </span>
      </div>
      {Contents(props, state.open)}
    </div>
  );
}

export default NewClassificationTree;
