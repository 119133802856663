import * as actionTypes from '../actions/actionTypes';

const initialState = {
  contractId: null,
  modelId: null,
  phaseId: null,
};

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_CONTRACT:
      return {
        contractId: action.data.contract,
        modelId: action.data.model,
        phaseId: action.data.phase,
      };
    case actionTypes.REMOVE_CONTRACT:
      return {
        contractId: null,
        modelId: null,
        phaseId: null,
      };
    case actionTypes.UPDATE_CONTRACT:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

export default contractReducer;
