import classNames from 'classnames';
import React from 'react';

const Label = ({ name, title, required }) => (
  <label
    className={classNames('text-[10px] uppercase text-black', {
      "after:content-['_*'] after:text-red-500": required,
    })}
    htmlFor={name}
  >
    {title}
  </label>
);

export default Label;
