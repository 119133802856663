import React, { useEffect, useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import ModalButton from '../../../../../shared/modal/ModalButton';
import { apiService } from '../../../../../utills/api';
import { useSelector } from 'react-redux';
import ActionList from '../../../../../elements/ActionList';

const TemplateModal = ({ visible, title, onSubmit, onClose, classificationId }) => {
  const [templates, setTemplates] = useState([]);
  const [selected, setSelected] = useState(null);
  const { modelId } = useSelector((state) => state.contract);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const res = await apiService.get('/api/v1/instance-type-template/left-panel/', {
          params: {
            model: modelId,
            classification: classificationId,
          },
        });

        setTemplates(res.data?.results);
      } catch (e) {
        //
      }
    };

    fetchTemplate();
  }, []);

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmit(selected)}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      {!templates?.length ? (
        <div className="flex justify-center items-center">Žádné položky k dispozici.</div>
      ) : (
        <ActionList data={templates ?? []} selected={selected} onSelect={setSelected} />
      )}
    </Modal>
  );
};

export default TemplateModal;
