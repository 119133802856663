import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import withDbItemsWrapper from '../../../utills/hoc/dbItemsWrapper';
import SolutionModal from './components/modals/SolutionModal';
import SolutionDetailView from './components/SolutionDetailView';

function Solutions({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) {
  const { detail } = useSelector((state) => state.solution);

  return (
    <div className="w-full min-w-0">
      {detail && <SolutionDetailView data={detail} onEdit={handleEdit} />}
      <SolutionModal
        title="Řešení"
        visible={openDialog}
        formProps={isEdit ? detail : null}
        classification={[]}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
}

export default withDbItemsWrapper(Solutions, 'solution', 'left-panel-solution/');
