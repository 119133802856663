import Search from 'antd/lib/input/Search';
import { useState } from 'react';
import PanelWithClassification from '../components/PanelWithClassification';
import ModalButton from '../modal/ModalButton';
import React from 'react';
import Modal from '../modal/Modal';
import { apiService } from '../../utills/api';
import { debounce } from 'lodash';
import SolutionGallery from '../../components/database/solution/components/SolutionGallery';

const fetchData = async (url, id) => {
  return (
    await apiService.get('/api/v1/' + url, {
      params: {
        classification: id,
        pagination: false,
      },
    })
  ).data;
};

const SolutionPickerModal = ({ visible, classificationId, onSubmit, onClose } = {}) => {
  const [activeItemId, setActiveItemId] = useState(null);
  const [selected, setSelected] = useState(null);
  const [items, setItems] = useState([]);

  const url = `left-panel-solution?classification=${classificationId}`;
  const pickerUrl = `solution/left-panel-picker/`;

  const debounceSearch = debounce((e) => {
    handleSearch(e);
  }, 500);

  const handleSearch = (e) => {
    if (!activeItemId) return;

    fetchData(`${pickerUrl}?search=${e.target.value}`, activeItemId)
      .then((data) => {
        setItems(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleMakeActive = async (id) => {
    setActiveItemId(id);

    try {
      const res = await fetchData(pickerUrl, id);
      setItems(res);
    } catch (e) {
      console.log(e);
    }
  };

  const reset = () => {
    setActiveItemId(null);
    setSelected(null);
    setItems([]);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(selected);
    reset();
  };

  return (
    <Modal
      title={'Vyberte položku'}
      visible={visible}
      footer={[
        <ModalButton key={1} action={handleClose}>
          Zavřít
        </ModalButton>,
        <ModalButton primary key={2} action={handleSubmit}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <div className="flex h-[465px] w-[1255px]">
        <div className="border-x border-neutral-200 mr-[1px]">
          <PanelWithClassification
            makeActive={handleMakeActive}
            active={activeItemId}
            onSearch={() => {}}
            addItem={() => {}}
            onFilter={() => {}}
            className="!h-[420px]"
            url={url}
          />
        </div>
        <div className="w-full min-w-[350px]">
          <Search placeholder="Vyhledat" onChange={debounceSearch} />
          <div className="h-[435px] overflow-auto">
            <SolutionGallery
              solutions={items}
              onSelect={(item) => setSelected(item)}
              selected={selected}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SolutionPickerModal;
