import React from 'react';
import { withFormik, Field } from 'formik';
import { InputField } from '../../shared/InputField';
import * as Yup from 'yup';
import * as Antd from 'antd';
import style from './modals/authModal.module.css';
import { apiService } from '../../utills/api';
import { NotificationManager } from 'react-notifications';
import history from '../../history';

const { Form: AntForm, Button } = Antd;

const ValidationSchema = Yup.object().shape({
  username: Yup.string()
    .matches(/^[a-z0-9]+$/, 'Uživatelské jméno může obsahovat pouze malá písmena a čísla.')
    .required('Uživatelské jméno je povinné pole.'),
  email: Yup.string().email('Zadejte validní e-mail!').required('Povinné pole'),
  password: Yup.string().required('Povinné pole'),
  password2: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Hesla se musí shodovat!')
    .required('Povinné pole'),
  first_name: Yup.string().required('Povinné pole'),
  last_name: Yup.string().required('Povinné pole'),
});

class C extends React.Component {
  state = {
    value: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      for (var key in this.props.error) {
        this.props.setErrors(this.props.error);
      }
    }
  }

  onFinish = (values) => {
    console.log('Success:', values);
  };

  onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  render() {
    const { handleSubmit, values, onClose } = this.props;

    return (
      <div className="">
        <AntForm onFinish={handleSubmit} autoComplete="off">
          <AntForm.Item className={style.subTitle}>
            <span>Údaje pro přihlášení</span>
          </AntForm.Item>
          <Field
            name="username"
            value={values.username}
            placeholder="Uživatelské jméno"
            component={InputField}
          />

          <Field name="email" value={values.email} placeholder="E-mail" component={InputField} />

          <Field
            name="password"
            type="password"
            value={values.password}
            placeholder="Heslo"
            component={InputField}
          />

          <Field
            type="password"
            name="password2"
            value={values.password2}
            placeholder="Potvrzovací heslo"
            component={InputField}
          />
          <AntForm.Item className={style.subTitle}>
            <span>Údaje o uživateli</span>
          </AntForm.Item>
          <Field
            name="degree_before"
            value={values.degree_before}
            placeholder="Titul před jménem"
            component={InputField}
          />
          <Field
            name="first_name"
            value={values.first_name}
            placeholder="Křestní jméno"
            component={InputField}
          />
          <Field
            name="last_name"
            value={values.last_name}
            placeholder="Prijmeni"
            component={InputField}
          />
          <Field
            name="degree_after"
            value={values.degree_after}
            placeholder="Titul za jménem"
            component={InputField}
          />

          <AntForm.Item style={{ float: 'right' }}>
            <Button style={{ border: 'none' }} onClick={() => history.go('/login')}>
              Zavřít
            </Button>
            <Button
              style={{ border: 'none', backgroundColor: '#4da8da' }}
              htmlType="submit"
              type="primary"
            >
              Založit nový účet
            </Button>
          </AntForm.Item>
        </AntForm>
      </div>
    );
  }
}

const RegistrationForm = withFormik({
  enableReinitialize: true,
  validationSchema: ValidationSchema,
  validateOnBlur: false,
  mapPropsToValues: ({ username, password, confirm }) => ({
    username,
    password,
    confirm,
  }),
  handleSubmit: async (values, { setErrors }) => {
    try {
      delete apiService.defaults.headers.common.Authorization;
      await apiService.post('/my-rest-auth/register/', values);

      history.push('/registration');
      NotificationManager.success('success', 'Verifikační e-mail odeslán.');
    } catch (e) {
      setErrors(e.response ? e.response.data : '');
    }
  },
})(C);

export default RegistrationForm;
