import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import withLeftPanelWrapper from '../../../utills/hoc/leftPanelWithDetailWrapper';
import BuildingModelDetailView from './components/BuildingModelDetailView';
import BuildingModelModal from './components/modals/BuildingModelModal';

const BuildingModel = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.model);

  return (
    <div className="w-full">
      {openDialog && (
        <Suspense fallback={<div>Loading...</div>}>
          <BuildingModelModal
            title={'Přidat model'}
            visible={openDialog}
            formProps={isEdit ? detail : undefined}
            onClose={handleClose}
            submitForm={handleSubmit}
          />
        </Suspense>
      )}
      <BuildingModelDetailView data={detail} makeEditable={handleEdit} />
    </div>
  );
};
export default withLeftPanelWrapper(BuildingModel, 'model');
