import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import BaseContainer from '../../../containers/BaseContainer';
import Bar from '../../../elements/Bar';
import List from '../../../elements/List';

const detailData = ({ title, hex, color_code }) => {
  return [
    { name: 'Název', value: title },
    { name: 'Hex', value: hex },
    { name: 'Kód barvy', value: color_code },
  ];
};

export default function ColorsDetail({ data, makeEditable }) {
  const permission = useSelector((state) => state.auth.pagePermission);

  return (
    <div>
      <Bar
        text={'Detail barvy'}
        content={
          permission === 2 && data
            ? [<EditOutlined key={1} onClick={() => makeEditable(data.id)} />]
            : []
        }
      />
      {data?.model_color && <List data={detailData(data.model_color)} />}
    </div>
  );
}
