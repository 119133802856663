import Search from 'antd/lib/input/Search';
import { useState } from 'react';
import PanelWithClassification from '../components/PanelWithClassification';
import ModalButton from '../modal/ModalButton';
import React from 'react';
import Modal from '../modal/Modal';
import { apiService } from '../../utills/api';
import ActionList from '../../elements/ActionList';
import { debounce } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import Bar from '../../elements/Bar';

const fetchData = async (url, id) => {
  return (
    await apiService.get('/api/v1/' + url, {
      params: {
        classification: id,
        pagination: false,
      },
    })
  ).data;
};

const PickValueWithClassificationModal = ({
  visible,
  onSubmit,
  onClose,
  url,
  pickerUrl,
  showDetail = false,
} = {}) => {
  const [activeItemId, setActiveItemId] = useState(null);
  const [selected, setSelected] = useState(null);
  const [items, setItems] = useState([]);

  const debounceSearch = debounce((e) => {
    handleSearch(e);
  }, 500);

  const handleSearch = (e) => {
    if (!activeItemId) return;
    const _url = pickerUrl ? pickerUrl : url;

    fetchData(`${_url}?search=${e.target.value}`, activeItemId)
      .then((data) => {
        if (data?.results) {
          setItems(data.results);
        } else {
          setItems(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleMakeActive = async (id) => {
    setActiveItemId(id);

    const _url = pickerUrl ? pickerUrl : url;

    try {
      const res = await fetchData(_url, id);
      if (res?.results) {
        setItems(res.results);
      } else {
        setItems(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const reset = () => {
    setActiveItemId(null);
    setSelected(null);
    setItems([]);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(selected);
    reset();
  };

  return (
    <Modal
      title={'Vyberte položku'}
      className="!min-w-[400px]"
      visible={visible}
      footer={[
        <ModalButton key={1} action={handleClose}>
          Zavřít
        </ModalButton>,
        <ModalButton primary key={2} action={handleSubmit}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <div className="flex h-[350px]">
        <div className="border-x border-neutral-200 mr-[1px]">
          <PanelWithClassification
            makeActive={handleMakeActive}
            active={activeItemId}
            onSearch={() => {}}
            addItem={() => {}}
            onFilter={() => {}}
            className="!h-[300px]"
            url={url}
          />
        </div>
        <div className="w-[300px]">
          <Search placeholder="Vyhledat" onChange={debounceSearch} />
          <ActionList
            height={'320px'}
            data={items}
            onSelect={(item) => setSelected(item)}
            selected={selected}
          />
        </div>

        {showDetail && (
          <div className="w-[300px] border-l">
            <Bar text="Detail" />
            <p>{selected?.description}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PickValueWithClassificationModal;
