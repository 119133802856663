import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './navigationLeftItem.module.css';
import SVG from 'react-inlinesvg';

class NavigationLeftItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { condition: false, route: this.props.route };
  }

  render() {
    return (
      <li className={style.navItem}>
        <NavLink className={style.navLink} to={this.props.route} activeClassName="active">
          <SVG src={this.props.icon} className="w-5 h-5 fill-neutral-200" />

          <span className="navi_span">{this.props.text}</span>
        </NavLink>
      </li>
    );
  }
}

export default NavigationLeftItem;
