import React from 'react';
import ResetPasswordEmailForm from './ResetPasswordEmailForm';
import { NotificationManager } from 'react-notifications';
import { apiService } from '../../utills/api';

const ResetPassword = () => {
  const submitEmail = (value) => {
    delete apiService.defaults.headers.common.Authorization;

    apiService
      .post('/my-rest-auth/password-reset/', value)
      .then(() => {
        NotificationManager.success('Odkaz pro rest hesla odeslán.', 'zkontrolujte si e-mail.');
      })
      .catch((error) => {
        NotificationManager.error('Chyba', error?.response?.message);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen flex-col gap-2">
      <h1 className="font-bold text-xl">Reset hesla</h1>

      <ResetPasswordEmailForm submit={submitEmail} />
    </div>
  );
};

export default ResetPassword;
