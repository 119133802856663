import * as actionTypes from './actionTypes';

export const setContract = (contract) => {
  return {
    type: actionTypes.ADD_CONTRACT,
    data: contract,
  };
};

export const clearContract = () => {
  return {
    type: actionTypes.REMOVE_CONTRACT,
  };
};
