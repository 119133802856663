const initialState = {
  cultures: [],
  active_culture: null,
};

const cultureReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CULTURE_SUCCESS':
      return {
        ...state,
        cultures: action.data,
      };
    // case "CLEAR_UNITS":
    //   return { ...state, units: [], detail: null };
    case 'SET_ACTIVE_CULTURE':
      return {
        ...state,
        active_culture: action.data,
      };
    case 'CLEAR_ACTIVE_CULTURE':
      return {
        ...state,
        active_culture: null,
      };
    default:
      return state;
  }
};

export default cultureReducer;
