import { useEffect } from 'react';

function useFormRutine(formRef, submitOutside, reset, formProps, resetConfirm, error) {
  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed && submitOutside) {
      formRef.current.submitForm();
    }

    return () => (isSubscribed = false);
  }, [submitOutside]);

  useEffect(() => {
    if (reset === true) {
      formRef.current.setValues(formProps);
      resetConfirm();
    }
  }, [reset]);

  useEffect(() => {
    formRef.current.setErrors(error?.response?.data || '');
  }, [error]);
}

export default useFormRutine;
