import { push } from 'react-router-redux';
import { NotificationManager } from 'react-notifications';
import * as actionTypes from './actionTypes';
import { apiService, updateToken } from '../../utills/api';
import history from '../../history';

export const profileStart = () => ({
  type: actionTypes.START_LOADING_USER_PROFILE,
});

export const authStart = () => ({
  type: actionTypes.AUTH_START,
});

export const profileSuccess = (data) => ({
  type: actionTypes.USER_PROFILE_LOADED,
  data,
});

export const authSuccess = (data) => ({
  type: actionTypes.AUTH_SUCCESS,
  data,
});

export const refreshConnectionSuccess = (refresh_token) => ({
  type: actionTypes.REFRESH_CONNECTION_SUCCESS,
  refresh_token,
});

export const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  error,
});

export const logout = () => {
  localStorage.clear();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => (dispatch) => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime * 1000);
};

export const loadProfile = () => async (dispatch) => {
  dispatch(profileStart());

  try {
    const { data } = await apiService.get(`/api/v1/user/employee-profiles/`);

    dispatch(profileSuccess(data));

    const currentEmployeeId = data?.current_employee?.id;
    const employee = currentEmployeeId ? `?employee=${currentEmployeeId}` : '';
    const { data: menu } = await apiService.get(`/api/v1/user/menu-for-employee/${employee}`);
    if (menu) {
      dispatch({ type: 'UPDATE_MENU', menu });
    }
  } catch (err) {
    console.log(err);
  }
};

export const changeCurrentCompany = (id, company) => async (dispatch) => {
  dispatch({ type: 'START_FETCHING_MENU' });
  const menu = (
    await apiService.get(`/api/v1/user/menu-for-employee/`, {
      params: {
        employee: id,
      },
    })
  ).data;

  if (menu) {
    dispatch(currentCompanyChanged(id, company));
    dispatch({ type: 'RESET_COMPANY' });
    dispatch({ type: 'UPDATE_MENU', menu });
  }
};

export const currentCompanyChanged = (id, company) => ({
  type: actionTypes.CHANGE_CURRENT_COMPANY,
  id,
  company,
});

export const authLogin = (username, password, intended) => async (dispatch) => {
  dispatch(authStart());

  try {
    delete apiService.defaults.headers.common.Authorization;
    const authData = (
      await apiService.post('/my-rest-auth/login/', {
        email: username,
        password,
      })
    ).data;

    dispatch(authSuccess(authData));
    updateToken();
    dispatch(loadProfile());

    history.push(intended || '/app/akce');
  } catch (err) {
    NotificationManager.error('Chyba', 'Špatné heslo nebo uživatelské jméno');
    dispatch(authFail(err));
  }
};

export const refreshConnection = (access) => ({
  type: actionTypes.REFRESH_CONNECTION_SUCCESS,
  access,
});

export const register = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.REGISTRATION_START });
  delete apiService.defaults.headers.common.Authorization;

  try {
    const response = await apiService.post('/my-rest-auth/register/', data);

    dispatch({
      type: actionTypes.REGISTRATION_SUCC,
      data: response.data,
    });
  } catch (e) {
    dispatch({
      type: actionTypes.REGISTRATION_FAIL,
      data: e.response ? e.response.data : '',
    });
  }
};

export const confirmRegistration = (token) => async (dispatch) => {
  delete apiService.defaults.headers.common.Authorization;
  try {
    await apiService.post(`/my-rest-auth/register/email-verify/`, {
      token,
    });

    dispatch({
      type: actionTypes.REGISTRATION_CONFIRM,
      data: null,
    });

    dispatch(push('/'));
  } catch (e) {
    NotificationManager.error('Chyba', e?.response?.data?.error);
    dispatch({
      type: actionTypes.REGISTRATION_FAIL,
      data: e.response ? e.response.data : '',
    });
  }
};

export const authCheckState = () => async (dispatch, getState) => {
  const token = getState().auth.refresh_token;
  //   dispatch(logout());

  // }   //
  if (!token) {
    // dispatch(logout());
  }

  try {
    await apiService.post(`/my-rest-auth/token/verify/`, {
      token,
    });
  } catch (e) {
    //
  }
};

export const setPagePermission = (permission) => (dispatch) => {
  dispatch({ type: actionTypes.SET_PAGE_PERMISSION, data: permission });
};
