import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React from 'react';
import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';

function TeamsDetailView({ data, makeEditable, addEmployee, removeEmployee, permission }) {
  if (!data) {
    return null;
  }

  const prepareDataDetail = () => {
    if (!data) return [];
    let { shortcut, title, leader_name } = data;
    return [
      { name: 'Označení', value: shortcut },
      { name: 'Název', value: title },
      { name: 'Vedoucí', value: leader_name },
    ];
  };

  const prepareDataMembers = () => {
    if (!data) return [];
    let { members_list } = data;
    return (members_list || []).map((ele) => ({
      name: ele?.employee_full_name,
      value: null,
      id: ele?.employee,
    }));
  };

  const deleteButton = (id) => (
    <Popconfirm
      placement="left"
      title="Opravdu chcete odstranit zaměstnance?"
      onConfirm={() => removeEmployee(id)}
      okText="Ano"
      cancelText="Ne"
      icon={null}
    >
      <MinusOutlined key={1} />
    </Popconfirm>
  );
  return (
    <div>
      <BaseContainer fullHeight={true} width={'calc(100% - 420px)'} margin>
        <Bar
          text={'Detail týmu'}
          content={permission === 2 ? [<EditOutlined key={1} onClick={makeEditable} />] : []}
        />
        <List data={prepareDataDetail()} />
        <Bar
          text={'Osoby v týmu'}
          content={permission === 2 ? [<PlusOutlined key={1} onClick={addEmployee} />] : []}
        />
        <List data={prepareDataMembers()} actions={[deleteButton]} />
      </BaseContainer>
    </div>
  );
}

export default TeamsDetailView;
