import React from 'react';

const MinusSquareO = (props) => (
  <svg {...props} viewBox="64 -65 897 897">
    <g>
      <path
        d="M888 760v0v0v-753v0h-752v0v753v0h752zM888 832h-752q-30 0 -51 -21t-21 -51v-753q0 -29 21 -50.5t51 -21.5h753q29 0 50.5 21.5t21.5 50.5v753q0 30 -21.5 51t-51.5 21v0zM732 347h-442q-14 0 -25 10.5t-11 25.5v0q0 15 11 25.5t25 10.5h442q14 0 25 -10.5t11 -25.5v0
  q0 -15 -11 -25.5t-25 -10.5z"
      />
    </g>
  </svg>
);

const PlusSquareO = (props) => (
  <svg {...props} viewBox="64 -65 897 897">
    <g>
      <path
        d="M888 760v0v0v-753v0h-752v0v753v0h752zM888 832h-752q-30 0 -51 -21t-21 -51v-753q0 -29 21 -50.5t51 -21.5h753q29 0 50.5 21.5t21.5 50.5v753q0 30 -21.5 51t-51.5 21v0zM732 420h-184v183q0 15 -10.5 25.5t-25.5 10.5v0q-14 0 -25 -10.5t-11 -25.5v-183h-184
  q-15 0 -25.5 -11t-10.5 -25v0q0 -15 10.5 -25.5t25.5 -10.5h184v-183q0 -15 11 -25.5t25 -10.5v0q15 0 25.5 10.5t10.5 25.5v183h184q15 0 25.5 10.5t10.5 25.5v0q0 14 -10.5 25t-25.5 11z"
      />
    </g>
  </svg>
);

const EyeO = (props) => (
  <svg {...props} viewBox="61 51 902 666">
    <g>
      <path
        d="M963 384q0 14 -21 62q-26 65 -61 109q-57 71 -139 112q-99 50 -230 50t-231 -50q-80 -41 -138 -112q-34 -43 -61 -109q-21 -48 -21 -62v0v0v0v0q0 -14 21 -62q27 -66 61 -109q57 -71 139 -112q100 -50 230 -50t230 50q81 41 139 112q35 44 62 109q20 48 20 62v0v0v0v0z
  M889 384q-25 -77 -64 -126h-1q-46 -59 -114 -93q-85 -42 -198.5 -42t-198.5 42q-67 34 -114 93q-40 49 -65 126q25 77 65 126q47 59 114 93q85 43 199 43t198 -43q67 -33 114 -93q40 -49 65 -126zM512 558q-72 0 -122.5 -50.5t-50.5 -122.5t50.5 -122.5t122.5 -50.5
  t122.5 50.5t50.5 122.5t-50.5 122.5t-122.5 50.5zM614 385q0 -42 -30 -72t-72 -30t-72 30t-30 72t30 72t72 30t72 -30t30 -72z"
      />
    </g>
  </svg>
);

const CloseSquareO = (props) => (
  <svg
    {...props}
    viewBox="0 0 1024 1024"
    focusable="false"
    data-icon="caret-right"
    width="1em"
    height="1em"
    fill="black"
    aria-hidden="true"
  >
    <path d="M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z"></path>
  </svg>
  //     <svg {...props} viewBox="64 -65 897 897">
  //         <g>
  //             <path
  //                 d="M717.5 589.5q-10.5 10.5 -25.5 10.5t-26 -10l-154 -155l-154 155q-11 10 -26 10t-25.5 -10.5t-10.5 -25.5t11 -25l154 -155l-154 -155q-11 -10 -11 -25t10.5 -25.5t25.5 -10.5t26 10l154 155l154 -155q11 -10 26 -10t25.5 10.5t10.5 25t-11 25.5l-154 155l154 155
  //   q11 10 11 25t-10.5 25.5zM888 760v0v0v-753v0h-752v0v753v0h752zM888 832h-752q-30 0 -51 -21t-21 -51v-753q0 -29 21 -50.5t51 -21.5h753q29 0 50.5 21.5t21.5 50.5v753q0 30 -21.5 51t-51.5 21v0z"
  //             />
  //         </g>
  //     </svg>
);

const dxf = () => (
  <svg
    className="umbrella"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    aria-labelledby="title"
  >
    <title id="title">Umbrella Icon</title>
    <path d="M27 14h5c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2v0zM27 14c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2v0 14c0 1.112-0.895 2-2 2-1.112 0-2-0.896-2-2.001v-1.494c0-0.291 0.224-0.505 0.5-0.505 0.268 0 0.5 0.226 0.5 0.505v1.505c0 0.547 0.444 0.991 1 0.991 0.552 0 1-0.451 1-0.991v-14.009c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2c0-1.105-1.119-2-2.5-2s-2.5 0.895-2.5 2c0-5.415 6.671-9.825 15-9.995v-1.506c0-0.283 0.224-0.499 0.5-0.499 0.268 0 0.5 0.224 0.5 0.499v1.506c8.329 0.17 15 4.58 15 9.995h-5z" />
  </svg>
);

const dwg = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="1em"
    height="1em"
    viewBox="0 0 318.188 318.188"
    // style="enable-background:new 0 0 318.188 318.188;"
  >
    <g>
      <g>
        <g>
          <path
            d="M95.683,233.392H79.077v47.979H94.6c7.985,0,14.183-2.166,18.591-6.498c4.408-4.331,6.613-10.501,6.613-18.509
               c0-7.438-2.096-13.127-6.285-17.065C109.329,235.361,103.384,233.392,95.683,233.392z M103.591,267.309
               c-1.837,2.287-4.725,3.43-8.663,3.43H92.04v-26.877h3.773c3.545,0,6.187,1.061,7.926,3.183c1.739,2.123,2.609,5.382,2.609,9.78
               C106.349,261.528,105.429,265.023,103.591,267.309z"
          />
          <path
            d="M219.667,282.027c6.738,0,12.919-0.962,18.542-2.888v-25.86h-20.708v10.009h8.565v7.515
               c-1.619,0.372-3.392,0.558-5.316,0.558c-3.523,0-6.219-1.176-8.09-3.528c-1.87-2.352-2.806-5.771-2.806-10.256
               c0-4.572,1.033-8.089,3.102-10.551c2.067-2.461,4.993-3.692,8.778-3.692c4.135,0,7.931,0.854,11.388,2.56l4.102-10.174
               c-1.991-0.874-4.327-1.597-7.007-2.166c-2.681-0.568-5.53-0.853-8.549-0.853c-7.964,0-14.156,2.155-18.575,6.465
               c-4.419,4.311-6.629,10.338-6.629,18.083c0,7.942,2.018,14.058,6.055,18.345C206.557,279.883,212.271,282.027,219.667,282.027z"
          />
          <path
            d="M283.149,52.722L232.625,2.197C231.218,0.79,229.311,0,227.321,0H40.342c-4.143,0-7.5,3.358-7.5,7.5v303.188
               c0,4.142,3.357,7.5,7.5,7.5h237.504c4.143,0,7.5-3.358,7.5-7.5V58.025C285.346,56.036,284.556,54.129,283.149,52.722z
                M234.821,25.606l24.918,24.919h-24.918V25.606z M227.321,65.526h43.024v34.71h-118.49V88.927c0-4.142-3.357-7.5-7.5-7.5h-11.309
               V40.263h86.775v17.763C219.821,62.168,223.178,65.526,227.321,65.526z M136.855,96.427v22.617h-22.617V96.427H136.855z
                M47.842,15h171.98v10.263H47.842V15z M47.842,40.263h70.204v41.165h-11.309c-4.142,0-7.5,3.358-7.5,7.5v11.309H47.842V40.263z
                M270.346,303.188H47.842V115.236h51.395v11.308c0,4.142,3.358,7.5,7.5,7.5h11.309v65.296h15v-65.296h11.309
               c4.143,0,7.5-3.358,7.5-7.5v-11.308h118.491V303.188z"
          />
          <path
            d="M145.909,261.057c-0.732,3.829-1.242,6.859-1.525,9.09c-0.438-3.15-1.247-7.384-2.429-12.7l-5.218-24.056h-12.504
               l11.881,47.979h15.324c2.801-12.58,4.393-19.756,4.775-21.528c0.383-1.772,0.771-3.713,1.166-5.825
               c0.393-2.111,0.678-3.877,0.854-5.3c0.217,1.751,0.563,3.927,1.033,6.531c0.471,2.603,0.814,4.343,1.033,5.218l4.596,20.905
               h15.324l11.814-47.979h-12.502l-5.318,24.121c-1.268,5.995-2.045,10.206-2.33,12.635c-0.24-1.881-0.721-4.726-1.443-8.533
               c-0.721-3.807-1.434-7.165-2.133-10.075l-4.102-18.148h-12.012l-4.234,18.148C147.325,254.056,146.643,257.228,145.909,261.057z"
          />
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

const Pdf = (props) => (
  <svg {...props} viewBox="64 -65 897 897">
    <g>
      <path
        d="M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39
		v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074
		c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978
		C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049
		C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z
		 M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.609c0.135,0,0.264-0.025,0.39-0.058
		c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"
      />
      <path
        d="M22.042,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641v-3.637h1.217
		c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388
		c0-0.483-0.103-0.918-0.308-1.306S22.375,45.018,22.042,44.744z M21.42,48.073c-0.101,0.278-0.232,0.494-0.396,0.649
		c-0.164,0.155-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198
		c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567
		C21.57,47.451,21.52,47.795,21.42,48.073z"
      />
      <path
        d="M31.954,45.4c-0.424-0.446-0.957-0.805-1.6-1.073s-1.388-0.403-2.235-0.403h-3.035V54h3.814
		c0.127,0,0.323-0.016,0.588-0.048c0.264-0.032,0.556-0.104,0.875-0.219c0.319-0.114,0.649-0.285,0.991-0.513
		s0.649-0.54,0.923-0.937s0.499-0.889,0.677-1.477s0.267-1.297,0.267-2.126c0-0.602-0.105-1.188-0.314-1.757
		C32.694,46.355,32.378,45.847,31.954,45.4z M30.758,51.73c-0.492,0.711-1.294,1.066-2.406,1.066h-1.627v-7.629h0.957
		c0.784,0,1.422,0.103,1.914,0.308s0.882,0.474,1.169,0.807s0.48,0.704,0.581,1.114c0.1,0.41,0.15,0.825,0.15,1.244
		C31.496,49.989,31.25,51.02,30.758,51.73z"
      />
      <polygon
        points="35.598,54 37.266,54 37.266,49.461 41.477,49.461 41.477,48.34 37.266,48.34 37.266,45.168 41.9,45.168
		41.9,43.924 35.598,43.924 	"
      />
      <path
        d="M38.428,22.961c-0.919,0-2.047,0.12-3.358,0.358c-1.83-1.942-3.74-4.778-5.088-7.562c1.337-5.629,0.668-6.426,0.373-6.802
		c-0.314-0.4-0.757-1.049-1.261-1.049c-0.211,0-0.787,0.096-1.016,0.172c-0.576,0.192-0.886,0.636-1.134,1.215
		c-0.707,1.653,0.263,4.471,1.261,6.643c-0.853,3.393-2.284,7.454-3.788,10.75c-3.79,1.736-5.803,3.441-5.985,5.068
		c-0.066,0.592,0.074,1.461,1.115,2.242c0.285,0.213,0.619,0.326,0.967,0.326h0c0.875,0,1.759-0.67,2.782-2.107
		c0.746-1.048,1.547-2.477,2.383-4.251c2.678-1.171,5.991-2.229,8.828-2.822c1.58,1.517,2.995,2.285,4.211,2.285
		c0.896,0,1.664-0.412,2.22-1.191c0.579-0.811,0.711-1.537,0.39-2.16C40.943,23.327,39.994,22.961,38.428,22.961z M20.536,32.634
		c-0.468-0.359-0.441-0.601-0.431-0.692c0.062-0.556,0.933-1.543,3.07-2.744C21.555,32.19,20.685,32.587,20.536,32.634z
		 M28.736,9.712c0.043-0.014,1.045,1.101,0.096,3.216C27.406,11.469,28.638,9.745,28.736,9.712z M26.669,25.738
		c1.015-2.419,1.959-5.09,2.674-7.564c1.123,2.018,2.472,3.976,3.822,5.544C31.031,24.219,28.759,24.926,26.669,25.738z
		 M39.57,25.259C39.262,25.69,38.594,25.7,38.36,25.7c-0.533,0-0.732-0.317-1.547-0.944c0.672-0.086,1.306-0.108,1.811-0.108
		c0.889,0,1.052,0.131,1.175,0.197C39.777,24.916,39.719,25.05,39.57,25.259z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export { PlusSquareO, MinusSquareO, CloseSquareO, EyeO, Pdf, dwg, dxf };
