import { useSelector } from 'react-redux';

export const usePermission = () => {
  const permission = useSelector((state) => state.auth.pagePermission);

  const isEditable = !(permission !== 2);

  return {
    isEditable,
  };
};
