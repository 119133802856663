import { EditOutlined } from '@ant-design/icons';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';
import { usePermission } from '../../../../hookd/usePerrmission';
/* eslint-disable */

function PropertiesView({ data, onEdit }) {
  const { isEditable } = usePermission();
  const prepareData = () => {
    if (!data) return [];
    return [
      { name: 'Název', value: data.title },
      { name: 'Zkratka', value: data.shortcut },
      { name: 'Popis', value: data.description },
      { name: 'Jednotka', value: data.unit?.title },
    ];
  };

  return (
    <div className="border-l-2 border-neutral-200 h-full">
      <Bar text="Detail" content={isEditable ? [<EditOutlined key={1} onClick={onEdit} />] : []} />
      <List data={prepareData()} />
      <div className="mb-[1px]">
        <Bar text="Biblio" />
        <List
          data={(data?.biblios || []).map((biblio) => ({
            name: biblio.title,
          }))}
        />
      </div>
      <div className="mb-[1px]">
        <Bar text="Nabývá hodnot" />
        <List
          data={(data.value_choices || []).map((choice) => ({
            name: choice.value,
            value: choice.description,
          }))}
        />
      </div>
    </div>
  );
}

export default PropertiesView;
