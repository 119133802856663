/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { NotificationContainer } from 'react-notifications';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import CustomLayout from './layout';

// BrowserRouter   HashRouter

// import Login from './components/login/Login';
import './App.css';
import ResetPassword from './components/resetPassword/ResetPassword';
import ResetPasswordConfirm from './components/resetPassword/ResetPasswordConfirm';
import * as actions from './store/actions/auth';
import './style.css';

import Company from './components/manage/company/Company';

import 'react-notifications/lib/notifications.css';

import Dashboard from './components/dashboard/dashboard';
import Biblio from './components/database/biblio/Biblio';
import Classification from './components/database/classification/Classification';
import ProfessionBond from './components/database/professionBond/ProfessionBond';
import Properties from './components/database/properties/Properties';
import PropertySet from './components/database/propertySet/PropertySet';
import Solution from './components/database/solution/Solution';
import TechnicalReportItem from './components/database/technicalReportItem/TechnicalReportItem';
import TechnicalReportItemSet from './components/database/technicalReportItemSet/TechnicalReportItemSet';
import TypeMarkTemplate from './components/database/templates/TypemarkTemplate';
import Units from './components/database/units/Units';
import Documentation from './components/documentation/DocumentationV2';
import NewLogin from './components/login/NewLogin';
import CreateCompany from './components/manage/company/CreateCompany';
import RightsController from './components/manage/rights/RightsController';
import TeamsController from './components/manage/teams/TeamsController';
import UsersController from './components/manage/users/UsersController';
import ConfirmUser from './components/manage/users/components/ConfirmUser';
import PdfPrint from './components/pdfPrint';
import Phase from './components/phase/Phase';
import PhaseForRevit from './components/phase/PhaseForRevit';
import Print from './components/print/Print';
import Building from './components/project/building/Building';
import BuildingObject from './components/project/buildingObject/BuildingObject';
import BuildingModel from './components/project/buldingModel/BuildingModel';
import Colors from './components/project/colors/Colors';
import Project from './components/project/project/Project';
import ProjectInformation from './components/project/projectInformation/ProjectInformation';
import TypeMark from './components/project/typemark/TypeMark';
import Confirm from './components/registration/Confirm';
import history from './history';
import ContractPicker from './subLayouts/contractPicker/ContractPicker';
import SuccessFulRegistration from './components/registration/SuccessfulRegistration';
import InstructionsTemplates from './components/instructions/InstructionsTemplate';
// import ThreeTesting from './ThreeTesting';

const pathsForTypeMarks = [
  'vyrobky/nabytek',
  'vyrobky/doplnky',
  'vyrobky/prvky-orientacniho-systemu',
  'vyrobky/technologicka-zarizeni-soubory',
  'vyrobky/dvere-vrata',
  'vyrobky/okna',
  'vyrobky/tesarske-konstrukce',
  'vyrobky/vyrobky-truhlarske',
  'vyrobky/vyrobky-zamecnicke',
  'vyrobky/vyrobky-klempirske',
  'vyrobky/vyrobky-ostatni',
  'prvky/zemni-prace',
  'prvky/zakladove-konstrukce',
  'prvky/operne-zarubni-steny',
  'prvky/nosne-konstrukce-svisle-vodorovne',
  'prvky/konstrukce-spojujici-ruzne-urovne',
  'prvky/steny',
  'prvky/lehke-obvodove-plaste',
  'prvky/proskelene-mobilni-pricky',
  'prvky/strechy',
  'prvky/podlahy',
  'prvky/podhledy',
];

class Routes extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    return (
      <>
        <Router history={history}>
          <div>
            <Switch>
              <Route
                path="/app"
                render={(props) =>
                  this.props.isAuthenticated === true ? (
                    <CustomLayout {...props}>
                      <Route path="/app" render={(props) => <Dashboard {...props}></Dashboard>} />
                      <Route
                        path="/app/sprava"
                        render={(props) => <Dashboard {...props}>sprava dashboard</Dashboard>}
                      />
                      <Route
                        path="/app/nastaveni/sprava-firmy/zalozit-firmu"
                        render={(props) => <CreateCompany {...props} />}
                      />
                      <Route
                        path="/app/nastaveni/sprava-firmy/sprava-firmy"
                        render={(props) => <Company {...props} />}
                      />
                      <Route
                        path="/app/nastaveni/sprava-firmy/sprava-tymu"
                        render={(props) => <TeamsController {...props} />}
                      />
                      <Route
                        path="/app/nastaveni/sprava-firmy/sprava-roli"
                        render={(props) => <RightsController {...props} />}
                      />
                      <Route
                        path="/app/nastaveni/sprava-firmy/sprava-uzivatelu"
                        render={(props) => <UsersController {...props} />}
                      />
                      <Route
                        path="/app/nastaveni/super-admin/databaze/jednotky"
                        render={(props) => <Units {...props} />}
                      />
                      <Route
                        path="/app/nastaveni/super-admin/databaze/tridnik"
                        render={(props) => <Classification {...props} />}
                      />
                      <Route
                        path="/app/data/stavby/stavby"
                        render={(props) => <Building {...props} />}
                      />
                      <Route
                        path="/app/data/akce/sprava-akci"
                        render={(props) => <Project {...props} />}
                      />
                      <Route
                        path="/app/akce/sprava/faze/model"
                        render={(props) => <BuildingModel {...props} />}
                      />
                      <Route
                        path="/app/akce/sprava/faze/vykonove-faze"
                        render={(props) => <Phase {...props} />}
                      />
                      <Route
                        path="/app/akce/sprava/vzhledy"
                        render={(props) => <Colors {...props} />}
                      />
                      <Route
                        path="/app/data/reseni/vlastnosti"
                        render={(props) => <Properties {...props} />}
                      />
                      <Route
                        path="/app/data/reseni/sady-vlastnosti"
                        render={(props) => <PropertySet {...props} />}
                      />
                      <Route
                        path="/app/data/obecne/biblio"
                        render={(props) => <Biblio {...props} />}
                      />
                      <Route
                        path="/app/data/reseni/polozky-zpravy"
                        render={(props) => <TechnicalReportItem {...props} />}
                      />
                      <Route
                        path="/app/data/reseni/sady-zprav"
                        render={(props) => <TechnicalReportItemSet {...props} />}
                      />
                      <Route
                        path="/app/data/reseni/profesni-vazby"
                        render={(props) => <ProfessionBond {...props} />}
                      />
                      <Route
                        path="/app/data/reseni/reseni"
                        render={(props) => <Solution {...props} />}
                      />
                      <Route
                        path={`/app/akce/(${pathsForTypeMarks.join('|')})/sestavy/:id?`}
                        render={(props) => <TypeMark {...props} />}
                      />
                      <Route
                        path={`/app/akce/(${pathsForTypeMarks.join('|')})/tisk`}
                        render={(props) => <Print {...props} />}
                      />
                      <Route
                        path={`/app/akce/(${pathsForTypeMarks.join('|')})/spolecne-informace`}
                        render={(props) => <ProjectInformation {...props} />}
                      />
                      <Route
                        path="/app/akce/stavba/stavebni-objekty"
                        render={(props) => <BuildingObject {...props} />}
                      />
                      <Route
                        path="/app/data/sablony/type-mark"
                        render={(props) => <TypeMarkTemplate {...props} />}
                      />
                      <Route
                        path="/app/data/sablony/instrukce/:id?"
                        render={(props) => <InstructionsTemplates {...props} />}
                      />
                      {/* <Route
                        path="/app/akce/vyrobky/dvere-vrata/tisk"
                        render={(props) => <Print {...props} />}
                      /> */}
                      <Route
                        exact
                        path="/app/pdf-tisk"
                        render={(props) => <PdfPrint {...props} />}
                      />
                      <Route
                        path="/app/akce/sprava/dokumentace"
                        render={(props) => <Documentation {...props} />}
                      />
                    </CustomLayout>
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                path={`/dvere-vrata/sestavy/:id`}
                render={(props) => {
                  return this.props.isAuthenticated === true ? (
                    <TypeMark {...props} isRevitView />
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />
              <Route
                path={`/vykonove-faze/:id?`}
                render={(props) => {
                  console.log({ props });
                  return this.props.isAuthenticated === true ? (
                    <PhaseForRevit {...props} isRevitView />
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />
              <Route path="/reset-password" render={(props) => <ResetPassword {...props} />} />
              <Route
                path="/password-reset-confirm/:token"
                render={(props) => <ResetPasswordConfirm {...props} />}
              />
              <Route
                exact
                path="/registration-confirm/:token"
                render={(props) => <Confirm {...props} />}
              />
              <Route
                exact
                path="/employee-verify/:token"
                render={(props) => <ConfirmUser {...props} />}
              />
              <Route exact path="/login" render={(props) => <NewLogin {...props} />} />
              <Route exact path="/test" render={(props) => <ContractPicker {...props} />} />
              <Route
                exact
                path="/registration"
                render={(props) => <SuccessFulRegistration {...props} />}
              />
              {/* <Route exact path="/app/databaze/obecne/jednotky" component={ResetPassword} />     */}
              {/* <Route exact path="/three" render={() => <ThreeTesting />} /> */}
              <Redirect to="/app" />
            </Switch>
          </div>
        </Router>
        <NotificationContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.access_token !== null,
});

const mapDispatchToProps = (dispatch) => ({
  onTryAutoSignup: () => dispatch(actions.authCheckState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
