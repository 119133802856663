import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import ColorsModal from './modals/ColorsModal';
import ColorsDetail from './ColorsDetail';
import withLeftPanelWrapper from '../../../utills/hoc/leftPanelWithDetailWrapper';

const Appearance = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.appearance);

  console.log({ isEdit });

  return (
    <div className="w-full">
      <Suspense fallback={<div>Loading...</div>}>
        <ColorsModal
          submitForm={handleSubmit}
          title={isEdit ? 'Editovat barvu' : 'Vytvořit barvu'}
          visible={openDialog}
          formProps={isEdit ? detail : undefined}
          onClose={handleClose}
        />
      </Suspense>

      {detail && <ColorsDetail onEdit={handleEdit} data={detail} makeEditable={handleEdit} />}
    </div>
  );
};

export default withLeftPanelWrapper(Appearance, 'appearance');
