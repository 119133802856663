import { NotificationManager } from 'react-notifications';
import { apiService } from '../../../utills/api';
import { emptyValuesAsUndefined } from '../../../utills/form';

function actionsFactory(name, withVersion = true) {
  const splittedName = name.split(/(?=[A-Z])/);
  const TYPE = splittedName.join('_').toUpperCase();
  let path = splittedName.join('-').toLowerCase();
  if (name === 'propertySet') {
    path = 'set-property-definition';
  }
  if (name === 'technicalReportItemSet') {
    path = 'set-technical-report-item';
  }
  if (name === 'contractListing') {
    path = 'contract';
  }
  if (name === 'typeMarkTemplate') {
    path = 'instance-type-template';
  }

  return {
    fetch(search, page, filter) {
      return async (dispatch, getState) => {
        try {
          const id = getState()[name].activeClassification;

          let { data } = await apiService.get(`/api/v1/${path}/left-panel/`, {
            params: {
              page: page,
              classification: id,
              page_size: 2,
              search: search,
              is_active: [true],
              ...filter,
              model: getState().contract.modelId,
              contract: getState().contract.contractId,
            },
          });

          if (data) {
            dispatch({
              type: `GET_${TYPE}_SUCCESS`,
              data: data,
              name: TYPE,
            });
          }
        } catch (err) {
          console.log(err);
        }
      };
    },
    fetchDetail(id) {
      return async (dispatch, getState) => {
        try {
          let { data } = await apiService.get(
            `/api/v1/${path}/${id}/${withVersion ? 'last-version/' : ''}`,
            {
              params: {
                classification: getState()[name].activeClassification,
              },
            }
          );

          if (data) {
            dispatch({
              type: `GET_${TYPE}_DETAIL_SUCCESS`,
              data: data,
              name: TYPE,
            });
          }
        } catch (err) {
          console.log(err);
        }
      };
    },
    create(values) {
      return async (dispatch, getState) => {
        try {
          let { data } = await apiService.post(
            `/api/v1/${path}/`,
            {
              ...values,
              classification: getState()[name].activeClassification,
              model: getState().contract.modelId,
              contract: getState().contract.contractId,
            },
            {
              params: {
                model: getState().contract.modelId,
                contract: getState().contract.contractId,
              },
            }
          );

          if (data) {
            dispatch({
              type: `CREATE_${TYPE}_SUCCESS`,
              data: data,
              name: TYPE,
            });
            // if (TYPE === "PHASE") {
            //   const { contractId, modelId } = getState().contract.contractId;
            //   dispatch(
            //     setContract({
            //       contract: contractId,
            //       phase: data.id,
            //       model: modelId,
            //     })
            //   );
            // } else if (TYPE === "MODEL") {
            //   const { contractId, phaseId } = getState().contract;
            //   dispatch(
            //     setContract({
            //       contract: contractId,
            //       phase: phaseId,
            //       model: data.id,
            //     })
            //   );
            // }
          }
        } catch (err) {
          console.log(err.response);
        }
      };
    },
    edit(id, values) {
      return async (dispatch, getState) => {
        try {
          if (withVersion) {
            let { data } = await apiService.post(
              `/api/v1/${path}/${id}/create-version/`,
              {
                ...emptyValuesAsUndefined(values),
              },
              {
                params: {
                  model: getState().contract.modelId,
                  contract: getState().contract.contractId,
                },
              }
            );

            dispatch({
              type: `EDIT_${TYPE}_SUCCESS`,
              data: data,
              name: TYPE,
            });
          } else {
            let { data } = await apiService.patch(
              `/api/v1/${path}/${id}/`,
              {
                ...emptyValuesAsUndefined(values),
              },
              {
                params: {
                  model: getState().contract.modelId,
                },
              }
            );

            dispatch({
              type: `EDIT_${TYPE}_SUCCESS`,
              data: data,
              name: TYPE,
            });
          }
        } catch (err) {
          NotificationManager.error('Pozor', 'Nejspise jste nevyplnil spravne data');
        }
      };
    },
    setActiveClassification(id) {
      return async (dispatch) => {
        dispatch({
          type: `${TYPE}_ACTIVE_CLASSIFICATION`,
          data: id,
          name: TYPE,
        });
      };
    },
    clearItems() {
      return async (dispatch) => {
        dispatch({ type: `CLEAR_ITEMS_${TYPE}`, name: TYPE });
      };
    },
    clear() {
      return async (dispatch) => {
        dispatch({ type: `CLEAR_${TYPE}`, name: TYPE });
      };
    },
  };
}

export default actionsFactory;
