import { Delete12Regular, Edit12Regular } from '@fluentui/react-icons';
import React from 'react';

const FormList = ({ data, withValue, onDelete, onEdit }) => {
  if (!data || !Array.isArray(data)) return null;

  return (
    <ul className="flex flex-col p-1">
      {data.map((ele, i) => (
        <li key={i} className="flex w-full text-neutral-500 px-2 border-b border-neutral-200">
          {withValue ? (
            <span className="grow">
              <span className="mr-2">{ele.title}</span>
              <span>{ele.value}</span>
            </span>
          ) : (
            <span className="grow">{ele.title}</span>
          )}
          <div className="shrink-0 flex cursor-pointer">
            {onEdit && <Edit12Regular key={1} onClick={() => onEdit(ele.id)} />}
            <Delete12Regular className="w-5 h-5" key={2} onClick={() => onDelete(ele.id)} />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FormList;
