import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import { InputField } from '../../../../../shared/InputField';
import { SelectField } from '../../../../../shared/SelectField';
import Yup from '../../../../../utills/myYup';

const UnitsForm = ({
  formProps = {},
  submitOutside,
  submit,
  error,
  reset,
  cultures,
  resetConfirm,
  isActiveForm,
  isEditCultureMutations = false,
}) => {
  const formRef = useRef();

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed && submitOutside) {
      formRef.current.submitForm();
    }

    return () => (isSubscribed = false);
  }, [submitOutside]);

  useEffect(() => {
    if (reset === true) {
      formRef.current.setValues(formProps);
      resetConfirm();
    }
  }, [reset]);

  useEffect(() => {
    formRef.current.setErrors(error?.response?.data || '');
  }, [error]);

  const init = (props) => {
    if (props) {
      return {
        title: props.title,
        mark: props.mark,
        description: props.description,
        is_active: props.is_active ? 1 : 2,
      };
    }
    return {
      title: null,
      biblio: null,
      mark: null,
      description: null,
      is_active: null,
    };
  };

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    mark: Yup.string().required('Povinné pole'),
    description: Yup.string().required('Povinné pole'),
  });

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          values.is_active = values.is_active ? 1 : 2;
          submit(values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          const fields = (
            <>
              {isEditCultureMutations === false && !isActiveForm && (
                <Field
                  name="biblio"
                  value={values.biblio || null}
                  placeholder="Biblio"
                  component={SelectField}
                  data={[]}
                  onChange={() => {}}
                />
              )}
              {!isActiveForm && (
                <>
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                  <Field
                    name="mark"
                    value={values.mark || null}
                    placeholder="Značka"
                    component={InputField}
                  />
                  <Field
                    name="description"
                    value={values.description || null}
                    placeholder="Popisek"
                    component={InputField}
                  />
                </>
              )}
              {isEditCultureMutations === false && !isActiveForm && (
                <Field
                  name="culture"
                  value={values.culture || null}
                  placeholder="Kultura"
                  component={SelectField}
                  data={cultures || []}
                  onChange={(val) => setFieldValue('culture', val)}
                />
              )}
              {isEditCultureMutations === false && isActiveForm === true && (
                <Field
                  name="is_active"
                  value={values.is_active || null}
                  placeholder=""
                  component={SelectField}
                  data={[
                    { value: 1, id: 1, title: 'Aktivní' },
                    { value: 2, id: 2, title: 'Neaktivní' },
                  ]}
                  onChange={(val) => setFieldValue('is_active', val)}
                />
              )}
            </>
          );

          return <Form labelCol={{ span: 4 }}>{fields}</Form>;
        }}
      </Formik>
    </div>
  );
};

export default UnitsForm;
