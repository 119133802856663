import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainContainer from '../../../containers/MainContainer';
import LeftPanelWithClassification from '../../../shared/components/LeftPanelWithClassification';

import {
  closeModal,
  getClassification,
  getClassificationDetail,
  openModal,
  setActiveClassification,
  updateClassification,
} from '../../../store/actions/classification';
import ClassificationDetailView from './ClassificationDetailView';
import ClassificationModal from './components/modals/ClassificationModal';

const URL = `/api/v1/classification/left-panel/`;

function Classification() {
  const { classification_items, edit } = useSelector((state) => state.classification);
  const { active_classification, modalVisible } = useSelector((state) => state.classification);

  const [filter, setFilter] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClassification(null, null, URL));
  }, []);

  const makeActiveClassification = (id) => {
    dispatch(setActiveClassification(id));
    dispatch(getClassificationDetail(id));
  };

  const getDetail = (id) => {
    dispatch(getClassificationDetail(id));
  };

  const handleSubmit = (values) => {
    dispatch(updateClassification(values));
  };

  const handleSearch = (value) => {
    dispatch(getClassification(filter, value, URL));
  };

  const handleFilter = (value) => {
    setFilter(value);
    dispatch(getClassification(value, null, URL));
  };

  return (
    <MainContainer>
      <LeftPanelWithClassification
        title="Třídník"
        data={classification_items}
        makeActive={makeActiveClassification}
        active={active_classification}
        onSearch={(e) => handleSearch(e.target.value)}
        addItem={() => dispatch(openModal())}
        onFilter={(val) => handleFilter(val)}
        url={URL}
        filteredValues={{
          is_active: [
            { value: true, title: 'Aktivní' },
            { value: false, title: 'Neaktivní' },
          ],
        }}
        disableAdd={false}
      />
      {active_classification && <ClassificationDetailView />}
      {modalVisible && (
        <ClassificationModal
          title={edit ? 'Editovat položku' : 'Přidat položku'}
          submitForm={handleSubmit}
          visible={modalVisible}
          onClose={() => dispatch(closeModal())}
        />
      )}
    </MainContainer>
  );
}
export default Classification;
