import React, { Component } from 'react';
import style from './dashboard.module.css';

export default class Dashboard extends Component {
  render() {
    if (this.props.match.path !== this.props.location.pathname) return null;

    return <div className={style.dashboard}>{this.props.children}</div>;
  }
}
