import { FormOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import MiniTopNavigation from '../components/navigation/miniTopNavigation';
import ContractPickerModal from './contractPicker/components/ContractPickerModal';
import ContractPicker from './contractPicker/ContractPicker';
import SubSubLayout from './subSubLayout';

class SubLayout extends Component {
  state = {
    dialogVisible: false,
  };

  handleOpenDialog = () => {
    this.setState({
      dialogVisible: true,
    });
  };

  render() {
    const pathname = this.props.location.pathname;
    return (
      <header className="visible">
        <div className="topnav">
          {pathname.includes('app/akce') && (
            <div style={{ width: '300px', cursor: 'pointer' }}>
              <div
                style={{
                  backgroundColor: 'white',
                  display: 'flex',
                  width: '23px',
                  height: '23px',
                  float: 'left',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'rgb(204, 204, 204)',
                  borderBottom: '1px solid rgb(219, 219, 219)',
                  cursor: 'pointer',
                }}
              >
                <FormOutlined onClick={() => this.handleOpenDialog()} />
              </div>
              <ContractPicker />
            </div>
          )}
          {(() => {
            if (this.props.items.length > 0) {
              return this.props.items.map((item) => {
                if (pathname.includes(item.route_1)) {
                  return (
                    <MiniTopNavigation
                      key={item.id}
                      route={item.route_1 + item.route_2}
                      text={item.title}
                    />
                  );
                }
                return null;
              });
            }
            return null;
          })()}
        </div>
        <div style={{ height: '60px' }}>
          {(() => {
            if (this.props.items2.length !== 0) {
              return this.props.items.map((item) => {
                if (pathname.includes(item.route_1)) {
                  return (
                    <Route
                      path={item.route_1 + item.route_2}
                      key={item.route_2}
                      render={(props) => (
                        <SubSubLayout
                          {...props}
                          items={this.props.items2}
                          items2={this.props.items4}
                        />
                      )}
                    />
                  );
                } else return null;
              });
            } else return null;
          })()}
        </div>
        <div
          style={{
            height: '20px',
            zIndex: '5',
            backgroundColor: '#f7f7f7',
            boxShadow: '1px 2px 5px gray',
          }}
        ></div>
        {this.state.dialogVisible && (
          <ContractPickerModal
            visible={this.state.dialogVisible}
            onClose={() => this.setState({ dialogVisible: false })}
          />
        )}
      </header>
    );
  }
}

export default SubLayout;
