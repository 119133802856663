import Bar from '../../../../elements/Bar';
import { EditOutlined } from '@ant-design/icons';
import List from '../../../../elements/List';
import { usePermission } from '../../../../hookd/usePerrmission';

const BiblioView = ({ data, onEdit }) => {
  const { isEditable } = usePermission();
  const prepareData = () => {
    if (!data) return [];
    return [
      { name: 'Označení', value: data.designation },
      { name: 'Název', value: data.title },
      { name: 'Rok vydání', value: data.publication_year },
    ];
  };

  return (
    <div className="border-l-2 border-neutral-200 h-full">
      <Bar text="Detail" content={isEditable ? [<EditOutlined key={1} onClick={onEdit} />] : []} />
      <List data={prepareData()} />
      <div className="mb-[1px]">
        <Bar text="Soubory" />
        <List
          data={(data?.files || []).map((file) => ({
            name: file.title,
          }))}
        />
      </div>
    </div>
  );
};

export default BiblioView;
