import React from 'react';
import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const FormItem = Form.Item;

export const AreaField = ({ field, form: { touched, errors, submitCount }, ...props }) => {
  const errorMsg =
    (touched[field.name] && errors[field.name]) || (errors[field.name] && submitCount > 0)
      ? errors[field.name]
      : null;

  return (
    <FormItem
      help={errorMsg}
      hasFeedback={!!errors[field.name]}
      validateStatus={errors[field.name] && (touched[field.name] || submitCount > 0) && 'error'}
    >
      <TextArea {...field} {...props} rows={5} showCount={!!props.limit} maxLength={props.limit} />
    </FormItem>
  );
};
