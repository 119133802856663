import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACTION_TYPES,
  SharedListWithBar,
} from '../../../../../shared/components/SharedListWithBar';
import {
  chooseTranslation,
  clearTranslations,
  getTranslations,
  startEditDetail,
} from '../../../../../store/actions/classification';

const prepareData = (translation) => {
  if (!translation) return [];
  let { title } = translation;
  return [{ name: 'Název', value: title }];
};

const renderListOfTranslations = (translations, dispatch) => {
  return (translations || []).map((translation, i) => {
    let properties = prepareData(translation);
    let lang = translation?.language;
    return (
      <SharedListWithBar
        key={i}
        title={lang?.title}
        data={properties}
        actionType={ACTION_TYPES.EDIT}
        action={() => dispatch(chooseTranslation(lang?.code))}
      />
    );
  });
};

const ClassificationDetailModal = ({ visible, onClose }) => {
  const { detail, translations } = useSelector((state) => state.classification);
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) dispatch(getTranslations());
  }, [visible]);

  useEffect(() => {
    return () => dispatch(clearTranslations());
  }, []);

  if (!detail) return null;

  const editTranslation = () => {};

  const getData = () => {
    return [
      {
        name: 'Definuje komponentu',
        value: detail.is_def_component ? 'Ano' : 'Ne',
      },
      {
        name: 'Definuje skladbu',
        value: detail.is_def_composition ? 'Ano' : 'Ne',
      },
      { name: 'Aktivní', value: detail.is_active ? 'Ano' : 'Ne' },
    ];
  };

  return (
    <Modal
      title={'Editace detailu'}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton primary key={2} action={() => onClose()}>
          Zavřít
        </ModalButton>,
      ]}
    >
      <SharedListWithBar
        title={detail.title}
        data={getData()}
        action={() => dispatch(startEditDetail())}
      />
      {renderListOfTranslations(translations, dispatch)}
    </Modal>
  );
};

ClassificationDetailModal.propTypes = {
  visible: propTypes.bool,
  onClose: propTypes.func,
  title: propTypes.string,
};

export default ClassificationDetailModal;
