import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstances } from '../../../../store/actions/typemark';

const InstanceTable = ({ id }) => {
  // const columnHelper = createColumnHelper();

  const columns = [
    {
      accessorFn: (row) => {
        return row.id;
      },
      id: 'indetify',
      cell: (info) => {
        return info.getValue();
      },
      header: () => <span>Indentifikátor</span>,
    },
    {
      accessorFn: (row) => {
        return row?.building_object?.title || '';
      },
      id: 'buildingObject',
      cell: (info) => {
        return info.getValue();
      },
      header: () => <span>St. objekt</span>,
    },
    {
      accessorFn: (row) => {
        return row?.storey?.title || '';
      },
      id: 'storey',
      cell: (info) => {
        return info.getValue();
      },
      header: () => <span>Podlaží</span>,
    },
    {
      accessorFn: (row) => {
        return row?.room?.title || '';
      },
      id: 'room',
      cell: (info) => {
        return info.getValue();
      },
      header: () => <span>Místnost</span>,
    },
  ];

  const PAGE_SIZE = 10;

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 1,
    pageSize: PAGE_SIZE,
  });
  const dispatch = useDispatch();
  const { instances, detail } = useSelector((state) => state.typeMark);

  const table = useReactTable({
    data: instances.items || [],
    columns,
    state: {
      pageIndex: pageIndex,
      pageSize: 10,
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  useEffect(() => {
    if (!id) return;
    dispatch(fetchInstances(id, pageIndex, PAGE_SIZE));
  }, [pageIndex, id]);

  return (
    <div>
      <table className="table-auto w-full text-left">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="border-b">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="uppercase text-color2 text-[12px] tracking-widest	border-r p-2"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="text-color2 text-[13px]">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2 border-r">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <div className="flex bg-color4 w-full items-center text-color2 justify-between px-4 text-sm uppercase">
        <div className="flex justify-center h-8">
          {pageIndex > 1 && (
            <button
              className="px-4 cursor-pointer"
              onClick={() => {
                table.previousPage();
              }}
            >
              {'<'}
            </button>
          )}
          {pageIndex * PAGE_SIZE < instances.count && (
            <button
              className="px-4 cursor-pointer"
              onClick={() => {
                table.nextPage();
              }}
            >
              {'>'}
            </button>
          )}
        </div>
        <span>{'celkem: ' + instances.count}</span>
      </div>
    </div>
  );
};

export default InstanceTable;
