import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import React, { useState } from 'react';
import Filter from '../../components/filter/Filter';
import BaseContainer from '../../containers/BaseContainer';
import Bar from '../../elements/Bar';
import NavList from '../../elements/NavList';
import useWindowSize from '../hooks/windowSizeHook';
import { debounce } from 'lodash';
import { Spin } from 'antd';

export default function LeftPanel({
  data,
  makeActive,
  active,
  onSearch,
  addItem,
  onFilter,
  title,
  filteredValues,
  fetchMore,
  dataLength,
  hasMore,
  disableAdd = false,
  permission = 2,
  loading,
}) {
  const [windowWidth, windowHeight] = useWindowSize();
  const [visibleFilter, setVisibleFilter] = useState(false);

  const handleDebouncedSearch = debounce((e) => {
    onSearch(e);
  }, 1000);

  const prepareData = () => (data || []).map((item) => ({ ...item, text: item?.title }));

  const handleFilter = (values) => {
    setVisibleFilter(false);
    onFilter(values);
  };

  const content = () => {
    const items = [
      <Filter
        key={1}
        filteredValues={filteredValues}
        visible={visibleFilter}
        onFilter={handleFilter}
        onClose={() => setVisibleFilter(false)}
      >
        <FilterOutlined onClick={() => setVisibleFilter(true)} />
      </Filter>,
    ];
    if (permission === 2 && !disableAdd) {
      items.push(<PlusOutlined key={2} onClick={addItem} />);
    }

    return items;
  };

  return (
    <div>
      <BaseContainer width="420px" margin className="border-r">
        <Bar text={title} content={content()} />
        <Search
          style={{ height: '30px' }}
          placeholder="Vyhledat"
          onChange={handleDebouncedSearch}
        />
        <div style={{ height: 'calc(100vh - 163px)' }} className="overflow-auto">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <Spin />
            </div>
          ) : (
            <NavList
              height={(windowHeight || 500) - 170}
              data={prepareData()}
              makeActive={makeActive}
              active={active}
              hasMore={hasMore}
              dataLength={dataLength}
              fetchMoreData={fetchMore}
            />
          )}
        </div>
      </BaseContainer>
    </div>
  );
}
