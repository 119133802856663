import MainContainer from '../../containers/MainContainer';
import NLeftPanel from '../../shared/components/NLeftPanel';
import { SearchContext } from '../project/building/Building';
import { useState } from 'react';
import InstructionTemplateDetail from './InstructionTemplateDetail';
import { useHistory, useParams } from 'react-router-dom';

const InstructionsTemplates = () => {
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({});
  const history = useHistory();
  const { id } = useParams();

  const handleActive = (id) => {
    history.push(`/app/data/sablony/instrukce/${id}`);
  };

  return (
    <SearchContext.Provider
      value={{
        search,
        filter,
        setSearch,
        setFilter,
      }}
    >
      <MainContainer>
        <div className="flex w-full">
          <NLeftPanel
            queryKey={'instruction-template'}
            makeActive={handleActive}
            active={id}
            onSearch={() => {}}
            addItem={() => {}}
            onFilter={() => {}}
            defaultFilterValues={{ is_active: [true] }}
            title="Šablony instrukcí"
            permission={2}
            filteredValues={{
              is_active: [
                { value: true, title: 'Aktivní' },
                { value: false, title: 'Neaktivní' },
              ],
            }}
          />

          <InstructionTemplateDetail />
        </div>
      </MainContainer>
    </SearchContext.Provider>
  );
};

export default InstructionsTemplates;
