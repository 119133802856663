import React from 'react';
import Logo from '../../images/logoMoiry.svg';
import style from './week.module.css';

Date.prototype.getWeek = function () {
  var jan4th = new Date(this.getFullYear(), 0, 4);
  return Math.ceil(((this - jan4th) / 86400000 + jan4th.getDay() + 1) / 7);
};

const Week = (props) => {
  const now = new Date();

  return (
    <li className={style.week} onClick={() => props.history.push('/')}>
      {/* <div className={style.content}> */}
      <img src={Logo} alt="Moiry" />
      <span>{now.getWeek()}</span>
      {/* </div> */}
    </li>
  );
};

export default Week;
