import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import { InputField } from '../../../../shared/InputField';

const EditCompanyForm = ({
  formProps = {},
  submitOutside,
  submit,
  error,
  reset = false,
  isIco = false,
  ares = null,
  resetConfirm,
}) => {
  const formRef = useRef();

  useEffect(() => {
    if (reset === true) {
      formRef.current.setValues(formProps);
      resetConfirm();
    }
  }, [reset]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && submitOutside) {
      formRef.current.submitForm();
    }

    return () => (isSubscribed = false);
  }, [submitOutside]);

  useEffect(() => {
    formRef.current.setErrors(error?.response?.data || '');
  }, [error]);

  useEffect(() => {
    if (ares) {
      formRef.current.setValues(ares);
    }
  }, [ares]);

  const init = ({ ico, dic, title, address, description }) => ({
    ico,
    dic,
    title,
    address,
    description,
  });
  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {(props) => {
          const { values } = props;

          return (
            <Form labelCol={{ span: 4 }}>
              <Form.Item label="IČO">
                <Field
                  disabled={formProps.ico}
                  name="ico"
                  value={values.ico}
                  placeholder="IČO"
                  component={InputField}
                />
              </Form.Item>
              {!isIco && (
                <>
                  <Form.Item label="DIČ">
                    <Field name="dic" value={values.dic} placeholder="DIČ" component={InputField} />
                  </Form.Item>
                  <Form.Item label="Název">
                    <Field
                      name="title"
                      value={values.title}
                      placeholder="Název"
                      component={InputField}
                    />
                  </Form.Item>
                  <Form.Item label="Sídlo">
                    <Field
                      name="address"
                      value={values.address}
                      placeholder="Sídlo"
                      component={InputField}
                    />
                  </Form.Item>
                  <Form.Item label="Zaměření">
                    <Field
                      name="description"
                      value={values.description}
                      placeholder="Zaměření"
                      component={InputField}
                    />
                  </Form.Item>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditCompanyForm;
