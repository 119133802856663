import { apiService } from '../utills/api';

export const getLastUsagesAndFavorites = () =>
  apiService.get(`/api/v1/contract-picker/dropdown-preview/`);

export const getContracts = (term) =>
  apiService.get(`/api/v1/contract-picker/dropdown/`, {
    params: {
      search: term,
      page_size: 100,
    },
  });

export const getAllDataForManager = (next = null, search = null, filter = {}) =>
  apiService.get(`/api/v1/contract-picker/manager-all/`, {
    params: {
      page: next,
      search,
      page_size: 20,
      ...filter,
    },
  });

export const toFavorite = (id) =>
  apiService.get(`/api/v1/contract-picker/${id}/is-favorite-switch/`);

export const getPhases = (id) =>
  apiService.get(`/api/v1/contract-picker/${id}/manager_pick_phase/`);

export const pickContract = (id) => apiService.get(`/api/v1/contract-picker/${id}/pick/`);

export const updateContract = (id, values) =>
  apiService.patch(`/api/v1/contract-picker/${id}/manager_pick_phase/`, values);

export const getDataManagerCompany = (next = 1, search = null, filter = {}) =>
  apiService.get(`/api/v1/contract-picker/manager-company/`, {
    params: {
      page: next,
      search,
      page_size: 20,
      ...filter,
    },
  });

export const getDataManagerFavorite = (next = 1, search = null, filter = {}) =>
  apiService.get(`/api/v1/contract-picker/manager-favorite/`, {
    params: {
      page: next,
      search,
      page_size: 20,
      ...filter,
    },
  });

export const getDataManagerShared = (next = 1, search = null, filter = {}) =>
  apiService.get(`/api/v1/contract-picker/manager-shared/`, {
    params: {
      page: next,
      search,
      page_size: 20,
      ...filter,
    },
  });
