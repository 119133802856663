import Bar from '../../../../elements/Bar';
import { EditOutlined } from '@ant-design/icons';
import List from '../../../../elements/List';
import { usePermission } from '../../../../hookd/usePerrmission';

const TechnicalReportItemSetView = ({ data, onEdit }) => {
  const { isEditable } = usePermission();
  const prepareData = () => {
    if (!data) return [];
    return [{ name: 'Název', value: data.title }];
  };

  return (
    <div className="border-l-2 border-neutral-200 h-full">
      <Bar text="Detail" content={isEditable ? [<EditOutlined key={1} onClick={onEdit} />] : []} />
      <List data={prepareData()} />
      <div className="mb-[1px]">
        <Bar text="Položky technické zprávy" />
        <List
          data={(data?.technical_report_item_set || []).map((technical_report_item) => ({
            name: technical_report_item.description,
          }))}
        />
      </div>
    </div>
  );
};

export default TechnicalReportItemSetView;
