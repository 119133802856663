import React from 'react';
import { useSelector } from 'react-redux';
import PropertiesModal from './components/modals/PropertiesModal';
import PropertiesView from './components/PropertiesView';
import withDbItemsWrapper from '../../../utills/hoc/dbItemsWrapper';

function Properties({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) {
  const { detail } = useSelector((state) => state.propertyDefinition);

  return (
    <div className="w-full min-w-0">
      {detail && <PropertiesView data={detail} onEdit={handleEdit} />}
      <PropertiesModal
        title="Vlastnost"
        visible={openDialog}
        formProps={isEdit ? detail : null}
        classification={[]}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
}

export default withDbItemsWrapper(
  Properties,
  'propertyDefinition',
  'left-panel-property-definition/'
);
