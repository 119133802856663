import React from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import { apiService } from '../../../../../utills/api';
import Label from '../../../../Label';
import { AreaField } from '../../../../../shared/AreaField';
import ModalButton from '../../../../../shared/modal/ModalButton';

const HasValueModal = ({ visible, title, onSubmit, onClose }) => {
  const ValidationSchema = Yup.object().shape({
    value: Yup.string().required('Povinné pole'),
    description: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          value: null,
          description: null,
        }}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="value" title="Hodnota" required />
                  <Field
                    name="value"
                    value={values.value || null}
                    placeholder="Hodnota"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="description" title="Popisek" required />
                  <Field
                    name="description"
                    value={values.description || null}
                    placeholder="Popisek"
                    component={AreaField}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default HasValueModal;
