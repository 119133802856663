import React, { useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import useFormRutine from '../../../../../shared/hooks/formHook';
import { InputField } from '../../../../../shared/InputField';
import Yup from '../../../../../utills/myYup';
import { AreaField } from '../../../../../shared/AreaField';
import { DatePickerField } from '../../../../../shared/DatePickerField';
import { validDate } from '../../../../../utills/time';
import { CheckboxField } from '../../../../../shared/Checkbox';

const EtapeForm = ({ formProps, submitOutside, submit, error, reset = false, resetConfirm }) => {
  const formRef = useRef();

  useFormRutine(formRef, submitOutside, reset, formProps, resetConfirm, error);

  const init = ({
    building_title,
    description,
    title,
    is_active,
    is_owned_by_my_company,
    is_physically_realized,
    created,
    finished,
  } = {}) => ({
    building_title,
    description,
    title,
    is_active,
    is_owned_by_my_company,
    is_physically_realized,
    created: created,
    finished: finished,
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    building_title: Yup.string().required('Povinné pole'),
    description: Yup.string().required('Povinné pole'),
    created: Yup.string().required('Povinné pole'),
    finished: Yup.string().required('Povinné pole'),
    is_owned_by_my_company: Yup.boolean(),
    is_physically_realized: Yup.boolean(),
    is_active: Yup.boolean(),
  });

  return (
    <div>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          return (
            <Form labelCol={{ span: 6 }}>
              <Form.Item label="Název etapy">
                <Field
                  name="title"
                  value={values.title}
                  placeholder="Název etapy"
                  component={InputField}
                  disabled={!formProps.is_owned_by_my_company}
                />
              </Form.Item>
              <Form.Item label="Název stavby">
                <Field
                  name="building_title"
                  value={values.building_title}
                  placeholder="Název stavby"
                  component={InputField}
                  disabled={!formProps.is_owned_by_my_company}
                />
              </Form.Item>
              <Form.Item label="Popis etapy">
                <Field
                  name="description"
                  value={values.description}
                  placeholder="Popis etapy"
                  component={AreaField}
                  disabled={!formProps.is_owned_by_my_company}
                />
              </Form.Item>

              <Form.Item label="Vytvořeno">
                <Field
                  name="created"
                  value={validDate(values.created)}
                  placeholder="Vytvořeno"
                  component={DatePickerField}
                  onChange={(date) => setFieldValue('created', date)}
                  disabled={!formProps.is_owned_by_my_company}
                />
              </Form.Item>
              <Form.Item label="Dokončeno">
                <Field
                  name="finished"
                  value={validDate(values.finished)}
                  placeholder="Dokončeno"
                  component={DatePickerField}
                  onChange={(date) => setFieldValue('finished', date)}
                  disabled={!formProps.is_owned_by_my_company}
                />
              </Form.Item>

              <div className="flex gap-1 w-full px-4 justify-center">
                <Form.Item label="Aktivní" className="flex-1" labelCol={1}>
                  <Field
                    name="is_active"
                    value={values.is_active}
                    placeholder="Aktivní"
                    component={CheckboxField}
                    disabled={!formProps.is_owned_by_my_company}
                  />
                </Form.Item>

                <Form.Item label="Realizováno" className="flex-1" labelCol={1}>
                  <Field
                    name="is_physically_realized"
                    value={values.is_physically_realized}
                    placeholder="Realizováno"
                    component={CheckboxField}
                    disabled={!formProps.is_owned_by_my_company}
                  />
                </Form.Item>

                <Form.Item label="Firma je vlastníkem" className="flex-1" labelCol={1}>
                  <Field
                    name="is_owned_by_my_company"
                    value={values.is_owned_by_my_company}
                    placeholder="Firma je vlastníkem"
                    component={CheckboxField}
                    disabled={!formProps.is_owned_by_my_company}
                  />
                </Form.Item>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EtapeForm;
