import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import Tree from './RightsTree';
import treeStyle from './rightTree.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(treeStyle);
const itemTrea = (item, changeRules) => {
  const _changeRules = (e) => {
    changeRules(item.id, e.target.value);
  };

  if (item.children.length === 0) {
    return (
      <Tree
        key={item.id}
        content={item.title}
        status={item.status}
        visible
        open
        changeRules={_changeRules}
      />
    );
  }
  return (
    <Tree
      key={item.id}
      content={item.title}
      status={item.status}
      canHide
      visible
      open
      changeRules={_changeRules}
    >
      {item.children.map((child) => itemTrea(child, changeRules))}
    </Tree>
  );
};

const makeTree = (data, changeRules) => {
  return (data || []).map((ele) => itemTrea(ele, changeRules));
};

const Header = () => (
  <div className={cx(treeStyle.main, treeStyle.row)}>
    <span>Část</span>
    <span className={treeStyle.containerFlex}>
      <span className={treeStyle.radioContainer}>Čtení</span>
      <span className={treeStyle.radioContainer}>Editace</span>
      <span className={treeStyle.radioContainer}>Odepřít</span>
    </span>
  </div>
);

export default function RightsDetailView({ data, changeRules, makeEditable, permission }) {
  if (!data) {
    return null;
  }
  return (
    <div>
      <BaseContainer fullHeight={true} width={'calc(100% - 420px)'} margin>
        <Bar
          text={'Nastavení oprávnění'}
          content={permission === 2 ? [<EditOutlined key={1} onClick={makeEditable} />] : []}
        />
        {data && <Header />}
        {makeTree(data, changeRules)}
      </BaseContainer>
    </div>
  );
}
