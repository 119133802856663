import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { apiService } from '../../../utills/api';

export const useGetTemplates = () => {
  return useQuery({
    queryKey: 'templates',
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/template/dropdown/`);
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetBuildingObjects = () => {
  const model = useSelector((state) => state.contract.modelId);

  return useQuery({
    queryKey: ['building-objects'],
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/building-object/dropdown/`, {
        params: {
          model: model,
        },
      });
      return data;
    },
    refetchOnWindowFocus: false,
  });
};
