import Bar from '../../../../elements/Bar';
import { EditOutlined } from '@ant-design/icons';
import List from '../../../../elements/List';
import { usePermission } from '../../../../hookd/usePerrmission';
import PropertyListWithBiblio from './List';

const PropertySetView = ({ data, onEdit }) => {
  const { isEditable } = usePermission();

  const prepareData = () => {
    if (!data) return [];
    return [
      { name: 'Název', value: data.title },
      { name: 'Popis', value: data.description },
    ];
  };

  return (
    <div className="border-l-2 border-neutral-200 h-full">
      <Bar text="Detail" content={isEditable ? [<EditOutlined key={1} onClick={onEdit} />] : []} />
      <List data={prepareData()} />
      <div className="mb-[1px]">
        <Bar text="Přiřazené vlastnosti" />
        <PropertyListWithBiblio data={data?.property_definition_set || []} />
      </div>
    </div>
  );
};

export default PropertySetView;
