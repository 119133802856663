import { apiService } from '../../utills/api';
import { EDIT_TYPES } from '../../components/database/classification/constants';

const PAGE_SIZE = 11;

export const getClassification =
  (filter = {}, search = null, url, parent = null) =>
  async (dispatch, getState) => {
    dispatch({ type: 'START_LOADING_CLASSIFICATION' });

    try {
      const { data } = await apiService.get(url, {
        params: { search, page_size: PAGE_SIZE, ...filter, parent },
      });

      if (data) {
        dispatch({ type: 'GET_CLASSIFICATION_SUCCESS', data: data?.results });
      }
    } catch (err) {
      console.log(err);
    }
  };

export const getClassificationChildren = (parent, url) => async (dispatch, getState) => {
  dispatch({ type: 'START_LOADING_CLASSIFICATION_CHILDREN' });

  const startFetchRutine = async (next) => {
    if (!next) return null;
    try {
      const { data } = await apiService.get(url, {
        params: { parent, page: next, page_size: PAGE_SIZE },
      });

      if (data) {
        dispatch({
          type: 'GET_CLASSIFICATION_CHILDREN_SUCCESS',
          data: data?.results,
          id: parent,
        });
        startFetchRutine(data?.next);
      }
    } catch (err) {
      console.log(err);
    }
  };
  startFetchRutine(1);
};

export const getTranslations = () => async (dispatch, getState) => {
  dispatch({ type: 'START_LOADING_CLASSIFICATION_TRANSLATIONS' });

  try {
    const { data } = await apiService.get(
      `/api/v1/classification/${getState()?.classification?.active_classification}/edit_detail/`
    );

    if (data) {
      console.log(data);
      dispatch({
        type: 'GET_CLASSIFICATION_TRANSLATIONS_SUCCESS',
        data: data?.translation_set || [],
      });
    }
  } catch (err) {
    dispatch({ type: 'GET_CLASSIFICATION_TRANSLATIONS_FAILED', data: err });
  }
};

export const clearTranslations = () => async (dispatch, getState) => {
  dispatch({ type: 'CLEAR_CLASSIFICATION_TRANSLATIONS' });
};

export const updateClassification = (values) => async (dispatch, getState) => {
  try {
    const activeClassification = getState()?.classification?.active_classification;
    let result = null;
    const editType = getState()?.classification?.edit;

    if (!editType) {
      const { data } = await apiService.post(`/api/v1/classification/`, {
        parent: activeClassification,
        ...values,
        is_def_component: values.is_def_component === 1,
        is_def_composition: values.is_def_composition === 1,
      });
      result = data;
    } else if (editType === EDIT_TYPES.detail) {
      const { data } = await apiService.patch(
        `/api/v1/classification/${activeClassification}/edit_detail/`,
        {
          title: getState()?.classification?.detail?.title,
          is_def_component: values.is_def_component === 1,
          is_def_composition: values.is_def_composition === 1,
        }
      );
      if (data) {
        dispatch({ type: 'CLASSIFICATION_DETAIL_UPDATED', data });
      }
    } else if (editType === EDIT_TYPES.translations) {
      const detail = getState()?.classification?.detail;
      const translations = getState()?.classification?.translations;
      const updatedTranslationIndex = translations.findIndex(
        (trans) => trans.language.code === getState()?.classification?.choosen_translation
      );
      console.log(translations, getState()?.classification?.choosen_translation);
      const updatedTranslations = translations;

      if (updatedTranslationIndex > -1) {
        updatedTranslations[updatedTranslationIndex].title = values.title;
      }
      const { data } = await apiService.patch(
        `/api/v1/classification/${activeClassification}/edit_detail/`,
        {
          title: getState()?.classification?.detail?.title,
          is_def_component: detail.is_def_component === 1,
          is_def_composition: detail.is_def_composition === 1,
          translation_set: updatedTranslations,
        }
      );
      if (data) {
        dispatch({ type: 'CLASSIFICATION_TRANSLATIONS_UPDATED', data });
      }
    }

    if (result) {
      console.log(result);
      // dispatch({ type: "GET_CLASSIFICATION_SUCCESS", data: data?.results });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAlternativeClassifications = () =>
  apiService.get(`/api/v1/classification-system/dropdown/`);

export const deleteAlternativeClassificationsItem = (id) =>
  apiService.delete(`/api/v1/alternative-classification/${id}/`);

export const patchAlternativeClassifications = (id, values) =>
  apiService.patch(`/api/v1/alternative-classification/${id}/`, {
    ...values,
  });

export const postAlternativeClassifications = (values, active_classification) =>
  apiService.post(`/api/v1/alternative-classification/`, {
    ...values,
    classification: active_classification,
  });

export const chooseTranslation = (code) => (dispatch, getState) => {
  dispatch({ type: 'TRANSLATION_CHOOSEN_FOR_EDIT', data: code });
};

export const openModal = () => (dispatch, getState) => {
  dispatch({ type: 'OPEN_MODAL' });
};

export const closeModal = () => (dispatch, getState) => {
  dispatch({ type: 'CLOSE_MODAL' });
};

export const startEditDetail = () => (dispatch, getState) => {
  dispatch({ type: 'START_EDITING_DETAIL' });
};

export const finishEditDetail = () => (dispatch, getState) => {
  dispatch({ type: 'EDITING_DETAIL_FINISHED' });
};

export const clearClassification = () => (dispatch, getState) => {
  dispatch({ type: 'CLEAR_CLASSIFICATION' });
};

export const setActiveClassification = (id) => (dispatch, getState) => {
  dispatch({ type: 'SET_ACTIVE_CLASSIFICATION', data: id });
};

export const getClassificationDetail = (id) => async (dispatch, getState) => {
  dispatch({ type: 'START_LOADING_CLASSIFICATION_DETAIL' });

  try {
    const { data } = await apiService.get(`/api/v1/classification/${id}/`);

    if (data) {
      dispatch({ type: 'GET_CLASSIFICATION_DETAIL_SUCCESS', data });
    }
  } catch (err) {
    console.log(err);
  }
};
