import React, { useState, useCallback } from 'react';
import { Avatar, Spin } from 'antd';
import { DownOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import Profile from './components/ProfileEditModal';
import * as actions from '../../store/actions/auth';
import './PopoverProfile.css';
import Profiles from './components/Profiles';

function CurrentItem({ title, role, toggleOpen, open }) {
  return (
    <div className="profile-dialog-content">
      <div className="profile-dialog-text">
        <span className="text-email">{role}</span>
        <span className="text-name current">{title}</span>
      </div>
      <div className="profile-dialog-icon" onClick={toggleOpen}>
        {open ? <UpOutlined /> : <DownOutlined />}
      </div>
    </div>
  );
}

const PureProfileDialog = ({ visible, onClose, history }) => {
  const [settings, setSettings] = useState(false);

  const { first_name, last_name, email, image_url } = useSelector((state) => state.auth.user);
  const { employees, current_employee } = useSelector((state) => state.auth);
  const { loadingMenu } = useSelector((state) => state.menu);
  const [open, setOpen] = useState(loadingMenu);

  const dispatch = useDispatch();

  const toggleOpen = () => setOpen(!open);

  const changeSettings = () => setSettings(!settings);

  const findCurrentEmployee = () => employees.find((ele) => ele.id === current_employee);

  useCallback(() => {
    const employee = findCurrentEmployee();
    if (employee && employee !== current_employee) {
      dispatch(actions.currentCompanyChanged(employee.id, employee.company.id));
    }
  }, [current_employee]);

  const renderList = () => {
    let first = {};

    const user = {
      company: {
        title: `${first_name} ${last_name}`,
      },
      role: {
        id: null,
        title: 'Uživatel',
      },
      id: null,
    };

    if (Array.isArray(employees) && employees.length === 0) {
      first = user;
    } else if (current_employee) {
      first = findCurrentEmployee();
    } else {
      first = user;
    }

    return (
      <>
        <CurrentItem
          title={first?.company.title}
          role={first?.role.title}
          open={open}
          toggleOpen={toggleOpen}
        />

        {open && (
          <Profiles employees={[...employees, user]} toggleOpen={toggleOpen} history={history} />
        )}
      </>
    );
  };

  const handleClose = () => {
    if (loadingMenu) return;

    onClose();
  };

  return createPortal(
    <div className={classNames({ 'profile-page': visible })} onClick={handleClose}>
      {loadingMenu && (
        <div className="h-full w-full flex justify-center items-center gap-4">
          <Spin />
          <span>Data se načítají ...</span>
        </div>
      )}
      <div
        className={classNames('profile-dialog', {
          'profile-dialog-show': visible,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="header-container">
          <div className="profile-dialog-avatar">
            <Avatar size={80} src={image_url || <UserOutlined style={{ color: 'black' }} />} />
          </div>
          <div className="profile-dialog-text">
            <span className="text-name">{`${first_name} ${last_name}`}</span>
            <span className="text-email">{`${email}`}</span>
          </div>
        </div>

        {renderList()}

        <div className="signout">
          <span onClick={() => setSettings(true)}>Spravovat profil</span>
          <span onClick={() => dispatch(actions.logout())}>Odhlásit se</span>
        </div>
        <Profile visible={settings} onClose={changeSettings} onSubmit={() => setSettings(false)} />
      </div>
    </div>,
    document.getElementById('root')
  );
};

function ProfilePopover({ history }) {
  const { image_url } = useSelector((state) => state.auth.user);
  const { loadingMenu } = useSelector((state) => state.menu);
  const [visible, setVisible] = useState(loadingMenu);

  const handleProfileDialogVisible = () => {
    if (loadingMenu) return;

    setVisible((visible) => !visible);
  };

  return (
    <>
      <div onClick={handleProfileDialogVisible} className="profile-avatar">
        <Avatar src={image_url || <UserOutlined style={{ color: 'black' }} />} />
      </div>
      <PureProfileDialog history={history} visible={visible} onClose={handleProfileDialogVisible} />
    </>
  );
}

export default ProfilePopover;
