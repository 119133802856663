import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import BaseContainer from '../../containers/BaseContainer';
import Bar from '../../elements/Bar';
import List from '../../elements/List';
import PropTypes from 'prop-types';
import { isArray } from 'underscore';

const ACTION_TYPES = {
  EDIT: 'edit',
  PLUS: 'plus',
};

const SharedListWithBar = ({ data = [], title, actionType = ACTION_TYPES.EDIT, action }) => {
  const permission = useSelector((state) => state.auth.pagePermission);
  return (
    <BaseContainer margin width={'100%'}>
      <Bar
        text={title}
        content={
          permission === 2
            ? actionType === ACTION_TYPES.EDIT
              ? [<EditOutlined key={1} onClick={() => action()} />]
              : [<PlusOutlined key={1} onClick={() => action()} />]
            : []
        }
      />
      {isArray(data) && data.length > 0 && <List data={data} />}
    </BaseContainer>
  );
};

SharedListWithBar.proptypes = {
  actionType: PropTypes.oneOf([ACTION_TYPES.EDIT, ACTION_TYPES.PLUS]),
  title: PropTypes.string,
  editItems: PropTypes.func,
  data: PropTypes.array,
};

export { ACTION_TYPES, SharedListWithBar };
