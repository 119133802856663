import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';
import { SelectField } from '../../../../../shared/SelectField';

import useFormRutine from '../../../../../shared/hooks/formHook';
import { InputField } from '../../../../../shared/InputField';
import Yup from '../../../../../utills/myYup';
import { EDIT_TYPES } from '../../constants';

const ClassificationForm = ({ formProps = {}, submitOutside, submit, error, type }) => {
  const formRef = useRef();

  useFormRutine(formRef, submitOutside, null, null, null, error);

  const init = (props) => {
    if (props) {
      return {
        title: props?.title,
        is_def_component: props?.is_def_component ? 1 : 2,
        is_def_composition: props?.is_def_composition ? 1 : 2,
      };
    }
    return {
      title: null,
      is_def_component: null,
      is_def_composition: null,
    };
  };

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    is_def_component: Yup.number().required('Povinné pole'),
    is_def_composition: Yup.number().required('Povinné pole'),
  });

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form labelCol={{ span: 8 }}>
              {(type === EDIT_TYPES.translations || type === null) && (
                <Form.Item label="Název">
                  <Field name="title" value={values.title || null} component={InputField} />
                </Form.Item>
              )}
              {(type === EDIT_TYPES.detail || type === null) && (
                <>
                  <Form.Item label="Definuje komponentu">
                    <Field
                      name="is_def_component"
                      value={values.is_def_component || null}
                      component={SelectField}
                      data={[
                        { id: 1, title: 'Ano' },
                        { id: 2, title: 'Ne' },
                      ]}
                      onChange={(val) => {
                        setFieldValue('is_def_component', val);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Definuje skladbu">
                    <Field
                      name="is_def_composition"
                      value={values.is_def_composition || null}
                      component={SelectField}
                      data={[
                        { id: 1, title: 'Ano' },
                        { id: 2, title: 'Ne' },
                      ]}
                      onChange={(val) => {
                        setFieldValue('is_def_composition', val);
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ClassificationForm;
