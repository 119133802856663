import React, { useRef, useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import ModalButton from '../../../../../shared/modal/ModalButton';

import ProjectForm from '../forms/ProjectForm';
const ProjectModal = ({ visible, onClose, submitForm, formProps, title }) => {
  const formRef = useRef();
  // const [submit, setSubmit] = useState(false);

  const formSubmit = (id, values) => {
    // setSubmit(false);
    submitForm(id, values);
  };

  const buttons = () => {
    let _buttons = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
      <ModalButton primary key={2} action={() => formRef?.current?.submitForm()}>
        Potvrdit
      </ModalButton>,
    ];

    return _buttons;
  };

  return (
    <Modal title={title} visible={visible} header={[]} onClose={onClose} footer={buttons()}>
      <ProjectForm ref={formRef} formProps={formProps} submit={formSubmit} />
    </Modal>
  );
};

export default ProjectModal;
