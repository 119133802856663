import React, { PureComponent, useState } from 'react';
import { StarOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Select, Tag } from 'antd';
import style from './contractList.module.css';
import { arrayOf, shape, func, bool, string } from 'prop-types';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);
const { Option } = Select;
export default class ContractList extends PureComponent {
  static propTypes = {
    contracts: arrayOf(
      shape({
        id: string,
        title: string,
        is_shared: bool,
        is_favorite: bool,
        is_archived: bool,
        shared_by: shape({
          id: string,
          title: string,
        }),
        phase: shape({
          id: string,
          title: string,
        }),
      })
    ),
    fetchMoreData: func,
    hasMore: bool,
    makeFavorite: func,
    updateContract: func,
  };

  static defaultProps = {
    contracts: [],
    fetchMoreData: () => {},
    makeFavorite: () => {},
    updateContract: () => {},
    hasMore: true,
  };

  render() {
    const { contracts, fetchMoreData, updateContract, makeFavorite } = this.props;
    return (
      <InfiniteScroll
        dataLength={contracts.length}
        next={fetchMoreData}
        hasMore={false}
        loader={<h4>Loading...</h4>}
        height={400}
      >
        <div className={style.container}>
          {contracts.map((item, i) => (
            <ContractItem
              key={i}
              props={item}
              makeFavorite={makeFavorite}
              updateContract={updateContract}
            />
          ))}
        </div>
      </InfiniteScroll>
    );
  }
}

const ContractItem = ({ props: { id, title, phase, is_favorite } = {}, makeFavorite }) => {
  const [isFavorite, setIsFavorite] = useState(is_favorite);

  const handleMakeFavorite = () => {
    setIsFavorite(!isFavorite);
    makeFavorite(id);
  };

  return (
    <div className={style.listItem}>
      <span className={style.left}>
        <span className={style.item}>{`${title} ( ${phase} )`}</span>
      </span>
      <span>
        <span className={cx({ star: true, active: isFavorite })}>
          <StarOutlined onClick={() => handleMakeFavorite()} />
        </span>
      </span>
    </div>
  );
};
