import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import classNames from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NewClassificationTree from '../../components/classification/newClassification';
import Filter from '../../components/filter/Filter';
import BaseContainer from '../../containers/BaseContainer';
import Bar from '../../elements/Bar';
import { apiService } from '../../utills/api';

const fetchData = async (url, params) =>
  (await apiService.get(url, { params: { ...params } })).data?.results;

export default function PanelWithClassification({
  makeActive,
  active,
  addItem,
  onFilter,
  title = null,
  filteredValues = {
    is_active: [
      { value: 0, title: 'Aktivní' },
      { value: 1, title: 'Neaktivní' },
    ],
  },
  onSearch,
  valueAsObject = false,
  url,
  disableAdd = false,
  width = '420px',
  className,
}) {
  const [visibleFilter, setVisibleFilter] = useState(false);
  const permission = useSelector((state) => state.auth.pagePermission);
  const [classificationItems, setClassificationItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterValues, setFilterValues] = useState(null);

  const _url = `/api/v1/classification/${url}`;
  const PAGE_SIZE = 11;

  const debounceSearch = debounce((e) => {
    handleSearch(e);
  }, 500);

  useEffect(() => {
    fetchData(_url)
      .then((data) => {
        setClassificationItems(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    fetchData(_url, { search: e.target.value, ...filterValues })
      .then((data) => {
        setClassificationItems(data);
        onSearch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleFilter = (filterValues) => {
    fetchData(_url, { search: searchTerm, ...filterValues })
      .then((data) => {
        setClassificationItems(data);
        onSearch();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateItemInTree = (node, id, nextItems) => {
    if (node.id === id) {
      node.children = node.children.concat(nextItems);
    }

    if (node.children !== undefined && node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        node.children[i] = updateItemInTree(node.children[i], id, nextItems);
      }
    }

    return node;
  };

  const iterateOverRootOfTree = (tree, id, dataForUpdate) => {
    let result = [];
    if (Array.isArray(tree) && tree.length > 0) {
      for (let i = 0; i <= tree.length - 1; i++) {
        result = result.concat(updateItemInTree(tree[i], id, dataForUpdate));
      }
    }

    return result;
  };

  const updateTree = (parentId, data) => {
    const updatedTree = iterateOverRootOfTree(classificationItems, parentId, data);

    setClassificationItems(updatedTree);
  };

  const getClassificationChildren = (parent, _url) => {
    const startFetchRutine = async (next) => {
      if (!next) return null;
      try {
        const { data } = await apiService.get(_url, {
          params: { parent, page: next, page_size: PAGE_SIZE },
        });

        if (data) {
          updateTree(parent, data?.results);
          startFetchRutine(data?.next);
        }
      } catch (err) {
        console.log(err);
      }
    };
    startFetchRutine(1);
  };

  const itemTree = (item) => {
    if (!item) return null;
    if (item.children.length === 0) {
      return (
        <NewClassificationTree
          key={item.id}
          content={item.title}
          active={item.id === active}
          reference={item.reference}
          onExpand={() => {
            getClassificationChildren(item.id, _url);
          }}
          onClick={() => {
            if (valueAsObject) {
              makeActive(item);
            } else {
              makeActive(item.id);
            }
          }}
          visible
          has_children={item.has_children}
        />
      );
    }
    return (
      <NewClassificationTree
        key={item.id}
        content={item.title}
        status={item.status}
        active={item.id === active}
        onClick={() => {
          if (valueAsObject) {
            makeActive(item);
          } else {
            makeActive(item.id);
          }
        }}
        onExpand={() => {}}
        reference={item.reference}
        canHide
        visible
        has_children={item.has_children}
      >
        {item.children.map((child) => itemTree(child))}
      </NewClassificationTree>
    );
  };

  const makeTree = (data) => (data || []).map((ele) => itemTree(ele));

  const content = () => {
    const items = [
      <Filter
        key={1}
        filteredValues={filteredValues}
        visible={visibleFilter}
        onFilter={handleFilter}
        onClose={() => setVisibleFilter(false)}
      >
        <FilterOutlined onClick={() => setVisibleFilter(true)} />
      </Filter>,
    ];
    if (permission === 2 && !disableAdd) {
      {
        active && items.push(<PlusOutlined key={2} onClick={addItem} />);
      }
    }
    return items;
  };

  return (
    <div>
      <BaseContainer width={width} margin>
        {title && <Bar text={title} content={content()} />}
        <Search placeholder="Vyhledat" onChange={debounceSearch} />
        <div className={classNames('overflow-y-scroll h-[calc(100vh-163px)]', className)}>
          {makeTree(classificationItems)}
        </div>
      </BaseContainer>
    </div>
  );
}

PanelWithClassification.propTypes = {
  data: PropTypes.array,
  makeActive: PropTypes.func,
  active: PropTypes.string,
  addItem: PropTypes.func,
  onFilter: PropTypes.func,
  title: PropTypes.string,
  onSearch: PropTypes.func,
  filteredValues: PropTypes.object,
  disableAdd: PropTypes.bool,
};
