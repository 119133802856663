import { useQuery } from 'react-query';
import { apiService } from '../../../utills/api';

const useGetFilter = (id) => {
  return useQuery({
    queryKey: ['filter', id],
    enabled: !!id,
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/filter/${id}/`);
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetFilter;
