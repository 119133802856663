import React, { useEffect, useState } from 'react';
import Search from 'antd/lib/input/Search';
import ContractList from './ContractList';
import { toFavorite, updateContract } from '../../../services/contractPickerService';
import { Spin } from 'antd';

const ManageContracts = ({ service }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        let result = (await service()).data.results;
        setData(result);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
    setLoading(false);
  }, [service]);

  const handleSearchContracts = async (e) => {
    try {
      let result = (await service(null, e.target.value)).data.results;
      setData(result);
    } catch (e) {
      console.log(e);
    }
  };

  const handleMakeFavorite = async (id) => {
    try {
      await toFavorite(id);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateContract = async (id, values) => {
    try {
      await updateContract(id, values);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <Search placeholder="Vyhledat akce" onChange={(e) => handleSearchContracts(e)} />
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            minHeight: '300px',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <ContractList
          contracts={data}
          makeFavorite={handleMakeFavorite}
          updateContract={handleUpdateContract}
        />
      )}
    </div>
  );
};

export default ManageContracts;
