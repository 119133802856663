import { Suspense, React, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProjectInformationModal from './components/ProjectInformationModal';
import ProjectInformationView from './ProjectInformationView';
import { apiService } from '../../../utills/api';
import { SearchContext } from '../building/Building';
import MainContainer from '../../../containers/MainContainer';
import NLeftPanel from '../../../shared/components/NLeftPanel';
import { useQueryClient } from 'react-query';

function ProjectInformation() {
  const { contractId, modelId } = useSelector((state) => state.contract);
  const permission = useSelector((state) => state.auth.pagePermission);
  const { menuId } = useSelector((state) => state.consist);
  const [openDialog, setOpenDialog] = useState(false);
  const [detail, setDetail] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({});
  const queryClient = useQueryClient();
  const queryKey = 'building-object';

  const getDetail = async (id) => {
    setActiveItem(id);
    try {
      const data = (
        await apiService.get(`/api/v1/building-object/${id}/project-information`, {
          params: {
            model: modelId,
            contract: contractId,
            menu: menuId,
          },
        })
      ).data;
      setDetail(data);
    } catch {
      //
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries(queryKey);
    setDetail(null);
  }, [contractId]);

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
  };

  const onFilter = async (value) => {
    setFilter(value);
  };

  const handleSubmit = async (values) => {
    try {
      const data = (
        await apiService.patch(`/api/v1/project-information/${detail.id}/`, { ...values })
      ).data;

      setDetail(data);
      setOpenDialog(false);
    } catch {
      //
    }
  };

  const handleEdit = () => {
    setOpenDialog(true);
  };

  return (
    <div className="w-full">
      <SearchContext.Provider
        value={{
          search,
          filter,
          setSearch,
          setFilter,
        }}
      >
        <MainContainer>
          <div className="flex">
            <div className="border-l-2 h-full border-neutral-200">
              <NLeftPanel
                queryKey={queryKey}
                makeActive={getDetail}
                active={activeItem}
                onSearch={onSearch}
                addItem={() => setOpenDialog(true)}
                onFilter={onFilter}
                defaultFilterValues={{ is_active: [true] }}
                title="Projektové informace"
                permission={permission}
                disableAdd={true}
                filteredValues={{
                  is_active: [
                    { value: true, title: 'Aktivni' },
                    { value: false, title: 'Neaktivni' },
                  ],
                }}
              />
            </div>

            <ProjectInformationView data={detail} makeEditable={handleEdit} />
          </div>
        </MainContainer>
      </SearchContext.Provider>
      {openDialog && (
        <Suspense fallback={<div>Loading...</div>}>
          <ProjectInformationModal
            visible={openDialog}
            title={'Projektové informace'}
            formProps={detail}
            onClose={() => setOpenDialog(false)}
            onSubmit={handleSubmit}
          />
        </Suspense>
      )}
    </div>
  );
}

export default ProjectInformation;
