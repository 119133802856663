import React from 'react';
import { Form, DatePicker } from 'antd';

const FormItem = Form.Item;

export const DatePickerField = ({ field, form: { touched, errors }, ...props }) => {
  const errorMsg = errors[field.name];

  return (
    <FormItem
      label={props.label}
      help={errorMsg}
      hasFeedback={!!errors[field.name]}
      validateStatus={errors[field.name] && 'error'}
    >
      <DatePicker {...field} {...props} />
    </FormItem>
  );
};
