import React, { useState } from 'react';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import EtapeForm from '../forms/EtapeForm';
import { copyEtape } from '../../../../../services/projectService';

const EtapeModal = ({ visible, onClose, data, submitForm, formProps, error, title }) => {
  const [submit, setSubmit] = useState(false);

  const formSubmit = (values) => {
    setSubmit(false);
    submitForm(values);
  };

  const buttons = () => {
    let _buttons = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
    ];

    if (formProps?.is_owned_by_my_company) {
      _buttons.push(
        <ModalButton
          key={1}
          action={async () => {
            try {
              await copyEtape(formProps?.id);
              onClose({ withReset: true });
            } catch (e) {
              NotificationManager.error('Chyba', error?.response?.data?.error || '');
            }
          }}
        >
          Vytvořit kopii
        </ModalButton>
      );
      _buttons.push(
        <ModalButton primary={true} key={1} action={() => setSubmit(true)}>
          Potvrdit
        </ModalButton>
      );
    } else {
      _buttons.push(
        <ModalButton
          key={1}
          action={async () => {
            try {
              await copyEtape(formProps?.id);
            } catch (e) {
              NotificationManager.error('Chyba', error?.response?.data?.error || '');
            }
          }}
        >
          Požádat o novou fázi
        </ModalButton>
      );
    }

    return _buttons;
  };

  return (
    <Modal title={title} visible={visible} header={[]} onClose={onClose} footer={buttons()}>
      <EtapeForm formProps={formProps} submit={formSubmit} submitOutside={submit} />
    </Modal>
  );
};

export default EtapeModal;
