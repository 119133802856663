import React from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../../utills/api';

const varifyUser = async (current, token) => {
  try {
    await apiService.post(`/api/v1/company/${current}/invite-employee-verify/`, {
      token: token,
    });
  } catch (e) {
    //
  }
};

const ConfirmUser = (props) => {
  const token = props.match.params.token;
  const current = useSelector((state) => state.auth.current_company);
  const _onClick = () => {
    varifyUser(current, token);
    props.history.push('/');
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <span style={{ color: 'blue', cursor: 'pointer' }} onClick={_onClick}>
        Potvrzuji
      </span>
    </div>
  );
};

export default ConfirmUser;
