import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeCurrentCompany, profileSuccess } from '../../../store/actions/auth';
import { apiService } from '../../../utills/api';
import EditCompanyModal from './components/modals/EditCompanyModal';

function CreateCompany({ history }) {
  const dispatch = useDispatch();
  const [error, setError] = useState();

  const submitEditForm = async (data) => {
    try {
      const response = await apiService.post('/api/v1/company/', data);
      if (response) {
        // dispatch(loadProfile());
        const profiles = (await apiService.get(`/api/v1/user/employee-profiles/`)).data;

        dispatch(profileSuccess(profiles));
        dispatch(
          changeCurrentCompany(
            response.data.current_employee?.id,
            response.data.current_employee.company?.id
          )
        );
        history.push('/');
      }
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div>
      <EditCompanyModal
        title="Založit firmu"
        visible
        error={error}
        onClose={() => {
          history.push('/');
        }}
        submitEditForm={submitEditForm}
      />
    </div>
  );
}

export default CreateCompany;
