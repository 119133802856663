import React, { useState, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PlusOutlined } from '@ant-design/icons';
import Modal from '../../../../../shared/modal/Modal';
import Yup from '../../../../../utills/myYup';
import { InputField } from '../../../../../shared/InputField';
import Bar from '../../../../../elements/Bar';
import PickValueWithClassificationModal from '../../../../../shared/modals/PickValueWithClassificationModal';
import Label from '../../../../Label';
import FormList from '../../../../../elements/FormList';
import ModalButton from '../../../../../shared/modal/ModalButton';

function TechnicalReportItemSetModal({ visible, title, formProps, onSubmit, onClose }) {
  const [visibleAddTechnicalReportItemDialog, setVisibleAddTechnicalReportItemDialog] =
    useState(false);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const handleDeleteTechnicalReportItem = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.technical_report_item_set.filter(
      (ele) => ele.id !== id
    );

    formRef.current.setFieldValue('technical_report_item_set', newValues);

    return null;
  };

  const handleAddTechnicalReportItem = (item) => {
    formRef.current.setFieldValue('technical_report_item_set', [
      ...formRef.current.values.technical_report_item_set,
      item,
    ]);

    setVisibleAddTechnicalReportItemDialog(false);
  };

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();

    return null;
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          technical_report_item_set: formProps?.technical_report_item_set || [],
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, {
            ...values,
            technical_report_item_set: values.technical_report_item_set.map(
              (technical_report_item) => technical_report_item.id
            ),
          });
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                <div className="mb-1">
                  <Bar
                    text="Položky technické zprávy"
                    content={[
                      <PlusOutlined
                        key={1}
                        onClick={() => setVisibleAddTechnicalReportItemDialog(true)}
                      />,
                    ]}
                  />
                  <FormList
                    data={values.technical_report_item_set}
                    onDelete={handleDeleteTechnicalReportItem}
                  />
                </div>
              </Form>
              <PickValueWithClassificationModal
                visible={visibleAddTechnicalReportItemDialog}
                url="left-panel-technical-report-item"
                pickerUrl="technical-report-item/left-panel-picker/"
                onClose={() => setVisibleAddTechnicalReportItemDialog(false)}
                onSubmit={handleAddTechnicalReportItem}
              />
            </>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default TechnicalReportItemSetModal;
