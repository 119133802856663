import classNames from 'classnames';
import Modal from '../../../../shared/modal/Modal';
import SolutionDetailView from './SolutionDetailView';
import { useState } from 'react';
import { apiService } from '../../../../utills/api';
import ModalButton from '../../../../shared/modal/ModalButton';

const fetchSolutionDetail = async (id) => {
  return (await apiService.get(`/api/v1/solution/${id}/last-version/`)).data;
};

const SolutionGallery = ({ solutions, selected, onSelect }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [solutionPreview, setSolutionPreview] = useState(null);

  const showSolutionPreview = async (id) => {
    if (!id) return;

    const solution = await fetchSolutionDetail(id);
    setSolutionPreview(solution);
    setShowPreview(true);
  };

  return (
    <>
      <div className="h-auto flex flex-wrap gap-1 text-center">
        {(solutions || []).map((solution) => (
          <div
            key={solution.id}
            className={classNames('w-[200px] bg-color4 p-2', {
              'border border-color7': selected === solution.id,
            })}
          >
            <div className="bg-color3 h-[150px]">
              {solution?.image_url && (
                <img className="object-cover h-full w-full" src={solution.image_url} />
              )}
            </div>
            <span className="uppercase text-xs">{solution.title}</span>
            <div className="text-xs uppercase flex justify-between">
              <button
                className="px-2 py-1 hover:bg-color7 hover:text-white"
                onClick={() => showSolutionPreview(solution.id_detail)}
              >
                Náhled
              </button>
              {selected === solution.id ? (
                <button
                  className="px-2 py-1 hover:bg-color7 hover:text-white"
                  onClick={() => onSelect(null)}
                >
                  Zrušit
                </button>
              ) : (
                <button
                  className="px-2 py-1 hover:bg-color7 hover:text-white"
                  onClick={() => onSelect(solution.id)}
                >
                  Zvolit
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <Modal
        title={'Preview'}
        visible={showPreview}
        footer={[
          <ModalButton key={1} action={() => setShowPreview(false)}>
            Zavřít
          </ModalButton>,
        ]}
      >
        <SolutionDetailView data={solutionPreview} editDisable />
      </Modal>
    </>
  );
};

export default SolutionGallery;
