import classNames from 'classnames/bind';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import style from './modal.module.css';

let cx = classNames.bind(style);

export default class Modal extends Component {
  list = (menu, styles) => {
    return (
      <span className={styles.menu}>
        {menu.map((item, i) => (
          <span key={i} className={styles.menuItem}>
            {item}
          </span>
        ))}
      </span>
    );
  };

  render() {
    const {
      title,
      header = [],
      children,
      visible = false,
      footer = [],
      className = '',
    } = this.props;

    if (!visible) return null;

    return ReactDOM.createPortal(
      <div>
        <div className={style.page}>
          <div
            id="modal"
            className={cx(
              'flex flex-col max-h-screen min-w-[550px] min-h-[400px] bg-white',
              className
            )}
          >
            <div id="header" className={cx('shrink-0', style.header)}>
              <span className={style.title}>{title || ''}</span>
              {this.list(header, {
                menu: style.menu,
                menuItem: style.menuItem,
              })}
            </div>
            <div id="content" className={cx('grow overflow-y-scroll', style.content)}>
              {children}
            </div>
            <div id="footer" className={cx('shrink-0', style.footer)}>
              {this.list(footer, { menu: '', menuItem: style.footerItem })}
            </div>
          </div>
        </div>
      </div>,
      document.getElementById('root')
    );
  }
}
