import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import { ACTION_TYPES, SharedListWithBar } from '../../../../shared/components/SharedListWithBar';
import { addCulture } from '../../../../store/actions/units';
import { setActiveCulture } from '../../../../store/actions/culture';
import List from '../../../../elements/List';

const CultureModal = React.lazy(() => import('./modals/CultureModal'));
const UnitCultureModal = React.lazy(() => import('./modals/UnitCultureModal'));

const prepareDataCulture = (data) => {
  if (!data) return [];
  let { biblio, title, mark, description } = data;
  return [
    { name: 'Biblio', value: biblio?.title },
    { name: 'Název', value: title },
    { name: 'Značka', value: mark },
    { name: 'Popisek', value: description },
  ];
};

const renderListOfCultures = (data, editItems) => {
  return (data || []).map((culture, i) => {
    let properties = prepareDataCulture(culture);
    return (
      <SharedListWithBar
        key={i}
        title={culture?.culture?.title}
        data={properties}
        actionType={ACTION_TYPES.EDIT}
        action={() => editItems(culture?.culture?.id)}
      />
    );
  });
};

export default function UnitsDetailView({ data, editUnit }) {
  const [cultureVisible, setCultureVisible] = useState(false);
  const [actualCultureVisible, setActualCultureVisible] = useState(false);
  const [actualCultureForEdit, setActualCultureForEdit] = useState(null);
  const { permission } = useSelector((state) => state.auth);
  const { active_culture } = useSelector((state) => state.culture);
  const dispatch = useDispatch();

  const submitNewCulture = (values) => {
    dispatch(addCulture(values));
    setCultureVisible(false);
  };

  const editItems = (id) => {
    setActualCultureVisible(true);
    dispatch(setActiveCulture(id));
  };

  // const getActualCulture = data.find(
  //   (ele) => ele?.culture?.id === active_culture
  // );

  return (
    <div>
      <Bar
        text="Jednotka"
        content={permission === 2 ? [<EditOutlined key={1} onClick={() => editUnit()} />] : []}
      />
      <List data={prepareDataCulture(data)} />

      {/* <UnitCultureModal
        visible={actualCultureVisible}
        title={getActualCulture?.culture?.title}
        onClose={() => setActualCultureVisible(false)}
      /> */}
    </div>
  );
}
