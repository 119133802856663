import { Suspense, React } from 'react';
import { useSelector } from 'react-redux';
import ProjectModal from './components/modals/ProjectModal';
import ProjectDetailView from './components/ProjectDetailView';
import withLeftPanelWrapper from '../../../utills/hoc/leftPanelWithDetailWrapper';

function Project({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) {
  const { detail } = useSelector((state) => state.contractListing);

  return (
    <div className="w-full">
      {openDialog && (
        <Suspense fallback={<div>Loading...</div>}>
          <ProjectModal
            submitForm={handleSubmit}
            title={isEdit ? 'Editovat akci' : 'Vytvořit akci'}
            visible={openDialog}
            formProps={isEdit ? detail : undefined}
            onClose={handleClose}
          />
        </Suspense>
      )}
      <ProjectDetailView data={detail} makeEditable={handleEdit} />
    </div>
  );
}

export default withLeftPanelWrapper(Project, 'contractListing');
