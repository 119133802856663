import React, { useState } from 'react';

import BuildingModelForm from '../forms/BuildingModelForm';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';

const PhaseModal = ({ visible, onClose, submitForm, formProps, title }) => {
  const [submit, setSubmit] = useState(false);

  const formSubmit = (id, values) => {
    setSubmit(false);
    submitForm(id, values);
  };

  const buttons = () => {
    let _buttons = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
      <ModalButton primary key={2} action={() => setSubmit(true)}>
        Potvrdit
      </ModalButton>,
    ];

    return _buttons;
  };

  return (
    <Modal title={title} visible={visible} header={[]} onClose={onClose} footer={buttons()}>
      <BuildingModelForm formProps={formProps} submit={formSubmit} submitOutside={submit} />
    </Modal>
  );
};

export default PhaseModal;
