import { useQuery } from 'react-query';
import { apiService } from '../../../utills/api';

const useGetInheritedValues = (documentationId, documentId) => {
  return useQuery({
    queryKey: ['api/v1/document/inherited/', documentationId, documentId],
    enabled: !!documentationId,
    queryFn: async () => {
      const { data } = await apiService.get(`api/v1/document/inherited/`, {
        params: {
          documentation: documentationId,
          parent: documentId,
        },
      });
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

export default useGetInheritedValues;
