import classNames from 'classnames';
import React from 'react';
import styles from './list.module.css';

const ActionList = ({ data, actions = [], selected, onSelect }) => {
  if (!data) return null;

  return (
    <ul className={classNames(`h-[320px] overflow-y-scroll`)}>
      {data.map(
        (ele) =>
          ele && (
            <li
              key={ele.id}
              className={classNames(
                'flex py-[5px] px-2 text-sm text-neutral-700 border-b cursor-pointer',
                {
                  'bg-color7 text-white': selected?.id === ele.id,
                }
              )}
              onClick={() => onSelect(ele)}
            >
              <span className="">{ele.title || ''}</span>
              <span className="">
                {actions.map((ele, i) => (
                  <div key={i} className={styles.actionIcon}>
                    {ele}
                  </div>
                ))}
              </span>
            </li>
          )
      )}
    </ul>
  );
};

export default ActionList;
