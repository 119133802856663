import React, { useEffect, useState } from 'react';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import UnitsForm from '../forms/UnitsForm';
import { useDispatch, useSelector } from 'react-redux';
import { getCultures } from '../../../../../store/actions/culture';

const UnitsModal = ({
  visible,
  onClose,
  submitForm,
  formProps,
  error,
  title,
  isActiveForm,
  isEditCultureMutations = false,
}) => {
  const [submit, setSubmit] = useState(false);
  const { cultures } = useSelector((state) => state.culture);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;

    if (mounted && cultures && !cultures.length > 0) {
      dispatch(getCultures());
    }
    return () => {
      mounted = false;
    };
  }, [cultures]);

  const formSubmit = (values) => {
    submitForm(values);
    setSubmit(false);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton primary key={2} action={() => setSubmit(true)}>
          Uložit
        </ModalButton>,
      ]}
    >
      <UnitsForm
        submitOutside={submit}
        submit={formSubmit}
        formProps={formProps}
        cultures={cultures}
        resetConfirm={() => {}}
        isActiveForm={isActiveForm}
        isEditCultureMutations={isEditCultureMutations}
        reset={null}
        error={error}
      />
    </Modal>
  );
};

export default UnitsModal;
