import React, { useState } from 'react';
import propTypes from 'prop-types';
import ModalButton from '../../../../../shared/modal/ModalButton';
import Modal from '../../../../../shared/modal/Modal';
import { useSelector } from 'react-redux';
import ClassificationForm from '../forms/ClassificationForm';
import { EDIT_TYPES } from '../../constants';

const ClassificationModal = ({ visible, onClose, submitForm, error, title }) => {
  const [submit, setSubmit] = useState(false);
  const { detail, translations, edit, choosen_translation } = useSelector(
    (state) => state.classification
  );

  const formSubmit = (values) => {
    submitForm(values);
    setSubmit(false);
  };

  const getChoosenTranslationData = () => {
    return translations.find((trans) => trans?.language?.code === choosen_translation);
  };

  const setDataToForm = () => {
    if (edit) {
      if (edit === EDIT_TYPES.detail) {
        return detail;
      }
      return getChoosenTranslationData();
    }
    return null;
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton primary key={2} action={() => setSubmit(true)}>
          Uložit
        </ModalButton>,
      ]}
    >
      <ClassificationForm
        submitOutside={submit}
        submit={formSubmit}
        formProps={setDataToForm()}
        type={edit}
        error={error}
        data={[]}
      />
    </Modal>
  );
};

ClassificationModal.propTypes = {
  visible: propTypes.bool,
  onClose: propTypes.func,
  submitForm: propTypes.func,
  formProps: propTypes.any,
  error: propTypes.any,
  title: propTypes.string,
};

export default ClassificationModal;
