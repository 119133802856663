import { Checkbox, Divider, Row } from 'antd';
import React, { useContext } from 'react';
import style from './filter.module.css';
import { SearchContext } from '../project/building/Building';

const prepareCheckBoxes = (key, values, filter, _onChange) => {
  return (
    <Checkbox.Group
      style={{ width: '100%' }}
      onChange={(value) => _onChange(key, value)}
      value={filter[key]}
    >
      {(values || []).map((ele, i) => (
        <Row key={i}>
          <Checkbox value={ele.value}>{ele.title}</Checkbox>
        </Row>
      ))}
    </Checkbox.Group>
  );
};

const mapPropsToSelectedKeys = (filteredValues, value) => {
  const keys = Object.keys(filteredValues);

  if (Array.isArray(keys) && keys.length > 0) {
    let result = {};
    for (let i = 0; i < keys.length; i++) {
      result[keys[i]] = value[keys[i]] ?? [];
    }
    return result;
  }
  return [];
};

function Filter({ visible, filteredValues = {}, children, onClose, onFilter }) {
  const { filter, setFilter } = useContext(SearchContext) || {};
  const selected = mapPropsToSelectedKeys(filteredValues || {}, filter || {});

  const _onChange = (key, values) => {
    setFilter((filter) => ({ ...filter, [key]: values }));
  };

  const _onFilter = () => {
    onFilter(selected);
  };

  const prepareData = () => {
    let result = [];

    if (Object.keys(filteredValues).length > 0) {
      for (const [key, value] of Object.entries(filteredValues)) {
        result.push(prepareCheckBoxes(key, value, selected, _onChange));
      }

      result = result.reduce((prev, curr) => (
        <>
          {prev}
          <Divider type="horizontal" />
          {curr}
        </>
      ));

      return result;
    }
    return null;
  };

  if (visible) {
    return (
      <>
        {children}
        <div className={style.filter} onClick={(e) => e.stopPropagation()}>
          <div className={style.content}>{prepareData()}</div>
          <div className={style.action} onClick={_onFilter}>
            <span>Filtrovat</span>
          </div>
        </div>
        <div className={style.cover} onClick={onClose}></div>
      </>
    );
  }
  return children;
}

export default Filter;
