import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';

export default function TwoColumnEditableList({ addItem, editItem, removeItem }) {
  const { detail } = useSelector((state) => state.classification);
  const permission = useSelector((state) => state.auth.pagePermission);

  const getDataForAlternativeClassList = () => {
    return (detail?.alternative_classification_set || []).map((ele) => ({
      id: ele.id,
      name: ele?.classification_system?.shortcut,
      value: ele.code,
    }));
  };

  const editButton = (id) => <EditOutlined key={1} onClick={() => editItem(id)} />;
  const deleteButton = (id) => (
    <Popconfirm
      placement="left"
      title="Opravdu chcete odstranit položku?"
      onConfirm={() => removeItem(id)}
      okText="Ano"
      cancelText="Ne"
      icon={null}
    >
      <MinusOutlined key={1} />
    </Popconfirm>
  );

  return (
    <BaseContainer margin width={'100%'}>
      <Bar
        text={'Alternativní zatřídění'}
        content={permission === 2 ? [<PlusOutlined key={1} onClick={() => addItem()} />] : []}
      />
      <List data={getDataForAlternativeClassList()} actions={[editButton, deleteButton]} />
    </BaseContainer>
  );
}
