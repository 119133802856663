import { useQuery } from 'react-query';
import { apiService } from '../../../utills/api';

const useGetDocumentationNotifications = (documentationId) => {
  return useQuery({
    queryKey: ['notifications', documentationId],
    enabled: !!documentationId,
    queryFn: async () => {
      const { data } = await apiService.get(
        `/api/v1/documentation/${documentationId}/notifications`
      );
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

const useGetSpecificNotifications = (ids) => {
  return useQuery({
    queryKey: 'specific-notifications',
    enabled: ids?.length > 0,
    queryFn: async () => {
      const { data } = await apiService.get(`/api/v1/task/`, {
        params: {
          notificationId: ids?.join(','),
        },
      });

      return data;
    },
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
  });
};

export { useGetDocumentationNotifications, useGetSpecificNotifications };
