import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MainContainer from '../../../containers/MainContainer';
import LeftPanel from '../../../shared/components/LeftPanel';
import { apiService } from '../../../utills/api';
import SharedModlalToAddItemFromList from '../../../shared/modals/SharedModlalToAddItemFromList';
import TeamsModal from './components/modals/TeamsModal';
import TeamsDetailView from './components/TeamsDetailView';

export default function TeamsController({ history }) {
  const current = useSelector((state) => state.auth.current_company);
  const permission = useSelector((state) => state.auth.pagePermission);
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [active, setActive] = useState(null);
  const [visible, setVisible] = useState(false);
  const [addEmployeesVisible, setAddEmployeesVisible] = useState(false);
  const [edit, setEdit] = useState(null);
  const [error, setError] = useState(null);
  const [nextTeams, setNextTeams] = useState(null);
  const [nextEmployees, setNextEmployees] = useState(null);
  const [searchInTeams, setSearchInTeams] = useState(null);
  const [searchInEmployees, setSearchInEmployees] = useState(null);
  const [filter, setFilter] = useState({});

  const getTeams = async (nextProps, search, filter) => {
    return await apiService.get(`/api/v1/current-company/teams/`, {
      params: {
        page: nextProps,
        search: search,
        page_size: 40,
        ...filter,
      },
    });
  };

  const getTeam = async (id) => await apiService.get(`/api/v1/company-team/${id}/`);

  const getEmployees = async (nextProps, search) =>
    await apiService.get(`/api/v1/current-company/employees/`, {
      params: {
        page: nextProps,
        search: search,
        page_size: 20,
      },
    });

  const fetchData = async () => {
    if (current) {
      let { data: result } = await getTeams(1, null, filter);

      setTeams(result?.results || []);
      setNextTeams(result?.next);

      if (result) {
        let { id } = getFirst(result?.results);
        if (id) {
          let [{ data: activeTeam }, { data: employees }] = await axios.all([
            getTeam(id),
            getEmployees(),
          ]);

          setEmployees(employees?.results);

          setActive(id);
          setCurrentTeam(activeTeam);
        } else {
          setCurrentTeam(null);
        }
      }
    }
  };

  useEffect(() => {
    if (!current) {
      history.push('/');
    }
    fetchData();
  }, [current]);

  const makeActive = async (id) => {
    if (id) {
      setActive(id);
      let { data: result } = await apiService.get(`/api/v1/company-team/${id}/`);
      setCurrentTeam(result);
    }
  };

  const makeEditable = () => {
    setEdit(true);
    setVisible(true);
  };

  const onClose = () => {
    setEdit(false);
    setVisible(false);
  };

  const submit = async (id, data) => {
    console.log({ data });
    if (id) {
      try {
        let { data: result } = await apiService.patch(`/api/v1/company-team/${id}/`, {
          ...data,
          company: current,
        });
        if (result) {
          let index = teams.findIndex((ele) => ele.id === id);
          let newR = [...teams];
          newR[index] = result;
          setTeams(newR);
          setCurrentTeam(result);
          setVisible(false);
          setEdit(false);
        }
      } catch (e) {
        setError(e);
      }
    } else {
      try {
        let { data: result } = await apiService.post(`/api/v1/company-team/`, {
          ...data,
          company: current,
        });
        if (result) {
          setTeams([...teams, result]);
        }

        setEdit(false);
        setVisible(false);
      } catch (e) {
        setError(e);
      }
    }
  };

  const editEmployees = async (data, action) => {
    let { data: result } = await apiService.post(
      `/api/v1/company-team/${currentTeam.id}/${action}/`,
      {
        members: [data],
      }
    );
    if (result) {
      setCurrentTeam(result);
      setAddEmployeesVisible(false);
    }
  };

  const fetchMoreTeams = async () => {
    if (nextTeams !== null) {
      try {
        let { data } = await getTeams(nextTeams, searchInTeams, filter);

        if (data) {
          setTeams(teams.concat(data?.results || []));
          setNextTeams(data?.next);
        }
      } catch (e) {
        //   setError(e);
      }
    }
  };

  const fetchMoreEmployees = async () => {
    if (nextEmployees !== null) {
      try {
        let { data } = await getEmployees(nextTeams, searchInEmployees);

        if (data) {
          setEmployees(employees.concat(data?.results || []));
          setNextEmployees(data?.next);
        }
      } catch (e) {
        //   setError(e);
      }
    }
  };

  const onSearchTeams = async (e) => {
    setSearchInTeams(e?.target?.value);
    try {
      let { data } = await getTeams(1, e?.target?.value, filter);
      if (data) {
        setTeams(data?.results || []);
        setNextTeams(data?.next);
      }
    } catch (e) {
      //
    }
  };

  const onSearchEmployees = async (e) => {
    setSearchInEmployees(e?.target?.value);
    try {
      let { data } = await getEmployees(1, e?.target?.value);
      if (data) {
        setEmployees(data?.results || []);
        setNextEmployees(data?.next);
      }
    } catch (e) {
      //
    }
  };

  const onFilter = async (filterValues) => {
    setFilter(filterValues);
    try {
      let { data } = await getTeams(1, searchInTeams, filterValues);
      if (data) {
        setTeams(data?.results || []);
        setNextTeams(data?.next);
      }
    } catch (e) {
      //
    }
  };

  const getFirst = (data) => (data?.length > 0 ? data[0] : {});

  return (
    <MainContainer>
      <LeftPanel
        data={teams}
        addItem={() => setVisible(true)}
        title={'Týmy ve firmě'}
        onSearch={onSearchTeams}
        onFilter={onFilter}
        fetchMore={fetchMoreTeams}
        hasMore={nextTeams !== null ? true : false}
        permission={permission}
        filteredValues={{
          is_active: [
            { value: true, title: 'Aktivní' },
            { value: false, title: 'Neaktivní' },
          ],
        }}
        submit={submit}
        makeActive={makeActive}
        active={active}
      />
      {Array.isArray(teams) && teams.length > 0 && (
        <TeamsDetailView
          data={currentTeam}
          makeEditable={makeEditable}
          addEmployee={() => setAddEmployeesVisible(true)}
          removeEmployee={(data) => editEmployees(data, 'remove_from')}
          permission={permission}
        />
      )}
      <TeamsModal
        onClose={onClose}
        data={edit && currentTeam}
        error={error}
        title={edit ? 'Editace týmu' : 'Vytvořit tým'}
        visible={visible}
        submitEditForm={submit}
      />
      <SharedModlalToAddItemFromList
        data={employees}
        title="Přidat lidi"
        visible={addEmployeesVisible}
        onSearch={onSearchEmployees}
        hasMore={nextEmployees !== null ? true : false}
        fetchMore={fetchMoreEmployees}
        onClose={() => setAddEmployeesVisible(false)}
        submit={(data) => editEmployees(data, 'add_to')}
      />
    </MainContainer>
  );
}
