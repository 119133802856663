import {
  GET_TYPE_MARK_DETAIL_SUCCESS,
  GET_TYPE_MARKS_SUCCESS,
  GET_TYPE_MARK_GEO_SUCCESS,
  CLEAR_TYPE_MARKS,
  ROUTE_ID_ADDED,
  GET_TYPE_MARK_INSTANCES,
  GET_TYPE_MARK_UPDATE_GEO_SUCCESS,
  GET_TYPE_MARK_DETAIL,
  CLEAR_TYPE_MARK_DETAIL,
  GET_SELECTED_TYPE_MARKS_DETAIL_SUCCESS,
} from '../actions/typemark';

const initialState = {
  loading: false,
  loadingGeo: false,
  loadingInstances: false,
  typeMarks: [],
  instances: {
    items: [],
    count: 0,
  },
  geometry: null,
  detail: null,
  menuId: null,
  next: null,
};

const typeMarkReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TYPE_MARKS_SUCCESS: {
      return {
        ...state,
        typeMarks: [...state.typeMarks, ...action.data.results],
        next: action.data.next,
      };
    }
    case GET_TYPE_MARK_DETAIL: {
      return {
        ...state,
        loading: true,
        loadingGeo: true,
        loadingInstances: true,
      };
    }
    case GET_TYPE_MARK_DETAIL_SUCCESS: {
      return {
        ...state,
        detail: { ...action.data },
        loading: false,
      };
    }
    case GET_TYPE_MARK_GEO_SUCCESS: {
      return {
        ...state,
        geometry: action.data,
        loadingGeo: false,
      };
    }
    case ROUTE_ID_ADDED: {
      return {
        ...state,
        menuId: action.data,
      };
    }
    case GET_TYPE_MARK_INSTANCES: {
      return {
        ...state,
        instances: { items: action.data.results, count: action.data.count },
        loadingInstances: false,
      };
    }

    case GET_TYPE_MARK_UPDATE_GEO_SUCCESS: {
      return {
        ...state,
        geometry: { ...state.geometry, ...action.data },
      };
    }
    case CLEAR_TYPE_MARKS: {
      return { ...initialState, menuId: state.menuId };
    }

    case CLEAR_TYPE_MARK_DETAIL: {
      return { ...state, detail: null };
    }

    case GET_SELECTED_TYPE_MARKS_DETAIL_SUCCESS: {
      return {
        ...initialState,
        typeMarks: state.typeMarks,
        menuId: state.menuId,
        detail: action.data,
      };
    }

    default:
      return state;
  }
};

export default typeMarkReducer;
