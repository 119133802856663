import { updateObjectInArray } from '../../utills/imutable';

const initialState = {
  items: [],
  detail: null,
  next: null,
};

const leftPanelWithDetailReducer = (state = initialState, action, name) => {
  switch (action.type) {
    case `GET_${name}_SUCCESS`:
      return {
        ...initialState,
        items: state.items.concat(action.data.results),
        next: action.data.next,
      };

    case `CLEAR_ITEMS_${name}`:
      return {
        ...state,
        items: [],
        detail: null,
      };
    case `CLEAR_${name}`:
      return initialState;

    case `GET_${name}_DETAIL_SUCCESS`:
      return {
        ...state,
        detail: action.data,
      };
    case `CREATE_${name}_SUCCESS`:
      return {
        ...state,
        detail: null,
        items: [...state.items, action.data],
      };
    case `EDIT_${name}_SUCCESS`:
      return {
        ...state,
        detail: action.data,
        items: updateObjectInArray(state.items, {
          id: action.data?.id,
          title: action.data?.title,
        }),
      };

    default:
      return state;
  }
};

export default leftPanelWithDetailReducer;
