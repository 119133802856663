import React, { useState } from 'react';

import stylesLicence from './licenceList.module.css';
import { Radio } from 'antd';
import { apiService } from '../../../../utills/api';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

const ContentListItemWithRadio = ({ items }) => {
  let arrayOfItems = [];
  let id = null;
  for (var key in items) {
    if (key === 'id') {
      id = items[key];
    } else {
      arrayOfItems.push(items[key]);
    }
  }
  return (
    <li className={stylesLicence.listRow}>
      <Radio value={id} />
      {arrayOfItems.map((ele, i) => (
        <span className={stylesLicence.item} key={i}>
          {ele}
        </span>
      ))}
    </li>
  );
};

const ListLicences = ({ data, activeLicence }) => {
  const [licence, setLicence] = useState(activeLicence?.id);
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.company.detail.id);

  const _onChange = async (e) => {
    const id = e?.target?.value;

    try {
      let { data: response } = await apiService.post(
        `/api/v1/company/${companyId}/licence-change/`,
        {
          licence: id,
        }
      );
      if (response) {
        setLicence(id);
        dispatch({ type: 'LICENCE_CHANGED', data: { id: id } });
      }
    } catch (error) {
      NotificationManager.warning('Upozornění', error?.response?.data?.detail);
    }
  };

  if (!data) return null;

  return (
    <Radio.Group style={{ display: 'block' }} onChange={_onChange} value={licence}>
      <ul className={stylesLicence.licenceContainer}>
        {data.map((ele, i) => ele && <ContentListItemWithRadio key={i} items={ele} />)}
      </ul>
    </Radio.Group>
  );
};

export default ListLicences;
