export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const START_LOADING_USER_PROFILE = 'START_LOADING_USER_PROFILE';
export const USER_PROFILE_LOADED = 'USER_PROFILE_LOADED';
export const UPDATE_USER_SUCCESSFULLY = 'UPDATE_USER_SUCCESSFULLY';

export const CHANGE_CURRENT_COMPANY = 'CHANGE_CURRENT_COMPANY';

export const REFRESH_CONNECTION_SUCCESS = 'REFRESH_CONNECTION_SUCCESS';

export const REGISTRATION_START = 'REGISTRATION_START';
export const REGISTRATION_SUCC = 'REGISTRATION_SUCC';
export const REGISTRATION_FAIL = 'REGISTRATION_FAIL';
export const REGISTRATION_CONFIRM = 'REGISTRATION_CONFIRM';

export const START_LOADING_CONSIST_DOORS = 'START_LOADING_CONSSIST_DOORS';
export const LOAD_CONSIST = 'LOAD_CONSIST';
export const SUCCESS_CONSIST = 'SUCCESS_CONSIST';
export const SUCCESS_BUILD_ITEM = 'SUCCESS_BUILD_ITEM';
export const CONSIST_FAIL = 'CONSIST_FAIL';
export const ON_PATCH_SUCCESS = 'ON_PATCH_SUCCESS';
export const SUCCESS_PROPERTY = 'SUCCESS_PROPERTY';
export const SUCCESS_COMPONENTS = 'SUCCESS_COMPONENTS';
export const SUCCESS_COLORS = 'SUCCESS_COLORS';
export const SUCCESS_SOLUTIONS = 'SUCCESS_SOLUTIONS';
export const SUCCESS_CREATED_PROPERTY = 'SUCCESS_CREATED_PROPERTY';
export const SUCCESS_PROPERTY_DELETE = 'SUCCESS_PROPERTY_DELETE';
export const SUCCESS_PROPERTY_EDITED = 'SUCCESS_PROPERTY_EDITED';

export const SUCCESS_PROPERTYINSIDE_EDITED = 'SUCCESS_PROPERTYINSIDE_EDITED';
export const SUCCESS_ADDED_PROPERTY_SET = 'SUCCESS_ADDED_PROPERTY_SET';

export const SUCCESS_CREATED_COMPONENT = 'SUCCESS_CREATED_COMPONENT';
export const SUCCESS_DELETED_COMPONENT = 'SUCCESS_DELETED_COMPONENT';
export const SUCCESS_UPDATED_COMPONENT_COLOR = 'SUCCESS_UPDATED_COMPONENT_COLOR';

export const SUCCESS_CLASSIFICATION = 'SUCCESS_CLASSIFICATION';

export const SET_ID_ROUTE = 'SET_ID_ROUTE';

export const SUCCESS_GEO = 'SUCCESS_GEO';

export const REHYDRATE = 'persist/REHYDRATE';

export const SET_PAGE_PERMISSION = 'SET_PAGE_PERMISSION';

export const ADD_CONTRACT = 'ADD_CONTRACT';
export const REMOVE_CONTRACT = 'REMOVE_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
