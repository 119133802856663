import React from 'react';
import style from './bar.module.css';

const Bar = ({ text, content }) => {
  var result = (content || []).map((item, i) => (
    <div key={i} className={style.barIcon}>
      {item}
    </div>
  ));

  return (
    <div data-cy="list" className={style.bar}>
      <span className={style.barText}>{text}</span>
      <span className={style.barContent}>{result}</span>
    </div>
  );
};

export default Bar;
