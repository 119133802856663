import React, { useEffect, useRef } from 'react';
import { Formik, Field } from 'formik';
import { Form } from 'antd';

import * as Yup from 'yup';
import { InputField } from '../../../shared/InputField';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email('Zadejte validní e-mail!'),
});

const ProfileForm = ({
  formProps = {},
  submitOutside = () => {},
  submit = () => {},
  error,
  reset = false,
  resetConfirm,
}) => {
  const formRef = useRef();

  useEffect(() => {
    if (reset === true) {
      formRef.current.setValues(init(formProps));
      resetConfirm();
    }
  }, [reset]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed && submitOutside) {
      formRef.current.submitForm();
    }

    return () => (isSubscribed = false);
  }, [submitOutside]);

  useEffect(() => {
    formRef.current.setErrors(error?.response?.data || '');
  }, [error]);

  const init = ({ degree_before, first_name, last_name, username, degree_after, email }) => {
    return {
      degree_before,
      first_name,
      last_name,
      username,
      degree_after,
      email,
    };
  };

  return (
    <div>
      <Formik
        validationSchema={ValidationSchema}
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={init(formProps)}
        onSubmit={(values) => {
          submit(values);
        }}
      >
        {(props) => {
          const { values } = props;

          return (
            <Form labelCol={{ span: 9 }}>
              {/* <Field
                name="username"
                value={values.username}
                placeholder="Uživatelské jméno"
                component={InputField}
              /> */}
              <Field
                name="degree_before"
                value={values.degree_before}
                placeholder="Titul před jménem"
                component={InputField}
              />
              <Field
                name="first_name"
                value={values.first_name}
                placeholder="Křestní jméno"
                component={InputField}
              />
              <Field
                name="last_name"
                value={values.last_name}
                placeholder="Prijmeni"
                component={InputField}
              />
              <Field
                name="degree_after"
                value={values.degree_after}
                placeholder="Titul za jménem"
                component={InputField}
              />
              {/* <Field
                name="email"
                value={values.email}
                placeholder="E-mail"
                component={InputField}
              /> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProfileForm;
