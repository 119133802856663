import React, { useEffect, useState } from 'react';
import ModalButton from '../../../../shared/modal/ModalButton';
import Modal from '../../../../shared/modal/Modal';

import { usePhaseService } from '../../../../services/phaseService';
import PhaseForm from '../forms/PhaseForm';

const PhaseModal = ({ visible, onClose, submitForm, formProps, title }) => {
  const [submit, setSubmit] = useState(false);
  const [phasesTypes, setPhasesTypes] = useState(false);
  const [models, setModels] = useState(false);
  const { getModels, getPhaseTypes } = usePhaseService();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await Promise.all([getPhaseTypes(), getModels()]);
        setPhasesTypes(result[0].data);
        setModels(result[1].data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  const formSubmit = (id, values) => {
    setSubmit(false);
    submitForm(id, values);
  };

  const buttons = () => {
    let _buttons = [
      <ModalButton key={1} action={() => onClose()}>
        Zavřít
      </ModalButton>,
      <ModalButton primary key={2} action={() => setSubmit(true)}>
        Potvrdit
      </ModalButton>,
    ];

    return _buttons;
  };

  return (
    <Modal title={title} visible={visible} header={[]} onClose={onClose} footer={buttons()}>
      <PhaseForm
        types={phasesTypes}
        models={models}
        formProps={formProps}
        submit={formSubmit}
        submitOutside={submit}
      />
    </Modal>
  );
};

export default PhaseModal;
