import React from 'react';
import { useSelector } from 'react-redux';
import TechnicalReportItemSetView from './components/TechnicalReportItemSetView';
import TechnicalReportItemSetModal from './components/modals/TechnicalReportItemSetModal';
import withDbItemsWrapper from '../../../utills/hoc/dbItemsWrapper';

const TechnicalReportItemSet = ({ openDialog, isEdit, handleSubmit, handleEdit, handleClose }) => {
  const { detail } = useSelector((state) => state.technicalReportItemSet);

  return (
    <div className="w-full min-w-0">
      {detail && <TechnicalReportItemSetView data={detail} onEdit={handleEdit} />}

      <TechnicalReportItemSetModal
        title="Sety položek technické správy"
        visible={openDialog}
        formProps={isEdit ? detail : null}
        classification={[]}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  );
};

export default withDbItemsWrapper(
  TechnicalReportItemSet,
  'technicalReportItemSet',
  'left-panel-set-technical-report-item/'
);
