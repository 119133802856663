import React, { useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import Bar from '../../../../../elements/Bar';
import { PlusOutlined } from '@ant-design/icons';
import PickValueWithClassificationModal from '../../../../../shared/modals/PickValueWithClassificationModal';
import Label from '../../../../Label';
import { AreaField } from '../../../../../shared/AreaField';
import FormList from '../../../../../elements/FormList';
import ModalButton from '../../../../../shared/modal/ModalButton';

const TechnicalReportItemModal = ({ visible, title, formProps, onSubmit, onClose }) => {
  const [visibleAddBiblioDialog, setVisibleAddBiblioDialog] = useState(false);

  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    description: Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const handleDeleteBiblio = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.biblios.filter((biblio) => biblio.id !== id);

    formRef.current.setFieldValue('biblios', newValues);
  };

  const handleAddBiblio = (item) => {
    formRef.current.setFieldValue('biblios', [...formRef.current.values.biblios, item]);

    setVisibleAddBiblioDialog(false);
  };

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          description: formProps?.description || null,
          biblios: formProps?.biblios || [],
        }}
        onSubmit={(values) => {
          onSubmit(formProps?.id, {
            ...values,
            biblios: values.biblios.map((biblio) => biblio.id),
          });
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                <div>
                  <Label name="description" title="Popisek" required />
                  <Field
                    name="description"
                    value={values.description || null}
                    placeholder="Popisek"
                    component={AreaField}
                  />
                </div>

                <div className="mb-1">
                  <Bar
                    text="Biblio"
                    content={[
                      <PlusOutlined key={1} onClick={() => setVisibleAddBiblioDialog(true)} />,
                    ]}
                  />
                  <FormList data={values.biblios} onDelete={handleDeleteBiblio} />
                </div>
              </Form>
              <PickValueWithClassificationModal
                visible={visibleAddBiblioDialog}
                url="left-panel-biblio"
                pickerUrl="biblio/left-panel/"
                onClose={() => setVisibleAddBiblioDialog(false)}
                onSubmit={handleAddBiblio}
              />
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TechnicalReportItemModal;
