import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'underscore';
import BaseContainer from '../../../containers/BaseContainer';
import Bar from '../../../elements/Bar';
import { ACTION_TYPES, SharedListWithBar } from '../../../shared/components/SharedListWithBar';
import {
  deleteAlternativeClassificationsItem,
  patchAlternativeClassifications,
  postAlternativeClassifications,
  startEditDetail,
} from '../../../store/actions/classification';
import ClassificationDetailList from './components/ClassificationDetailList';
import AlternativeClassificationModal from './components/modals/AlternativeClassificationModal';
import ClassificationDetailModal from './components/modals/ClassificationDetailModal';
import TwoColumnEditableList from './components/TwoColumnEditableList';

function ClassificationDetailView({ setEdit }) {
  const [visibleDetailModal, setVisibleDetailModal] = useState(false);
  const [visibleAlternativeClassificationModal, setVisibleAlternativeClassificationModal] =
    useState(false);
  const [editAlternativeClassificationId, setEditAlternativeClassificationId] = useState(null);
  const { detail, active_classification } = useSelector((state) => state.classification);
  const permission = useSelector((state) => state.auth.pagePermission);
  const dispatch = useDispatch();

  if (!detail) {
    return null;
  }

  const submitAlternativeClassification = async (values) => {
    try {
      if (editAlternativeClassificationId) {
        const { data } = await patchAlternativeClassifications(
          editAlternativeClassificationId,
          values
        );
        if (data) {
          setVisibleAlternativeClassificationModal(false);
          setEditAlternativeClassificationId(null);
          dispatch({
            type: 'EDIT_ALTERNATIVE_CLASSIFICATION_SUCCESS',
            data,
          });
        }
      } else {
        const { data } = await postAlternativeClassifications(values, active_classification);

        if (data) {
          setVisibleAlternativeClassificationModal(false);
          dispatch({
            type: 'ADD_ALTERNATIVE_CLASSIFICATION_SUCCESS',
            data,
          });
        }
      }
    } catch (err) {
      //
    }
  };

  const removeItemAlternativeClassification = async (id) => {
    try {
      const { status } = await deleteAlternativeClassificationsItem(id);
      if (status === 204) {
        dispatch({
          type: 'REMOVE_ALTERNATIVE_CLASSIFICATION_SUCCESS',
          data: id,
        });
      }
    } catch (err) {
      //
    }
  };

  const editAlternativeClassification = async (id) => {
    setVisibleAlternativeClassificationModal(true);
    setEditAlternativeClassificationId(id);
  };

  const findCurrentAlterantiveClass = () => {
    const result = (detail?.alternative_classification_set || []).find(
      (ele) => ele.id === editAlternativeClassificationId
    );
    if (result) {
      return result;
    }
    return null;
  };

  const onCloseAlternativeClass = () => {
    setVisibleAlternativeClassificationModal(false);
    setEditAlternativeClassificationId(null);
  };

  return (
    <div>
      <BaseContainer fullHeight width="calc(100% - 420px)" margin>
        <Bar
          text="Detail"
          content={
            permission === 2
              ? [<EditOutlined key={1} onClick={() => setVisibleDetailModal(true)} />]
              : []
          }
        />
        <ClassificationDetailList />
        {detail && (
          <TwoColumnEditableList
            addItem={() => setVisibleAlternativeClassificationModal(true)}
            removeItem={(id) => {
              removeItemAlternativeClassification(id);
            }}
            editItem={(id) => {
              editAlternativeClassification(id);
            }}
          />
        )}
      </BaseContainer>
      <ClassificationDetailModal
        visible={visibleDetailModal}
        onClose={() => setVisibleDetailModal(false)}
      />
      <AlternativeClassificationModal
        submitForm={submitAlternativeClassification}
        visible={visibleAlternativeClassificationModal}
        formProps={findCurrentAlterantiveClass()}
        onClose={() => onCloseAlternativeClass()}
      />
    </div>
  );
}

export default ClassificationDetailView;
