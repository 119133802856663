import { updateObjectInArray } from '../../utills/imutable';

const initialState = {
  items: [],
  detail: null,
  activeClassification: null,
  next: null,
  isLoading: false,
};

const dbItemsReducer = (state = initialState, action, name) => {
  switch (action.type) {
    case `GET_${name}_SUCCESS`:
      return {
        ...state,
        items: [...state.items, ...action.data.results],
        next: action.data.next,
      };
    case `CLEAR_ITEMS_${name}`:
      return {
        ...state,
        items: [],
        detail: null,
      };
    case `CLEAR_${name}`:
      return {
        initialState,
      };
    case `${name}_ACTIVE_CLASSIFICATION`:
      return {
        ...initialState,
        activeClassification: action.data,
      };
    case `GET_${name}_DETAIL`:
      return {
        ...state,
        isLoading: true,
      };
    case `GET_${name}_DETAIL_SUCCESS`:
      return {
        ...state,
        detail: action.data,
        isLoading: false,
      };
    case `CREATE_${name}_SUCCESS`:
      return {
        ...state,
        detail: null,
        items: [...state.items, action.data],
      };
    case `EDIT_${name}_SUCCESS`:
      return {
        ...state,
        detail: action.data,
        items: updateObjectInArray(state.items, {
          id: action.data?.id,
          title: action.data?.title,
        }),
      };

    default:
      return state;
  }
};

export default dbItemsReducer;
